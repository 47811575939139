import {
    FETCH_SONGS, ADD_SONG, PUBLISH_SONG, GET_SONG_DATA, LOAD_SONGS, ERROR_LOADING_SONG, ERROR_LOADING_SONGS, ERROR_ADDING_ACTIVITY
    , ERROR_ADDING_SONG,
    PUBLISH_ACTIVITY, LOAD_SONG, EDIT_SONG_DATA, ERROR_EDITING_SONG, EMPTY_SONG, ERROR_DELETING_SONG, DELETE_SONG, SETTING_SONG_STATUS, ERROR_SETTING_SONG_STATUS,
    EMPTY_ALL_SONG
} from './Types'
import axios, { post, put } from 'axios'
import { saveActivity } from '../Promises/Activity.js';
import { saveSong } from '../Promises/Song.js'
import firebase from 'firebase/app'

export const fetchSongs = () => dispatch => {

    dispatch({
        type: LOAD_SONGS,
        payload: 'Loading'
    })

    axios.get('/Song/getAllSongsList')
        .then((res) => {
            dispatch({
                type: FETCH_SONGS,
                payload: res.data
            })
        }
        ).catch((Error) => {
            dispatch({
                type: ERROR_LOADING_SONGS,
                payload: Error.message
            })
        })
}

export const publishSong = (name) => dispatch => {
    dispatch({
        type: LOAD_SONG,
        payload: 'Loading'
    })

    saveActivity(name).then((res) => {
        dispatch({
            type: PUBLISH_ACTIVITY,
            payload: res
        })
        saveSong(res.id).then((res) => {
            dispatch(
                {
                    type: PUBLISH_SONG,
                    payload: res.data
                }
            )
        }).catch((Error) => {
            dispatch(
                {
                    type: ERROR_ADDING_SONG,
                    payload: Error.message
                })
        })



    }).catch((Error) => {
        dispatch({
            type: ERROR_ADDING_ACTIVITY,
            payload: Error.message
        })
    });
}

export const addSong = (name, description, image, PublishDate,
    videoWithMusic, Video_Without_Music,
    videoWithMusic_duration,
    videoWithoutMusic_duration,
    radiobutton, isFree, voice,
    subValueId,
    selected_songParent_id,
    selected_songParent_name,
    song_name_in_english,
    description_in_english,
    videoKeyVideoWithMusic,
    videoKeyVideoWithoutMusic, featured) => dispatch => {

        if (description === null &&
            description === undefined) {
            description = '';
        }

        if (description_in_english === null ||
            description_in_english === undefined) {
            description_in_english = '';
        }

        if (song_name_in_english === null ||
            song_name_in_english === undefined) {
            song_name_in_english = '';
        }

        const song = {
            name: name, description: description, image: image, PublishDate: PublishDate, videoWithMusic: videoWithMusic,
            Video_Without_Music: Video_Without_Music,
            videoWithMusic_duration: videoWithMusic_duration,
            videoWithoutMusic_duration: videoWithoutMusic_duration,
            videoKeyVideoWithMusic:videoKeyVideoWithMusic,
            videoKeyVideoWithoutMusic,videoKeyVideoWithoutMusic,
            radiobutton: radiobutton,
            isFree: isFree,
            voice: voice,
            subValueId: subValueId,
            description_in_english: description_in_english,
            song_name_in_english: song_name_in_english,
            selected_songParent_id: selected_songParent_id,
            selected_songParent_name: selected_songParent_name,
            featured: featured
        }

        dispatch({
            type: ADD_SONG,
            payload: song
        })
    }

export const getSongData = (id) => {


    return (dispatch, getState, { getFirebase }) => {
        const firebase = getFirebase();
        firebase.auth().currentUser.getIdToken().then((token) => {
            dispatch({
                type: LOAD_SONG,
                payload: 'Loading'
            })

            const url = "/Song/getSongDetails/admin"
            const formData = new FormData();
            formData.append('Song_ID', id);
            const config = {
                headers: {
                    'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
                    'X-Authorization-Firebase': token
                }
            }

            post(url, formData, config).then((res) => {
                dispatch(
                    {
                        type: GET_SONG_DATA,
                        payload: res.data
                    }
                )
            }).catch((Error) => {
                dispatch({
                    type: ERROR_LOADING_SONG,
                    payload: Error.message
                })
            })
        })



    }

}

export const updateSong = (id, name, description, Image,
    PublishDate, videoWithMusic,
    videoWithoutMusic, activityId,
    videoWithMusic_duration,
    videoWithoutMusic_duration, paymentAva, voice,
    subValueId, songParent_id,
    song_name_in_english,description_in_english,
    video_key_video_with_music,
    video_key_video_without_music, featured) => {


    return (dispatch, getState, { getFirebase }) => {
        const firebase = getFirebase();
        firebase.auth().currentUser.getIdToken().then((token) => {
            dispatch({
                type: LOAD_SONG,
                payload: 'Loading'
            })


            if (description === null &&
                description === undefined) {
                description = '';
            }

            if (description_in_english === null ||
                description_in_english === undefined) {
                description_in_english = '';
            }

            if (song_name_in_english === null ||
                song_name_in_english === undefined) {
                song_name_in_english = '';
            }



            let isFree = ''
            if (paymentAva === 'paid') {
                isFree = 0
            }
            else if (paymentAva === 'free') {
                isFree = 1
            }

            let updatedSubValueId
            if (subValueId === '' ||
                subValueId === undefined ||
                subValueId === null) {
                updatedSubValueId = 0
            } else {
                updatedSubValueId = subValueId
            }


            let videoWithMusicUrl = '', videoWithoutMusicUrl = '';
            let videoKeyVideoWithMusic ='' , videoKeyVideoWithoutMusic='';
            if (videoWithMusic === '' ||
                videoWithMusic === undefined ||
                videoWithMusic === null) {
                videoWithMusicUrl = ''
            } else {
                videoWithMusicUrl = videoWithMusic
            }


            if (videoWithoutMusic === '' ||
                videoWithoutMusic === undefined ||
                videoWithoutMusic === null) {
                videoWithoutMusicUrl = ''
            } else {
                videoWithoutMusicUrl = videoWithoutMusic
            }
            if (video_key_video_with_music === '' ||
            video_key_video_with_music === undefined ||
            video_key_video_with_music === null) {
                videoKeyVideoWithMusic = ''
        } else {
            videoKeyVideoWithMusic = video_key_video_with_music
        }
        if (video_key_video_without_music === '' ||
            video_key_video_without_music === undefined ||
            video_key_video_without_music === null) {
                videoKeyVideoWithoutMusic = ''
        } else {
            videoKeyVideoWithoutMusic = video_key_video_without_music
        }

            let status = '';
            if (name === '' || name === null || name === undefined
                // || song_name_in_english === '' || song_name_in_english === null || song_name_in_english === undefined
                || Image === '' || Image === null || Image === undefined
                || videoWithoutMusic_duration == 0
                || videoWithoutMusicUrl === '' || videoWithoutMusicUrl === null
                || videoWithoutMusicUrl === undefined
                || videoWithMusic_duration == 0
                || videoWithMusicUrl === '' || videoWithMusicUrl === null
                || videoWithMusicUrl === undefined
                || PublishDate === '' || PublishDate === null) {
                status = 'SUSPENDED'
            }
            else {
                status = ''
            }

            const url = "/Song/updateSong";
            const formData = new FormData();
            formData.append('ID', id);
            formData.append('Name', name);
            formData.append('Description', description);
            formData.append('Image', Image);
            formData.append('Status', status);
            formData.append('Publish_Date', PublishDate);

            formData.append('Video_With_Music_URL', videoWithMusicUrl);
            formData.append('Video_With_Music_Duration', videoWithMusic_duration);

            formData.append('Video_Without_Music_URL', videoWithoutMusicUrl);
            formData.append('Video_Without_Music_Duration', videoWithoutMusic_duration);

            formData.append('Activity_ID', activityId);
            formData.append('Is_free', isFree);
            formData.append('Voice', voice);
            formData.append('Content_Sub_Value_Id', updatedSubValueId);
            formData.append('Song_Parent_Id', songParent_id);
            formData.append('Name_In_English', song_name_in_english);
            formData.append('Description_In_English', description_in_english);
            formData.append('Video_Key_Video_With_Music', videoKeyVideoWithMusic);
            formData.append('Video_Key_Video_Without_Music', videoKeyVideoWithoutMusic);
            formData.append('featured', featured);
            const config = {
                headers: {
                    'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
                    'X-Authorization-Firebase': token
                }
            }
            put(url, formData, config).then((res) => {
                dispatch({
                    type: EDIT_SONG_DATA,
                    payload: res.data
                })
            }).catch((Error) => {
                dispatch({
                    type: ERROR_EDITING_SONG,
                    payload: Error.response.data.message
                })
            })
        })
    }
}

export const emptySong = () => dispatch => {
    dispatch({
        type: EMPTY_SONG,
        payload: ""
    })
}

export const emptyAllSongs = () => dispatch => {
    dispatch({
        type: EMPTY_ALL_SONG,
        payload: ""
    })
}

export const deleteSong = (id) => {
    return (dispatch, getState, { getFirebase }) => {
        const firebase = getFirebase();
        firebase.auth().currentUser.getIdToken().then((token) => {
            const url = "/Song/deleteSong"
            const formData = new FormData();
            formData.append("Song_ID", id);

            const config = {
                headers: {
                    'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
                    'X-Authorization-Firebase': token
                }

            }
            post(url, formData, config).then((res) => {
                dispatch({
                    type: DELETE_SONG,
                    payload: res.data
                })
            }).catch((Error) => {
                dispatch({
                    type: ERROR_DELETING_SONG,
                    payload: Error.response.data.message
                })
            })

        })
    }
}

export const setSongStatus = (id, status) => {
    return (dispatch, getState, { getFirebase }) => {
        const firebase = getFirebase();
        firebase.auth().currentUser.getIdToken().then((token) => {
            dispatch({
                type: LOAD_SONG,
            })
            const url = "/Song/setSongStatus"
            const formData = new FormData();
            formData.append("ID", id)
            formData.append("Status", status)

            const config = {
                headers: {
                    'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
                    'X-Authorization-Firebase': token
                }
            }
            post(url, formData, config).then((res) => {
                dispatch({
                    type: SETTING_SONG_STATUS,
                    payload: res.data
                })
            }).catch((Error) => {
                dispatch({
                    type: ERROR_SETTING_SONG_STATUS,
                    payload: Error.response.data.message// Error.message
                })
            })
        })

    }
}



export const deleteSongPuzzle = (id) => {

    const url = "/SongPuzzleController/deleteSongPuzzle"
    let token = firebase.auth().currentUser.h.b;
    const formData = new FormData();
    formData.append("Song_Puzzle_Id", id);
    return axios({

        method: "post",
        headers: {
            'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
            'X-Authorization-Firebase': token
        },
        data: formData,
        url: url
    });
}


export const saveSongPuzzle = (Song_ID, Image_URL) => {

    const url = "/SongPuzzleController/saveSongPuzzle"
    let token = firebase.auth().currentUser.h.b;
    const formData = new FormData();
    formData.append("Song_ID", Song_ID);
    formData.append("Image_URL", Image_URL);
    return axios({

        method: "post",
        headers: {
            'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
            'X-Authorization-Firebase': token
        },
        data: formData,
        url: url
    });
}





export const deleteSongPaintingGame = (id) => {

    const url = "/SongPaintingGameController/deleteSongPaintingGame"
    let token = firebase.auth().currentUser.h.b;
    const formData = new FormData();
    formData.append("Song_Painting_Game_Id", id);
    return axios({

        method: "post",
        headers: {
            'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
            'X-Authorization-Firebase': token
        },
        data: formData,
        url: url
    });
}


export const saveSongPaintingGame = (Song_ID, Image_URL) => {

    const url = "/SongPaintingGameController/saveSongPaintingGame"
    let token = firebase.auth().currentUser.h.b;
    const formData = new FormData();
    formData.append("Song_ID", Song_ID);
    formData.append("Image_URL", Image_URL);
    return axios({

        method: "post",
        headers: {
            'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
            'X-Authorization-Firebase': token
        },
        data: formData,
        url: url
    });
}



/*Start of Drag Drop*/ 

export const getDragAndDropSong = (DDID) => {
    const url = "/SongDragDrop/getSongDragAndDropData"
    let token = firebase.auth().currentUser.h.b;
    const formData = new FormData();
    formData.append("SongDragDrop_ID", DDID);
    return axios({
        method: "post",
        headers: {
            'X-Authorization-Firebase': token
        },
        data: formData,
        url: url
    });
}

export const saveSongDragAndDrop = (attributes) => {
    const url = "/SongDragDrop/saveSongDragDrop"
    let token = firebase.auth().currentUser.h.b;
    return axios({
        method: "post",
        headers: {
            'content-type': 'application/json',
            'X-Authorization-Firebase': token
        },
        data: {
            songId: attributes.parentId,
            imageUrl: attributes.game_Image,
            cropedImageUrl: attributes.cropedImageUrl,
            dragDropWidth: attributes.dragDropWidth,
            dragDropHeight: attributes.dragDropHeight,
            draggableElements: attributes.listElements
        },
        url: url
    });
}

export const deleteSongDragAndDrop = (id) => {
    const url = "/SongDragDrop/deleteDragAndDrop"
    let token = firebase.auth().currentUser.h.b;
    const formData = new FormData();
    formData.append("SongDragDrop_ID", id);
    return axios({
        method: "post",
        headers: {
            'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
            'X-Authorization-Firebase': token
        },
        data: formData,
        url: url
    });
}

export const addDraggableElementSong = (DDID, draggableWidth, draggableHeight, xCoordinate, yCoordinate, draggableImage) => {
    const url = "/SongDragDrop/addDraggableElement"
    let token = firebase.auth().currentUser.h.b;
    return axios({
        method: "post",
        headers: {
            'X-Authorization-Firebase': token
        },
        data: {
            dragDropId: DDID,
            draggableWidth: draggableWidth,
            draggableHeight: draggableHeight,
            xCoordinate: xCoordinate,
            yCoordinate: yCoordinate,
            draggableImage: draggableImage,
        },
        url: url
    });
}

export const deleteDraggableElementSong = (id) => {
    const url = "/SongDragDrop/deleteDraggableElement"
    let token = firebase.auth().currentUser.h.b;
    const formData = new FormData();
    formData.append("DraggableElement_ID", id);
    return axios({
        method: "post",
        headers: {
            'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
            'X-Authorization-Firebase': token
        },
        data: formData,
        url: url
    });
}

export const updateOneDraggableElementSong = (id, width, height, XCoordinate, YCoordinate, imageURL) => {
    const url = "/SongDragDrop/updateOneDraggableElement";
    let token = firebase.auth().currentUser.h.b;
    const formData = new FormData();
    formData.append("DraggableId", id);
    formData.append("DraggbleWidth", width);
    formData.append("DraggbleHeight", height);
    formData.append("XCoordinate", XCoordinate);
    formData.append("YCoordinate", YCoordinate);
    formData.append("url", imageURL);

    return axios({
        method: "post",
        headers: {
            'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
            'X-Authorization-Firebase': token
        },
        data: formData,
        url: url
    });
}

export const updateDragAndDropSong = (id, width, height, imageURL, croppedImageURL) => {
    const url = "/SongDragDrop/updateSongDragAndDrop";
    let token = firebase.auth().currentUser.h.b;
    const formData = new FormData();
    formData.append("Id", id);
    formData.append("DragDropWidth", width);
    formData.append("DragDropHeight", height);
    formData.append("url", imageURL);
    formData.append("Cropped_Image_Url", croppedImageURL);

    return axios({
        method: "post",
        headers: {
            'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
            'X-Authorization-Firebase': token
        },
        data: formData,
        url: url
    });
}

/*End of Drag Drop*/ 
