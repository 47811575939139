import React, { Component } from "react";
import { withFirebase } from "../../config";
import { Dropdown, Input, Button, Icon } from "semantic-ui-react";
import { Link, Redirect } from "react-router-dom";
/*navbar*/
import OuterHeader from "../Layout/OuterHeader";
import Dashboard from "../Dashboard";
import InnerHeader from "../Layout/InnerHeader";
import NavBar from "../Layout/NavBar";
import Footer from "../Layout/Footer";
/*Redux */
import { connect } from "react-redux";
import propTypes from "prop-types";
/*Actions */
import { addSong } from "../../Actions/SongActions";
import { fetchAllSubValues } from "../../Actions/ContentSubValueAction";
import { fetchAllSongParent ,getSongParentData ,emptySongParentWithSongs} from "../../Actions/SongParentAction";
import { addActivity } from "../../Actions/ActivityActions";
/*CSS */
import "../../css/AppCss/Episode/addEpisode.css";
import "../../css/Songs.css";
/**Other Component */
import PublishDate from "../Layout/PublishDate";
import UploadImage from "../S3Uploading/UploadImage";
import UploadVideo from "../S3Uploading/UploadVideo";
import UploadAudio from "../S3Uploading/UploadAudio";

/* Routes */
import { ALL_SONGS, ADD_SONG_ACTIVITY } from "../../routes";
import { clearPreSignData, clearAll } from "../../Actions/VideoCipherActions";
import VideoWithMusicUploader from "../FilePondUploading/VideoWithMusicUploader";
import VideoWithoutMusicUploader from "../FilePondUploading/VideoWithoutMusicUploader";
import { SONG_FOLDER_NAME } from "../../portal/src/constants";
import RenderVideoWithMusic from "../VideoPreview/RenderVideoWithMusic";
import RenderVideoWithoutMusic from "../VideoPreview/RenderVideoWithoutMusic";

const is_free_type = [
  {
    key: 0,
    text: "paid",
    value: "paid",
  },
  {
    key: 1,
    text: "free",
    value: "free",
  },
];

const INITIAL_STATE = {
  redirection: false,

  selected_songParent_id: "",
  selected_songParent_name: "",
  video_with_music_folder_id : "",
  video_with_out_music_folder_id : "",
  returned_songParent_id_viewSongParentPage: "",
  returned_songParent_name_viewSongParentPage: "",

  song_name: "",
  description: "",
  song_name_in_english: "",
  description_in_english: "",
  error: "",
  imageUrl: "",

  videoWithMusicUrl: "",
  videoWithoutMusicUrl: "",
  VideoWithMusicReady: false,
  VideoWithoutMusicReady: false,
  videoWithMusic_duration: 0,
  videoWithoutMusic_duration: 0,
  videoKeyVideoWithMusic: "",
  videoKeyVideoWithoutMusic: "",
  isFree: "",

  auth_user: {},
  publishDateTime: "",
  radiobutton: "",
  voice: "",
  subValueId: "",
  featured: false,
  ////////////////// validation form
  error: true,
  errorMessage: "",
};

class AddSong extends Component {
  constructor(props) {
    super(props);
    this.state = INITIAL_STATE;
  }


  componentDidUpdate(prevProps) {
    const { songParentSelected } = this.props;
  
  
    if (songParentSelected !== prevProps.songParentSelected) {
     
      this.handleSongParentSelectedChange();
    }
  }
  
  handleSongParentSelectedChange() {

    if (this.props.songParentSelected && this.props.songParentSelected !==null && this.props.songParentSelected !== undefined && this.props.songParentSelected !== ""){
      if (this.props.songParentSelected.withMusicFolderId  && this.props.songParentSelected.withMusicFolderId !== null && this.props.songParentSelected.withMusicFolderId !== undefined){

        this.setState ({
          video_with_music_folder_id :this.props.songParentSelected.withMusicFolderId ,
         })
       }
       
       if (this.props.songParentSelected.withoutMusicFolderId  && this.props.songParentSelected.withoutMusicFolderId !== null && this.props.songParentSelected.withoutMusicFolderId !== undefined){
   
         this.setState ({
           video_with_out_music_folder_id :this.props.songParentSelected.withoutMusicFolderId ,
         })
       }
    }
  }


  componentDidMount() {
    this.props.fetchAllSongParent();
    this.props.fetchAllSubValues();
    this.props.clearAll();
    
    const { song } = this.props;
    if (song && song.selected_songParent_id){
      this.props.getSongParentData(song.selected_songParent_id);
      if(song.videoKeyVideoWithMusic && song.videoKeyVideoWithMusic !== null && song.videoKeyVideoWithMusic !== "" && song.videoKeyVideoWithMusic !== undefined ){
        this.setState({ VideoWithMusicReady: true });
      }
      if(song.videoKeyVideoWithoutMusic && song.videoKeyVideoWithoutMusic !== null && song.videoKeyVideoWithoutMusic !== "" && song.videoKeyVideoWithoutMusic !== undefined ){
        this.setState({ VideoWithoutMusicReady: true });
      }
    }
    else {
      this.props.emptySongParentWithSongs();
    }


    // let videoKeyVideoWithMusic = this.props.vdocipherUploadInfo
    //   .videoKeyVideoWithMusic
    //   ? this.props.vdocipherUploadInfo.videoKeyVideoWithMusic.videoId
    //   : "";
    // let videoKeyVideoWithoutMusic = this.props.vdocipherUploadInfo
    //   .videoKeyVideoWithoutMusic
    //   ? this.props.vdocipherUploadInfo.videoKeyVideoWithoutMusic.videoId
    //   : "";
    this.setState({
      error: false,
      song_name: song.name,
      imageUrl: song.image,
      publishDateTime: song.PublishDate,
      videoWithMusicUrl: song.videoWithMusic,
      videoWithoutMusicUrl: song.Video_Without_Music,
      videoWithMusic_duration: song.videoWithMusic_duration,
      videoWithoutMusic_duration: song.videoWithoutMusic_duration,
      videoKeyVideoWithMusic: song.videoKeyVideoWithMusic,
      videoKeyVideoWithoutMusic: song.videoKeyVideoWithoutMusic,
      isFree: song.isFree,
      voice: song.voice,
      subValueId: song.subValueId,
      description: song.description,
      song_name_in_english: song.song_name_in_english,
      description_in_english: song.description_in_english,
      selected_songParent_id: song.selected_songParent_id,
      selected_songParent_name: song.selected_songParent_name,
//       video_with_music_folder_id :song.video_with_music_folder_id ,
// video_with_out_music_folder_id : song.video_with_out_music_folder_id,
      returned_songParent_id_viewSongParentPage: song.selected_songParent_id,
      returned_songParent_name_viewSongParentPage:
      song.selected_songParent_name,
      radiobutton: song.radiobutton,
    });

    const { parentId, parentName ,withMusicFolderId,withoutMusicFolderId} = this.props.location;

    if (parentId) {
      this.setState({
        returned_songParent_name_viewSongParentPage: parentName,
        returned_songParent_id_viewSongParentPage: parentId,
        selected_songParent_id: parentId,
        selected_songParent_name: parentName,
      });
    }

    
    if (withMusicFolderId && withMusicFolderId !== null & withMusicFolderId !== undefined){
      this.setState({video_with_music_folder_id : withMusicFolderId})
    }

    if (withoutMusicFolderId && withoutMusicFolderId !== null & withoutMusicFolderId !== undefined){
      this.setState({video_with_out_music_folder_id : withoutMusicFolderId})
    }
  }

  handleUploadInfoWithMusic = (uploadInfo) => {
    
    if (
      uploadInfo &&
      uploadInfo !== undefined &&
      uploadInfo !== null &&
      Object.keys(uploadInfo).length > 0
    ) {
      this.setState({ videoKeyVideoWithMusic: uploadInfo.videoId });
    } else {
      this.setState({ videoKeyVideoWithMusic: this.state.videoKeyVideoWithMusic });
    }
  };
  handleUploadInfoWithoutMusic = (uploadInfo) => {
    
    if (
      uploadInfo &&
      uploadInfo !== undefined &&
      uploadInfo !== null &&
      Object.keys(uploadInfo).length > 0
    ) {
      this.setState({ videoKeyVideoWithoutMusic: uploadInfo.videoId });
    } else {
      this.setState({ videoKeyVideoWithoutMusic: this.state.videoKeyVideoWithoutMusic });
    }
  };

  handleChangeVideoWithMusic = (uploadInfo) => {

    if (
      uploadInfo &&
      uploadInfo !== undefined &&
      uploadInfo !== null &&
      Object.keys(uploadInfo).length > 0
    ) {
      this.setState({ videoKeyVideoWithMusic: uploadInfo.videoId });
    } 
    else {
      this.setState({ videoKeyVideoWithMusic: this.state.videoKeyVideoWithMusic });
    }
  };
  handleChangeVideoWithoutMusic = (uploadInfo) => {

    if (
      uploadInfo &&
      uploadInfo !== undefined &&
      uploadInfo !== null &&
      Object.keys(uploadInfo).length > 0
    ) {
      this.setState({ videoKeyVideoWithoutMusic: uploadInfo.videoId });
    } 
    else {
      this.setState({ videoKeyVideoWithoutMusic: this.state.videoKeyVideoWithoutMusic });
    }
  };

  // setVideoWithMusicReady = (isReady) => {
    
  //   if (
  //    isReady
  //   )
  //     this.setState({ VideoWithMusicReady: true });
  // };
  // setVideoWithoutMusicReady = (isReady) => {
    
  //   if (
  //    isReady
  //   )
  //     this.setState({ VideoWithoutMusicReady: true });
  // };

  handleDateChange = (e, { name, value }) => {
    if (this.state.hasOwnProperty(name)) {
      this.setState({ [name]: value });
    }
  };

  handleChange = (e) => {
    let checkSpaces = e.target.value.replace(/\s\s+/g, " ");
    const specialCharacters = /[@#$%^&*()\+={}[\]:;<>,\/|\\]/; 
    this.setState({ [e.target.name]: checkSpaces });
    let nameOfState = e.target.name;
    setTimeout(() => {
      if (specialCharacters.test(this.state.song_name) || specialCharacters.test(this.state.song_name_in_english)){
        this.setState({
          error: true,
          errorMessage: "Song name can't contain spaecial characters",
        });
      }
      else if (
        //(this.state.imageUrl === '' || this.state.imageUrl === null || this.state.imageUrl === undefined)
        //||
        this.state.song_name === "" ||
        this.state.song_name === null ||
        this.state.song_name === " " ||
        (this.state.description_in_english &&
          this.state.description_in_english.length > 255)
        // || (this.state.song_name_in_english === '' || this.state.song_name_in_english === null || this.state.song_name_in_english === ' ')
        // || (this.state.videoWithMusicUrl == undefined || this.state.videoWithMusicUrl == '')
        // || (this.state.videoWithoutMusicUrl == undefined || this.state.videoWithoutMusicUrl == '')
        // || (this.state.videoWithMusic_duration == 0 || this.state.videoWithMusic_duration == undefined)
        // || (this.state.videoWithoutMusic_duration == 0 || this.state.videoWithoutMusic_duration == undefined)
      ) {
        this.setState({
          error: true,
          errorMessage:
            nameOfState === "description_in_english"
              ? "Description in English is too long (greater than 255 characters)."
              : "Empty Data is not accepted",
        });
      } 
      else if (
        //(this.state.imageUrl !== '' && this.state.imageUrl !== null && this.state.imageUrl !== undefined)
        //&&
        this.state.song_name !== "" &&
        this.state.song_name !== null &&
        this.state.song_name !== " " &&
        this.state.description_in_english &&
        this.state.description_in_english.length <= 255
        // && (this.state.song_name_in_english !== '' && this.state.song_name_in_english !== null && this.state.song_name_in_english !== ' ')
        // && (this.state.videoWithMusicUrl !== undefined && this.state.videoWithMusicUrl !== '')
        // && (this.state.videoWithoutMusicUrl !== undefined && this.state.videoWithoutMusicUrl !== '')
        // && (this.state.videoWithMusic_duration != 0 && this.state.videoWithMusic_duration != undefined)
        // && (this.state.videoWithoutMusic_duration != 0 && this.state.videoWithoutMusic_duration != undefined)
      ) {
        this.setState({ error: false, errorMessage: "" });
      }
    }, 0);
  };

  handlePaymentAva = (e, { value }) => {
    this.setState({
      isFree: value,
    });
  };

  handleSongParentChange = (e, { value }) => {
    this.setState({ selected_songParent_id: value[0].id });
    this.setState({ selected_songParent_name: value[1].name });
    this.setState({ video_with_music_folder_id: value[2].folderIdWithMusic });
    this.setState({ video_with_out_music_folder_id: value[3].folderIdWithoutMusic });
  };

  handleSubValueChange = (e, { value }) => {
    this.setState({ subValueId: value });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const {
      song_name,
      description,
      imageUrl,
      publishDateTime,
      videoWithMusicUrl,
      videoWithoutMusicUrl,
      videoWithMusic_duration,
      videoWithoutMusic_duration,
      radiobutton,
      isFree,
      voice,
      subValueId,
      selected_songParent_id,
      selected_songParent_name,
      song_name_in_english,
      description_in_english,
      videoKeyVideoWithMusic,
      videoKeyVideoWithoutMusic,
      VideoWithMusicReady,
      VideoWithoutMusicReady,featured
    } = this.state;

    if (e.nativeEvent.submitter.id === "nextButton") {
      if (
        isFree === "" ||
        isFree == undefined ||
        isFree == null ||
        isFree == ""
      ) {
        alert("You must choose payment availability");
        this.setState({ validateInput: false });
        e.preventDefault();
      }

      if (
        selected_songParent_id === "" ||
        selected_songParent_id == undefined ||
        selected_songParent_name === "" ||
        selected_songParent_name == undefined
      ) {
        alert("Empty Song Parent");
        this.setState({ validateInput: false });
      }

      if (publishDateTime === "" || publishDateTime == undefined) {
        this.setState({ validateInput: false });
      } 
      // else if (
      //   (videoWithMusicUrl === "" || videoWithMusicUrl === undefined) &&
      //   (videoKeyVideoWithMusic === "" ||
      //     videoKeyVideoWithMusic === undefined) &&
      //   (videoWithoutMusicUrl === "" || videoWithoutMusicUrl === undefined) &&
      //   (videoKeyVideoWithoutMusic === "" ||
      //     videoKeyVideoWithoutMusic === undefined)
      // ) {
      //   alert("Empty Video");
      //   this.setState({ validateInput: false });
      // }
      //  else if (
      //   videoKeyVideoWithMusic !== "" &&
      //   videoKeyVideoWithMusic !== undefined &&
      //   !VideoWithMusicReady
      // ) {
      //   alert("Please wait until Video with is ready for playback");
      //   this.setState({ validateInput: false });
      // } else if (
      //   videoKeyVideoWithoutMusic !== "" &&
      //   videoKeyVideoWithoutMusic !== undefined &&
      //   !VideoWithoutMusicReady
      // ) {
      //   alert("Please wait until Video without is ready for playback");
      //   this.setState({ validateInput: false });
      // } 
      else {
        this.setState({ validateInput: true });
        this.props.addSong(
          song_name,
          description,
          imageUrl,
          publishDateTime,
          videoWithMusicUrl,
          videoWithoutMusicUrl,
          videoWithMusic_duration,
          videoWithoutMusic_duration,
          radiobutton,
          isFree,
          voice,
          subValueId,
          selected_songParent_id,
          selected_songParent_name,
          song_name_in_english,
          description_in_english,
          videoKeyVideoWithMusic,
          videoKeyVideoWithoutMusic,featured
        );
      }
    }
    /// calling save
  };

  handleCancel = (e) => {
    this.setState({ redirection: true });
  };

  handleCheckboxChange = () => {
    this.setState({ featured: !this.state.featured })
  };

  getPublishDate(publishDateTime, radioButtonState) {
    if (radioButtonState) {
      
      this.setState({ radiobutton: radioButtonState });
    }

    if (publishDateTime) {
      this.setState({ publishDateTime: publishDateTime });
      
    }
  }

  getImageUrl(imageUrl) {
    if (imageUrl === "delete") {
      this.setState({
        imageUrl: "",
        error: true,
        errorMessage: "Empty Data is not accepted",
      });
    } else if (imageUrl !== undefined && imageUrl !== "") {
      this.setState({ imageUrl: imageUrl });
      if (
        this.state.song_name !== "" &&
        this.state.song_name !== " " &&
        this.state.song_name !== null

        // && this.state.song_name_in_english !== '' && this.state.song_name_in_english !== ' ' && this.state.song_name_in_english !== null
        // && this.state.videoWithMusicUrl != undefined && this.state.videoWithMusicUrl != ''
        // && this.state.videoWithoutMusicUrl != undefined && this.state.videoWithoutMusicUrl != ''
        // && this.state.videoWithMusic_duration != 0 && this.state.videoWithMusic_duration != undefined
        // && this.state.videoWithoutMusic_duration != 0 && this.state.videoWithoutMusic_duration != undefined
      ) {
        this.setState({ error: false, errorMessage: "" });
      }
    }
  }

  getvideoWithMusicUrl(videoUrl, duration) {
    if (videoUrl !== "" && videoUrl !== "delete") {
      this.setState({
        videoWithMusicUrl: videoUrl,
        videoWithMusic_duration: duration,
      });
      if (
        this.state.song_name !== "" &&
        this.state.song_name !== " " &&
        this.state.song_name !== null &&
        this.state.imageUrl != undefined &&
        this.state.imageUrl != ""
        // && this.state.videoWithoutMusicUrl != undefined && this.state.videoWithoutMusicUrl != ''
        // && this.state.videoWithMusic_duration != 0 && this.state.videoWithoutMusic_duration != 0
        // && this.state.videoWithMusic_duration != undefined && this.state.videoWithoutMusic_duration != undefined
      ) {
        this.setState({ error: false, errorMessage: "" });
      }
    } else {
      this.setState({ videoWithMusicUrl: "", videoWithMusic_duration: 0 });
    }
  }

  getVideoWithoutMusicUrl(videoUrl, duration) {
    if (videoUrl !== "" && videoUrl !== "delete") {
      this.setState({
        videoWithoutMusicUrl: videoUrl,
        videoWithoutMusic_duration: duration,
      });
      if (
        this.state.song_name !== "" &&
        this.state.song_name !== " " &&
        this.state.song_name !== null &&
        this.state.imageUrl != undefined &&
        this.state.imageUrl != ""
        // && this.state.videoWithMusicUrl != undefined && this.state.videoWithMusicUrl != ''
        // && this.state.videoWithMusic_duration != 0 && this.state.videoWithoutMusic_duration != 0
        // && this.state.videoWithMusic_duration != undefined && this.state.videoWithoutMusic_duration != undefined
      ) {
        this.setState({ error: false, errorMessage: "" });
      }
    } else {
      this.setState({
        videoWithoutMusicUrl: "",
        videoWithoutMusic_duration: 0,
      });
    }
  }

  getAudio(audioUrl) {
    // get  Audio
    if (audioUrl) {
      // check if url =  delete send empty to DB
      if (audioUrl === "delete") {
        this.setState({ voice: "" });
        
      }
      // else send the url to DB
      else {
        this.setState({ voice: audioUrl });
        
      }
    }
  }

  render() {
    const {
      song_name,
      imageUrl,
      publishDateTime,
      videoWithMusicUrl,
      videoWithoutMusicUrl,
      videoWithMusic_duration,
      videoWithoutMusic_duration,
      radiobutton,
      isFree,
      selected_songParent_id,
      selected_songParent_name,
      song_name_in_english,
      description_in_english,
      videoKeyVideoWithMusic,
      videoKeyVideoWithoutMusic
    } = this.state;

    const isInvalid =
      song_name === "" ||
      song_name == undefined ||
      // song_name_in_english === '' || song_name_in_english == undefined ||
      imageUrl === "" ||
      imageUrl == undefined ||
      isFree === "" ||
      isFree == undefined ||
      videoWithMusicUrl === "" ||
      videoWithMusicUrl == undefined ||
      videoWithoutMusicUrl === "" ||
      videoWithoutMusicUrl == undefined ||
      videoWithMusic_duration === "" ||
      videoWithMusic_duration == undefined ||
      videoWithMusic_duration === 0 ||
      videoWithoutMusic_duration === "" ||
      videoWithoutMusic_duration == undefined ||
      videoWithoutMusic_duration === 0 ||
      selected_songParent_id === "" ||
      selected_songParent_id == undefined ||
      selected_songParent_name === "" ||
      selected_songParent_name == undefined;

    const { allSubValues } = this.props;
    const subValuesList = [];
    for (let i = 0; i < allSubValues.length; i++) {
      subValuesList.push({
        key: allSubValues[i].id,
        text: allSubValues[i].contentValue,
        value: allSubValues[i].id,
      });
    }

    const { songParent } = this.props;
    const songParentList = [];
    for (let i = 0; i < songParent.length; i++) {

      
      if (!songParent[i].withMusicFolderId || songParent[i].withMusicFolderId=== null ||songParent[i].withMusicFolderId ==="" ||
      !songParent[i].withoutMusicFolderId || songParent[i].withoutMusicFolderId=== null ||songParent[i].withoutMusicFolderId ===""){
        songParentList.push({
          key: songParent[i].id,
          text: songParent[i].name,
          value: [{ id: songParent[i].id }, { name: songParent[i].name },{folderIdWithMusic : ""}
          , {folderIdWithoutMusic : ""}],
        });
      }
      else {
        songParentList.push({
          key: songParent[i].id,
          text: songParent[i].name,
          value: [{ id: songParent[i].id }, { name: songParent[i].name },{folderIdWithMusic : songParent[i].withMusicFolderId}
          , {folderIdWithoutMusic : songParent[i].withoutMusicFolderId}],
        });
      }

    }

    // for dropdown Song Parent selection
    let selectedSongParent = null;
    if (this.state.returned_songParent_id_viewSongParentPage) {
      selectedSongParent = (
        <input
          dir="auto"
          className="form-control"
          fluid
          id="episode_description"
          value={this.state.returned_songParent_name_viewSongParentPage}
        />
      );
    } else {
      selectedSongParent = (
        <Dropdown
        disabled={this.state.videoKeyVideoWithMusic !== "" && this.state.videoKeyVideoWithoutMusic !== ""&&
        this.state.videoKeyVideoWithMusic !== null && this.state.videoKeyVideoWithoutMusic !== null &&
        this.state.videoKeyVideoWithMusic !== undefined && this.state.videoKeyVideoWithoutMusic !== undefined }
          fluid
          selection
          placeholder="Select Song Parent"
          name="songParent_id"
          // id="episode_series_id"
          onChange={this.handleSongParentChange}
          options={songParentList}
        />
      );
    }

    if (this.state.redirection === true) {
      const { redirectionPage } = this.props;
      var pageName = redirectionPage.pageName;
      return <Redirect to={pageName}></Redirect>;
    }

    if (this.state.validateInput === true) {
      return <Redirect to={ADD_SONG_ACTIVITY}></Redirect>;
    }

    return (
      <div className="container">
        <OuterHeader />
        <div className="container">
          <div className="container-body">
            <div className="row">
              <Dashboard />
              <div className="col">
                <InnerHeader />
                <div className="main-content">
                  <div className="row">
                    <div className="col-12 left-main-body" id="left-main-body">
                      <NavBar />
                      <br />
                      <div className="container">
                        <div className="main-progress-bar">
                          <ol className="progress-tracker">
                            <li className="step active">
                              <span className="step-name small-font">
                                Add Song
                              </span>
                            </li>
                            <li className="step">
                              <span className="step-name small-font">
                                Add activity
                              </span>
                            </li>
                            <li className="step ">
                              <span className="step-name small-font">
                                Add Evaluation
                              </span>
                            </li>
                            <li className="step">
                              <span className="step-name small-font">
                                Puplish Song
                              </span>
                            </li>
                          </ol>
                        </div>
                        <br />

                        <form onSubmit={this.handleSubmit.bind(this)}>
                          {/* <p style={{ color: "red" }}>{this.state.errorMessage} </p> */}
                          <br />
                          <label
                                htmlFor="episode_series_id"
                                className="label"
                              >
                                <span className="required_input">*</span>
                                Select Song Parent
                              </label>
                              <br />

                              {selectedSongParent}

                              <br />
                              
                          <label className="label">Video With Music</label>
                        
                               <div>

                               <VideoWithMusicUploader
                               isNew={true}
                                 id="VideoWithMusic"
                                 onUploadInfo={this.handleUploadInfoWithMusic}
                                 folderName = {this.state.video_with_music_folder_id}
                                 disabled={!this.state.video_with_music_folder_id || this.state.video_with_music_folder_id === "" || this.state.video_with_music_folder_id === null ?true : false}
                               />
                             </div>
                               
                         
                          <br />
                          <label className="label">Video Without Music</label>
                        
                               <div>
                               <VideoWithoutMusicUploader
                                    isNew={true}
                                 id="VideoWithoutMusic"
                                 onUploadInfo={this.handleUploadInfoWithoutMusic}
                                 folderName = {this.state.video_with_out_music_folder_id}
                                 disabled={!this.state.video_with_out_music_folder_id || this.state.video_with_out_music_folder_id === "" || this.state.video_with_out_music_folder_id === null ?true : false}
                               />
                             </div>
                              
                         
                          <br />
                          {/* <label className="label">
                           
                            Video with music
                          </label>
                          <UploadVideo
                            getVideoURLCallBack={this.getvideoWithMusicUrl.bind(
                              this
                            )}
                            videoPreview={this.state.videoWithMusicUrl}
                            videoUrl={this.state.videoWithMusicUrl}
                            s3DirectryrName="song"
                          />
                          <br /> */}
                          {/* <label
                            htmlFor="videoWithMusic_duration"
                            className="label"
                          >
                           
                            Video with music duration in seconds
                          </label>
                          <br />
                          <Input
                            disabled
                            fluid
                            type="number"
                            min="0"
                            step="0.01"
                            name="videoWithMusic_duration"
                            id="videoWithMusic_duration"
                            onChange={this.handleChange}
                            value={this.state.videoWithMusic_duration}
                          />
                          <br /> <br /> */}
                          {/* <label className="label">
                            
                            Video without music
                          </label>
                          <UploadVideo
                            getVideoURLCallBack={this.getVideoWithoutMusicUrl.bind(
                              this
                            )}
                            videoPreview={this.state.videoWithoutMusicUrl}
                            videoUrl={this.state.videoWithoutMusicUrl}
                            s3DirectryrName="song"
                          />
                          <br /> */}
                          {/* <label
                            htmlFor="videoWithoutMusic_duration"
                            className="label"
                          >
                          
                            Video with music duration in seconds
                          </label>
                          <br />
                          <Input
                            disabled
                            fluid
                            type="number"
                            min="0"
                            step="0.01"
                            name="videoWithoutMusic_duration"
                            id="videoWithoutMusic_duration"
                            onChange={this.handleChange}
                            value={this.state.videoWithoutMusic_duration}
                          />
                          <br /> <br /> */}
                          <div className="row">
                            <div className="col-4">
                              {/* <span className="required_input">*</span> */}
                              <UploadImage
                                getImageURLCallBack={this.getImageUrl.bind(
                                  this
                                )}
                                imageUrl={this.state.imageUrl}
                                imagepreview={this.state.imageUrl}
                                s3DirectryrName="song"
                              />
                            </div>
                            <div className="col">
                              <p style={{ color: "red" }}>
                                {this.state.errorMessage}{" "}
                              </p>
                              <label htmlFor="song_name" className="label">
                                <span className="required_input">*</span>
                                Song Name
                              </label>
                              <br />

                              <input
                                dir="auto"
                                className="form-control"
                                fluid
                                name="song_name"
                                id="song_name"
                                onChange={this.handleChange}
                                value={this.state.song_name}
                              />
                              <br />

                              <label
                                htmlFor="song_name_in_english"
                                className="label"
                              >
                                {/* <span className="required_input">*</span> */}
                                Song Name In English
                              </label>
                              <br />

                              <input
                                dir="auto"
                                className="form-control"
                                fluid
                                name="song_name_in_english"
                                id="song_name_in_english"
                                onChange={this.handleChange}
                                value={this.state.song_name_in_english}
                              />
                              <br />

                              <label
                                className="label"
                                for="exampleFormControlTextarea1"
                              >
                                Song Description
                              </label>
                              <br />

                              <textarea
                                dir="auto"
                                className="form-control"
                                id="exampleFormControlTextarea1"
                                rows="3"
                                name="description"
                                onChange={this.handleChange}
                                value={this.state.description}
                              />
                              <br />

                              <label
                                className="label"
                                for="exampleFormControlTextarea1"
                              >
                                Song Description In English
                              </label>
                              <br />

                              <textarea
                                dir="auto"
                                className="form-control"
                                id="exampleFormControlTextarea1"
                                rows="3"
                                name="description_in_english"
                                onChange={this.handleChange}
                                value={this.state.description_in_english}
                              />
                              <br />

                              <label htmlFor="name" className="label">
                                Audio
                              </label>

                              <UploadAudio
                                getAudioUrlCallBack={this.getAudio.bind(this)}
                                audioPreview={this.state.voice}
                                audioUrl={this.state.voice}
                                s3DirectryrName="song/audio"
                              />
                              <br />

                             
                              <label
                                htmlFor="episode_series_id"
                                className="label"
                              >
                                <span className="required_input">*</span>
                                Select payment availability
                              </label>
                              <br />

                              <Dropdown
                                required
                                fluid
                                selection
                                placeholder="Select Payment Availability"
                                id="episode_series_id"
                                onChange={this.handlePaymentAva}
                                options={is_free_type}
                                value={this.state.isFree}
                              />
                              <br />

                              <label
                                htmlFor="episode_series_id"
                                className="label"
                              >
                                Sub Value
                              </label>
                              <br />

                              <Dropdown
                                required
                                fluid
                                search
                                selection
                                placeholder="Select Sub Value"
                                id="episode_series_id"
                                onChange={this.handleSubValueChange}
                                options={subValuesList}
                                value={this.state.subValueId}
                              />
                              <br />

                              {/* DataComponent */}
                              {this.state.publishDateTime !== " " &&
                              this.state.publishDateTime !== "" &&
                              this.state.publishDateTime !== null &&
                              this.state.publishDateTime !== undefined ? (
                                <PublishDate
                                  getPublishDateCallBack={this.getPublishDate.bind(
                                    this
                                  )}
                                  date={this.state.publishDateTime}
                                  showNow={!isInvalid}
                                />
                              ) : (
                                <PublishDate
                                  getPublishDateCallBack={this.getPublishDate.bind(
                                    this
                                  )}
                                  value={this.state.publishDateTime}
                                  showNow={!isInvalid}
                                />
                              )}

                              <br />
                                <label>
                                  <input
                                    type="checkbox"
                                    checked={this.state.featured}
                                    onChange={this.handleCheckboxChange}
                                  />
                                  {' Featured'}
                                </label>
                              <br />
                            </div>
                            <div className="buttons_container">
                              {/* <Link to="/addSongActivity"> */}
                              <Button
                                icon
                                labelPosition="right"
                                id="nextButton"
                                disabled={this.state.error}
                              >
                                Next
                                <Icon name="right arrow" />
                              </Button>
                              {/* </Link> */}

                              <Button
                                onClick={this.handleCancel}
                                id="episode_cancel"
                              >
                                Cancel
                              </Button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}
AddSong.propTypes = {
  addSong: propTypes.func.isRequired,
  addActivity: propTypes.func.isRequired,
  fetchAllSubValues: propTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  song: state.songs.item,
  activities: state.activities.item,
  allSubValues: state.ContentSubValue.items,
  songParent: state.songParent.items,
  songParentSelected: state.songParent.item,
  redirectionPage: state.redirectionPage.item,
  // vdocipherUploadInfo: state.vdoCipher.entity,
});

export default withFirebase(
  connect(mapStateToProps, {
    addSong,
    addActivity,
    fetchAllSubValues,
    fetchAllSongParent,
    getSongParentData,
    emptySongParentWithSongs,
    clearPreSignData,
    clearAll,
  })(AddSong)
);
