import React, { Component } from "react";
import { Dropdown, TextArea, Input, Button, Icon } from "semantic-ui-react";
import { Link, Redirect } from "react-router-dom";
/**Other Component */
import PublishDate from "../Layout/PublishDate";
import UploadImage from "../S3Uploading/UploadImage";
import UploadVideo from "../S3Uploading/UploadVideo";
import UploadAudio from "../S3Uploading/UploadAudio";

/*Navbar*/
import OuterHeader from "../Layout/OuterHeader";
import Dashboard from "../Dashboard";
import InnerHeader from "../Layout/InnerHeader";
import NavBar from "../Layout/NavBar";
import Footer from "../Layout/Footer";
/*Redux */
import { connect } from "react-redux";
import propTypes from "prop-types";
import { withFirebase } from "../../config";
/* Actions*/
import { addEpisode } from "../../Actions/EpisodeActions";
import { fetchAllSeries ,emptySeriesWithEpisode , getSeriesType , getSeriesData ,clearSeriesType} from "../../Actions/SeriesActions";
import { emptyDiscoverSeriesItem , getDiscoverUsSeriesData } from "../../Actions/DiscoverUsSeriesActions";
import { addActivity } from "../../Actions/ActivityActions";

import { fetchAllSubValues } from "../../Actions/ContentSubValueAction";
/*CSS*/
import "../../css/AppCss/Episode/addEpisode.css";
import "../../css/AppCss/Episode/EpisodeButtons.css";
/* Routes */
import { ADD_EPISODE_ACTIVITY } from "../../routes";
import { clearPreSignData, clearAll } from "../../Actions/VideoCipherActions";
import VideoWithMusicUploader from "../FilePondUploading/VideoWithMusicUploader";
import VideoWithoutMusicUploader from "../FilePondUploading/VideoWithoutMusicUploader";
import { EPISODE_FOLDER_NAME } from "../../portal/src/constants";
import RenderVideoWithMusic from "../VideoPreview/RenderVideoWithMusic";
import RenderVideoWithoutMusic from "../VideoPreview/RenderVideoWithoutMusic";

const is_free_type = [
  {
    key: 0,
    text: "paid",
    value: "paid",
  },
  {
    key: 1,
    text: "free",
    value: "free",
  },
];

const INITIAL_STATE = {
  selected_series_id: "",
  selected_series_name: "",
  video_with_music_folder_id: "",
  video_with_out_music_folder_id: "",
  episode_name: "",
  episode_imageUrl: "",
  episode_description: "",
  episode_number: 0,
  episode_name_in_english: "",
  episode_description_in_english: "",

  episode_videoUrlwithMusic: "",
  episode_videoUrlwithoutMusic: "",

  episode_videoUrlwithMusic_duration: 0,
  episode_videoUrlwithoutMusic_duration: 0,
  videoKeyVideoWithMusic: "",
  videoKeyVideoWithoutMusic: "",
  VideoWithMusicReady: false,
  VideoWithoutMusicReady: false,
  episode_publishDate: "",
  isFree: "",
  returned_series_id_viewSeriesPage: "",
  returned_series_name_viewSeriesPage: "",

  error: "",
  auth_user: {},
  radiobutton: "",
  validateInput: false,
  voice: "",

  subValueId: "",
  redirection: false,
  series_type : "",

  featured: false
};

class AddEpisode extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }


  componentDidUpdate(prevProps) {

    if (this.state.series_type === "NORMAL" ){
      const {selectedSeries} = this.props;

      if (selectedSeries !== prevProps.selectedSeries) {
     
        this.handleSelectedSeriesChange();
      }
  
    
    }
  
    else  if (this.state.series_type === "DISCOVER"){
      const { selectedDiscoverUsSeries } = this.props;
  
      if (selectedDiscoverUsSeries !== prevProps.selectedDiscoverUsSeries) {
     
        this.handleSelectedDiscoverUsSeriesChange();
      }
  
    }
  }

  handleSelectedDiscoverUsSeriesChange (){
    
    if (this.props.selectedDiscoverUsSeries && this.props.selectedDiscoverUsSeries !== null && this.props.selectedDiscoverUsSeries !== undefined && this.props.selectedDiscoverUsSeries !== ""){
     
      if (this.props.selectedDiscoverUsSeries.withMusicFolderId  && this.props.selectedDiscoverUsSeries.withMusicFolderId !== null && this.props.selectedDiscoverUsSeries.withMusicFolderId !== undefined){
        this.setState ({
          video_with_music_folder_id :this.props.selectedDiscoverUsSeries.withMusicFolderId ,
        })
      }
    
      if (this.props.selectedDiscoverUsSeries.withoutMusicFolderId  && this.props.selectedDiscoverUsSeries.withoutMusicFolderId !== null && this.props.selectedDiscoverUsSeries.withoutMusicFolderId !== undefined){
        this.setState ({
          video_with_out_music_folder_id :this.props.selectedDiscoverUsSeries.withoutMusicFolderId ,
        })
      }
     }
  }
  
  handleSelectedSeriesChange() {

    if (this.props.selectedSeries && this.props.selectedSeries !== null && this.props.selectedSeries !== undefined && this.props.selectedSeries !== ""){
     
      if (this.props.selectedSeries.withMusicFolderId  && this.props.selectedSeries.withMusicFolderId !== null && this.props.selectedSeries.withMusicFolderId !== undefined){
        this.setState ({
          video_with_music_folder_id :this.props.selectedSeries.withMusicFolderId ,
        })
      }
    
      if (this.props.selectedSeries.withoutMusicFolderId  && this.props.selectedSeries.withoutMusicFolderId !== null && this.props.selectedSeries.withoutMusicFolderId !== undefined){
        this.setState ({
          video_with_out_music_folder_id :this.props.selectedSeries.withoutMusicFolderId ,
        })
      }
     }
  }


  componentDidMount() {
    this.props.fetchAllSeries();
    this.props.fetchAllSubValues();
    this.props.clearAll();
    this.props.clearSeriesType();

  

    const { episodes , series_type} = this.props;

    if (episodes && episodes.selected_series_id && series_type === "NORMAL" ){
      this.setState({
        series_type : "NORMAL"
        })
      this.props.getSeriesData(episodes.selected_series_id);

      if(episodes.videoKeyVideoWithMusic && episodes.videoKeyVideoWithMusic !== null && episodes.videoKeyVideoWithMusic !== "" && episodes.videoKeyVideoWithMusic !== undefined ){
        this.setState({ VideoWithMusicReady: true });
      }
      if(episodes.videoKeyVideoWithoutMusic && episodes.videoKeyVideoWithoutMusic !== null && episodes.videoKeyVideoWithoutMusic !== "" && episodes.videoKeyVideoWithoutMusic !== undefined ){
        this.setState({ VideoWithoutMusicReady: true });
      }
    }
    else {
      this.props.emptySeriesWithEpisode();
    }

  if (episodes && episodes.selected_series_id && series_type === "DISCOVER" ){
    this.setState({
      series_type : "DISCOVER"
        })
    this.props.getDiscoverUsSeriesData(episodes.selected_series_id);

    if(episodes.videoKeyVideoWithMusic && episodes.videoKeyVideoWithMusic !== null && episodes.videoKeyVideoWithMusic !== "" && episodes.videoKeyVideoWithMusic !== undefined ){
      this.setState({ VideoWithMusicReady: true });
      }
    if(episodes.videoKeyVideoWithoutMusic && episodes.videoKeyVideoWithoutMusic !== null && episodes.videoKeyVideoWithoutMusic !== "" && episodes.videoKeyVideoWithoutMusic !== undefined ){
      this.setState({ VideoWithoutMusicReady: true });
    }
  }

  else {
this.props.emptyDiscoverSeriesItem();
  }

  if (series_type === "NORMAL"){
    this.setState({
      series_type : "NORMAL"
    })

    if (this.props.selectedSeries && this.props.selectedSeries !== null && this.props.selectedSeries !== undefined && this.props.selectedSeries !== ""){
   
     if (this.props.selectedSeries.withMusicFolderId  && this.props.selectedSeries.withMusicFolderId !== null && this.props.selectedSeries.withMusicFolderId !== undefined){
       this.setState ({
         video_with_music_folder_id :this.props.selectedSeries.withMusicFolderId ,
       })
     }
   
     if (this.props.selectedSeries.withoutMusicFolderId  && this.props.selectedSeries.withoutMusicFolderId !== null && this.props.selectedSeries.withoutMusicFolderId !== undefined){
       this.setState ({
         video_with_out_music_folder_id :this.props.selectedSeries.withoutMusicFolderId ,
       })
     }
    }
  }

  else  if (series_type === "DISCOVER"){
    this.setState({
      series_type : "DISCOVER"
    })
    // const { selectedDiscoverUsSeries } = this.props;

    if (this.props.selectedDiscoverUsSeries && this.props.selectedDiscoverUsSeries !== null && this.props.selectedDiscoverUsSeries !== undefined && this.props.selectedDiscoverUsSeries !== ""){
   
     if (this.props.selectedDiscoverUsSeries.withMusicFolderId  && this.props.selectedDiscoverUsSeries.withMusicFolderId !== null && this.props.selectedDiscoverUsSeries.withMusicFolderId !== undefined){
       this.setState ({
         video_with_music_folder_id :this.props.selectedDiscoverUsSeries.withMusicFolderId ,
       })
     }
   
     if (this.props.selectedDiscoverUsSeries.withoutMusicFolderId  && this.props.selectedDiscoverUsSeries.withoutMusicFolderId !== null && this.props.selectedDiscoverUsSeries.withoutMusicFolderId !== undefined){
       this.setState ({
         video_with_out_music_folder_id :this.props.selectedDiscoverUsSeries.withoutMusicFolderId ,
       })
     }
    }
  }


 
    this.setState({
      episode_name: episodes.episode_name,
      episode_imageUrl: episodes.episode_imageUrl,
      episode_description: episodes.episode_description,
      episode_number: episodes.episode_number,
      episode_name_in_english: episodes.episode_name_in_english,
      episode_description_in_english: episodes.episode_description_in_english,

      episode_videoUrlwithMusic: episodes.episode_videoUrlwithMusic,
      episode_videoUrlwithoutMusic: episodes.episode_videoUrlwithoutMusic,

      episode_videoUrlwithMusic_duration:
        episodes.episode_videoUrlwithMusic_duration,
      episode_videoUrlwithoutMusic_duration:
        episodes.episode_videoUrlwithoutMusic_duration,
      videoKeyVideoWithMusic: episodes.videoKeyVideoWithMusic,
      videoKeyVideoWithoutMusic: episodes.videoKeyVideoWithoutMusic,

      episode_publishDate: episodes.episode_publishDate,
      returned_series_id_viewSeriesPage: episodes.selected_series_id,
      returned_series_name_viewSeriesPage: episodes.selected_series_name,

      selected_series_id: episodes.selected_series_id,
      selected_series_name: episodes.selected_series_name,
      //       video_with_music_folder_id :episodes.video_with_music_folder_id ,
      // video_with_out_music_folder_id : episodes.video_with_out_music_folder_id,
      isFree: episodes.isFree,
      voice: episodes.voice,

      subValueId: episodes.subValueId,
      radiobutton: episodes.radiobutton,
    });

    

    const { seriesId, seriesName ,withMusicFolderId,withoutMusicFolderId,seriesType
    } = this.props.location;
    if (seriesId) {

      this.setState({
        returned_series_name_viewSeriesPage: seriesName,
        returned_series_id_viewSeriesPage: seriesId,
        selected_series_id: seriesId,
        selected_series_name: seriesName,
      });
    }

    if (withMusicFolderId && withMusicFolderId !== null & withMusicFolderId !== undefined) {
      this.setState({ video_with_music_folder_id: withMusicFolderId })
    }

    if (withoutMusicFolderId && withoutMusicFolderId !== null & withoutMusicFolderId !== undefined) {
      this.setState({ video_with_out_music_folder_id: withoutMusicFolderId })
    }

  }

  handleUploadInfoWithMusic = (uploadInfo) => {
    if (
      uploadInfo &&
      uploadInfo !== undefined &&
      uploadInfo !== null &&
      Object.keys(uploadInfo).length > 0
    ) {
      this.setState({ videoKeyVideoWithMusic: uploadInfo.videoId });
    } else {
      this.setState({ videoKeyVideoWithMusic: this.state.videoKeyVideoWithMusic });
    }
  };
  handleUploadInfoWithoutMusic = (uploadInfo) => {
    if (
      uploadInfo &&
      uploadInfo !== undefined &&
      uploadInfo !== null &&
      Object.keys(uploadInfo).length > 0
    ) {
      this.setState({ videoKeyVideoWithoutMusic: uploadInfo.videoId });
    } else {
      this.setState({ videoKeyVideoWithoutMusic:  this.state.videoKeyVideoWithoutMusic  });
    }
  };

  handleChangeVideoWithMusic = (uploadInfo) => {

    if (
      uploadInfo &&
      uploadInfo !== undefined &&
      uploadInfo !== null &&
      Object.keys(uploadInfo).length > 0
    ) {
      this.setState({ videoKeyVideoWithMusic: uploadInfo.videoId });
    } 
    else {
      this.setState({ videoKeyVideoWithMusic: this.state.videoKeyVideoWithMusic });
    }
  };
  handleChangeVideoWithoutMusic = (uploadInfo) => {

    if (
      uploadInfo &&
      uploadInfo !== undefined &&
      uploadInfo !== null &&
      Object.keys(uploadInfo).length > 0
    ) {
      this.setState({ videoKeyVideoWithoutMusic: uploadInfo.videoId });
    } 
    else {
      this.setState({ videoKeyVideoWithoutMusic: this.state.videoKeyVideoWithoutMusic });
    }
  };

  // setVideoWithMusicReady = (isReady) => {

  //   if (
  //     isReady
  //   )
  //     this.setState({ VideoWithMusicReady: true });
  // };
  // setVideoWithoutMusicReady = (isReady) => {

  //   if (
  //     isReady
  //   )
  //     this.setState({ VideoWithoutMusicReady: true });
  // };

  postData(
    episode_name,
    episode_description,
    episode_number,
    selected_series_id,
    selected_series_name,
    episode_publishDate,
    episode_imageUrl,
    episode_videoUrlwithMusic,
    episode_videoUrlwithoutMusic,
    episode_videoUrlwithMusic_duration,
    episode_videoUrlwithoutMusic_duration,
    radiobutton,
    isFree,
    voice,
    subValueId,
    episode_name_in_english,
    episode_description_in_english,
    videoKeyVideoWithMusic,
    videoKeyVideoWithoutMusic,
    featured
  ) {
    this.props.addEpisode(
      episode_name,
      episode_description,
      episode_number,
      selected_series_id,
      selected_series_name,
      episode_publishDate,
      episode_imageUrl,
      episode_videoUrlwithMusic,
      episode_videoUrlwithoutMusic,
      episode_videoUrlwithMusic_duration,
      episode_videoUrlwithoutMusic_duration,
      radiobutton,
      isFree,
      voice,
      subValueId,
      episode_name_in_english,
      episode_description_in_english,
      videoKeyVideoWithMusic,
      videoKeyVideoWithoutMusic,
      featured
    ); // add new fileds
  }

  handleChange = (e) => {
    switch (e.target.name) {
      default:
        this.setState({ [e.target.name]: e.target.value });
    }
  };

  handleSeriesChange = (e, { value }) => {
    this.setState({ selected_series_id: value[0].id });
    this.setState({ selected_series_name: value[1].name });
    this.setState({ video_with_music_folder_id: value[2].folderIdWithMusic });
    this.setState({ video_with_out_music_folder_id: value[3].folderIdWithoutMusic });

  };

  handlePaymentAva = (e, { value }) => {


    this.setState({
      isFree: value,
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();



    if (e.nativeEvent.submitter.id === "nextButton") {
      const {
        episode_name,
        episode_description,
        episode_number,
        selected_series_id,
        selected_series_name,
        episode_publishDate,
        episode_imageUrl,
        episode_videoUrlwithMusic,
        episode_videoUrlwithoutMusic,
        episode_videoUrlwithMusic_duration,
        episode_videoUrlwithoutMusic_duration,
        radiobutton,
        isFree,
        voice,
        subValueId,
        episode_name_in_english,
        episode_description_in_english,
        videoKeyVideoWithMusic,
        videoKeyVideoWithoutMusic,
        VideoWithMusicReady,
        VideoWithoutMusicReady,
        featured
      } = this.state;


      this.props.getSeriesType(selected_series_id);
      const specialCharacters = /[@#$%^&*()\+={}[\]:;<>,\/|\\]/; 

      if (specialCharacters.test(episode_name) || specialCharacters.test(episode_name_in_english)){
        alert("Episode name can't contain spaecial characters");
        this.setState({ validateInput: false });
      }
      else if (episode_name === "" || episode_name == undefined) {
        alert("Empty Episode Name");
        this.setState({ validateInput: false });
      } else if (
        episode_number === "" ||
        episode_number == undefined ||
        episode_number == 0
      ) {
        alert("Empty Episode Number");
        this.setState({ validateInput: false });
      } else if (
        selected_series_id === "" ||
        selected_series_id == undefined ||
        selected_series_name === "" ||
        selected_series_name == undefined
      ) {
        alert("Empty Series ");
        this.setState({ validateInput: false });
      } else if (
        isFree === "" ||
        isFree == undefined ||
        isFree == null ||
        isFree == ""
      ) {
        alert("You must choose payment availability");
        this.setState({ validateInput: false });
      } else if (
        episode_publishDate === "" ||
        episode_publishDate == undefined
      ) {
        alert("Empty Publish Date");
        this.setState({ validateInput: false });
      } else if (
        this.state.episode_description_in_english &&
        this.state.episode_description_in_english.length > 255
      ) {
        alert(
          "Description in English is too long (greater than 255 characters)."
        );
        this.setState({ validateInput: false });
      }
      // else if (episode_imageUrl === ''
      //     || episode_imageUrl == undefined) {
      //     alert("Empty Image")
      //     this.setState({ validateInput: false })

      // }
      // else if (
      //   (episode_videoUrlwithMusic === "" ||
      //     episode_videoUrlwithMusic === undefined) &&
      //   (videoKeyVideoWithMusic === "" || videoKeyVideoWithMusic === undefined)
      // ) {
      //   alert("Empty Video With Music");
      //   this.setState({ validateInput: false });
      // }
      //  else if (
      //   (episode_videoUrlwithoutMusic === "" ||
      //     episode_videoUrlwithoutMusic === undefined) &&
      //   (videoKeyVideoWithoutMusic === "" ||
      //     videoKeyVideoWithoutMusic === undefined)
      // ) {
      //   alert("Empty Video Without Music");
      //   this.setState({ validateInput: false });
      // } 
      // else if (
      //   videoKeyVideoWithMusic !== "" &&
      //   videoKeyVideoWithMusic !== undefined &&
      //   !VideoWithMusicReady
      // ) {
      //   alert("Please wait until Video is ready for playback");
      //   this.setState({ validateInput: false });
      // } else if (
      //   videoKeyVideoWithoutMusic !== "" &&
      //   videoKeyVideoWithoutMusic !== undefined &&
      //   !VideoWithoutMusicReady
      // ) {
      //   alert("Please wait until Video is ready for playback");
      //   this.setState({ validateInput: false });
      // }
      // else if (episode_videoUrlwithMusic_duration === ''
      //     || episode_videoUrlwithMusic_duration == undefined
      //     || episode_videoUrlwithMusic_duration == 0) {
      //     alert("Empty Video With Music Duration")
      //     this.setState({ validateInput: false })

      // }

      // else if (episode_videoUrlwithoutMusic_duration === ''
      //     || episode_videoUrlwithoutMusic_duration == undefined
      //     || episode_videoUrlwithoutMusic_duration == 0) {
      //     alert("Empty Video Without Music Duration")
      //     this.setState({ validateInput: false })
      // }
      else {
        this.setState({ validateInput: true });

        this.postData(
          episode_name,
          episode_description,
          episode_number,
          selected_series_id,
          selected_series_name,
          episode_publishDate,
          episode_imageUrl,
          episode_videoUrlwithMusic,
          episode_videoUrlwithoutMusic,
          episode_videoUrlwithMusic_duration,
          episode_videoUrlwithoutMusic_duration,
          radiobutton,
          isFree,
          voice,
          subValueId,
          episode_name_in_english,
          episode_description_in_english,
          videoKeyVideoWithMusic,
          videoKeyVideoWithoutMusic,
          featured
        );
      }
    }
  };

  handleCancel = (e) => {
    this.setState({ redirection: true });
  };

  handleSubValueChange = (e, { value }) => {
    this.setState({ subValueId: value });
  };

  handleCheckboxChange = () => {
    this.setState({ featured: !this.state.featured })
  };

  getPublishDate(publishDateTime, radiobutton) {
    if (radiobutton) {
      this.setState({ radiobutton: radiobutton });
    }

    if (publishDateTime) {
      this.setState({ episode_publishDate: publishDateTime });

    }
  }

  getImageUrl(imageUrl) {
    if (imageUrl) {
      this.setState({ episode_imageUrl: imageUrl });

    }
  }

  getVideoUrl(videoUrl, duration) {
    if (videoUrl !== "" && videoUrl !== "delete") {
      this.setState({
        episode_videoUrlwithMusic: videoUrl,
        episode_videoUrlwithMusic_duration: duration,
      });

    } else {
      this.setState({
        episode_videoUrlwithMusic: "",
        episode_videoUrlwithMusic_duration: 0,
      });
    }
  }

  getVideoUrlWithoutMusic(videoUrl, duration) {
    if (videoUrl !== "" && videoUrl !== "delete") {
      this.setState({
        episode_videoUrlwithoutMusic: videoUrl,
        episode_videoUrlwithoutMusic_duration: duration,
      });

    } else {
      this.setState({
        episode_videoUrlwithoutMusic: "",
        episode_videoUrlwithoutMusic_duration: 0,
      });
    }
  }

  getAudio(audioUrl) {
    // get  Audio
    if (audioUrl) {
      // check if url =  delete send empty to DB
      if (audioUrl === "delete") {
        this.setState({ voice: "" });

      }
      // else send the url to DB
      else {
        this.setState({ voice: audioUrl });

      }
    }
  }

  render() {


    const { allSubValues } = this.props;
    const subValuesList = [];
    for (let i = 0; i < allSubValues.length; i++) {
      subValuesList.push({
        key: allSubValues[i].id,
        text: allSubValues[i].contentValue,
        value: allSubValues[i].id,
      });
    }

    const { seriesType } = this.props.location;

    let series = null;
    if (seriesType === "Discover") {
      series = this.props.discoverUsSeries;
    } else {
      series = this.props.series;
    }

    const seriesList = [];
    for (let i = 0; i < series.length; i++) {

      if (!series[i].withMusicFolderId || series[i].withMusicFolderId === null || series[i].withMusicFolderId === "" ||
        !series[i].withoutMusicFolderId || series[i].withoutMusicFolderId === null || series[i].withoutMusicFolderId === "") {
        seriesList.push({
          key: series[i].id,
          text: series[i].name,
          value: [{ id: series[i].id }, { name: series[i].name }, { folderIdWithMusic: "" }
            , { folderIdWithoutMusic: "" }],
        });
      }
      else {
        seriesList.push({
          key: series[i].id,
          text: series[i].name,
          value: [{ id: series[i].id }, { name: series[i].name }, { folderIdWithMusic: series[i].withMusicFolderId }
            , { folderIdWithoutMusic: series[i].withoutMusicFolderId }],
        });
      }

    }

    // for dropdown series selection
    let selectedSeries = null;
    if (this.state.returned_series_id_viewSeriesPage) {
    
      selectedSeries = (
        <input
          dir="auto"
          className="form-control"
          fluid
          id="episode_description"
          value={this.state.returned_series_name_viewSeriesPage}
        />
      );
    } else {
      selectedSeries = (
        <Dropdown
          disabled={this.state.videoKeyVideoWithMusic !== "" && this.state.videoKeyVideoWithoutMusic !== "" &&
            this.state.videoKeyVideoWithMusic !== null && this.state.videoKeyVideoWithoutMusic !== null &&
            this.state.videoKeyVideoWithMusic !== undefined && this.state.videoKeyVideoWithoutMusic !== undefined}
          fluid
          selection
          placeholder="Select Series"
          name="episode_series_id"
          id="episode_series_id"
          onChange={this.handleSeriesChange}
          options={seriesList}
        />
      );
    }

    if (this.state.redirection === true) {
      const { redirectionPage } = this.props;
      var pageName = redirectionPage.pageName;
      return <Redirect to={pageName}></Redirect>;
    }

    const {
      episode_name,
      episode_description,
      episode_number,
      selected_series_id,
      selected_series_name,
      episode_publishDate,
      episode_imageUrl,
      episode_videoUrlwithMusic,
      episode_videoUrlwithoutMusic,
      episode_videoUrlwithMusic_duration,
      episode_videoUrlwithoutMusic_duration,
      episode_name_in_english,
      episode_description_in_english,
      videoKeyVideoWithMusic,
      videoKeyVideoWithoutMusic,
    } = this.state;

    const isInvalid =
      episode_name === "" ||
      episode_name == undefined ||
      selected_series_name === "" ||
      selected_series_name == undefined ||
      episode_imageUrl === "" ||
      episode_imageUrl == undefined ||
      episode_videoUrlwithMusic === "" ||
      episode_videoUrlwithMusic == undefined ||
      episode_videoUrlwithoutMusic === "" ||
      episode_videoUrlwithoutMusic == undefined ||
      episode_videoUrlwithMusic_duration === 0 ||
      episode_videoUrlwithMusic_duration == undefined ||
      episode_videoUrlwithoutMusic_duration === 0 ||
      episode_videoUrlwithoutMusic_duration == undefined ||
      episode_number === "" ||
      episode_number == undefined ||
      selected_series_id === "" ||
      selected_series_id == undefined;


    if (this.state.validateInput == true) {
      return <Redirect to={ADD_EPISODE_ACTIVITY}></Redirect>;
    }

    return (
      <div className="container">
        <OuterHeader />
        <div className="container">
          <div className="container-body">
            <div className="row">
              <Dashboard />
              <div className="col">
                <InnerHeader />
                <div className="main-content">
                  <div className="row">
                    <div className="col-12 left-main-body" id="left-main-body">
                      <NavBar />
                      <br />
                      <div className="container">
                        <div className="main-progress-bar">
                          <ol className="progress-tracker">
                            <li className="step active">
                              <span className="step-name small-font">
                                Add Episode
                              </span>
                            </li>
                            <li className="step">
                              <span className="step-name small-font">
                                Add activity
                              </span>
                            </li>
                            <li className="step ">
                              <span className="step-name small-font">
                                Add Evaluation
                              </span>
                            </li>
                            <li className="step">
                              <span className="step-name small-font">
                                Puplish Episode
                              </span>
                            </li>
                          </ol>
                        </div>

                        <br />
                        <form onSubmit={this.handleSubmit.bind(this)}>
                          <label
                            htmlFor="episode_series_id"
                            className="label"
                          >
                            <span className="required_input">*</span>
                            Select the Series
                          </label>
                          <br />

                          {selectedSeries}
                          {/* <Input fluid id="episode_description" value="jjjjjjjjjjjjjjjjjjjjj" />
                                                            <Dropdown fluid selection placeholder='Select Series' name="episode_series_id" id="episode_series_id" onChange={this.handleSeriesChange} options={seriesList} /> */}
                          <br />
                          <label className="label">Video With Music</label>
                         
                          <div>
                            <VideoWithMusicUploader
                             isNew={true}
                              id="VideoWithMusic"
                              onUploadInfo={this.handleUploadInfoWithMusic}
                                 folderName = {this.state.video_with_music_folder_id}
                                 disabled={!this.state.video_with_music_folder_id || this.state.video_with_music_folder_id === "" || this.state.video_with_music_folder_id === null ?true : false}
                            />
                          </div>
                              
                         
                          <br />
                          <label className="label">Video Without Music</label>
                         
                          <div>
                            <VideoWithoutMusicUploader
                                 isNew={true}
                              id="VideoWithoutMusic"
                              onUploadInfo={this.handleUploadInfoWithoutMusic}
                                 folderName = {this.state.video_with_out_music_folder_id}
                                 disabled={!this.state.video_with_out_music_folder_id || this.state.video_with_out_music_folder_id === "" || this.state.video_with_out_music_folder_id === null ?true : false}
                            />
                          </div>
                              
                         
                          <br />
                          {/* <label className="label">Video with music</label>
                          <div>
                            <UploadVideo
                              getVideoURLCallBack={this.getVideoUrl.bind(this)}
                              videoPreview={
                                this.state.episode_videoUrlwithMusic
                              }
                              videoUrl={this.state.episode_videoUrlwithMusic}
                              s3DirectryrName="episode"
                            />
                          </div>
                          <br /> */}
                          {/* <label
                            htmlFor="episode_videoUrlwithMusic_duration"
                            className="label"
                          >
                            Video with music duration in seconds
                          </label>
                          <br />
                          <Input
                            disabled
                            type="number"
                            min="0"
                            step="0.01"
                            fluid
                            name="episode_videoUrlwithMusic_duration"
                            id="episode_videoUrlwithMusic_duration"
                            onChange={this.handleChange}
                            value={
                              this.state.episode_videoUrlwithMusic_duration
                            }
                          />
                          <br />
                          <br /> <br /> */}
                          {/* <label className="label">Video with out music</label>
                          <div>
                            <UploadVideo
                              getVideoURLCallBack={this.getVideoUrlWithoutMusic.bind(
                                this
                              )}
                              videoPreview={
                                this.state.episode_videoUrlwithoutMusic
                              }
                              videoUrl={this.state.episode_videoUrlwithoutMusic}
                              s3DirectryrName="episode"
                            />
                          </div>
                          <br /> */}
                          {/* <label
                            htmlFor="episode_videoUrlwithoutMusic_duration"
                            className="label"
                          >
                    
                            Video WithOut Music Duration in seconds
                          </label>
                          <br />
                          <Input
                            disabled
                            type="number"
                            min="0"
                            step="0.01"
                            fluid
                            name="episode_videoUrlwithoutMusic_duration"
                            id="episode_videoUrlwithoutMusic_duration"
                            onChange={this.handleChange}
                            value={
                              this.state.episode_videoUrlwithoutMusic_duration
                            }
                          /> */}
                          {/* <br />
                          <br /> <br /> */}
                          <div className="row">
                            {/*  image dropzone*/}
                            <div className="col-4">
                              {/* <span className="required_input">*</span> */}
                              <UploadImage
                                getImageURLCallBack={this.getImageUrl.bind(
                                  this
                                )}
                                imageUrl={this.state.episode_imageUrl}
                                imagepreview={this.state.episode_imageUrl}
                                s3DirectryrName="episode"
                              />
                            </div>

                            <div className="col">
                              <label htmlFor="episode_name" className="label">
                                <span className="required_input">*</span>
                                Episode Name
                              </label>
                              <br />
                              <input
                                dir="auto"
                                className="form-control"
                                fluid
                                name="episode_name"
                                id="episode_name"
                                onChange={this.handleChange}
                                value={this.state.episode_name}
                              />
                              <br />

                              <label
                                htmlFor="episode_name_in_english"
                                className="label"
                              >
                                {/* <span className="required_input">*</span> */}
                                Episode Name In English
                              </label>
                              <br />
                              <input
                                dir="auto"
                                className="form-control"
                                fluid
                                name="episode_name_in_english"
                                id="episode_name_in_english"
                                onChange={this.handleChange}
                                value={this.state.episode_name_in_english}
                              />
                              <br />

                              <label htmlFor="name" className="label">
                                Audio
                              </label>

                              <UploadAudio
                                getAudioUrlCallBack={this.getAudio.bind(this)}
                                audioPreview={this.state.voice}
                                audioUrl={this.state.voice}
                                s3DirectryrName="episode/audio"
                              />

                              <br />
                              <label htmlFor="episode_number" className="label">
                                <span className="required_input">*</span>
                                Episode Number
                              </label>
                              <br />
                              <input
                                dir="auto"
                                className="form-control"
                                fluid
                                type="number"
                                min="0"
                                name="episode_number"
                                id="episode_number"
                                onChange={this.handleChange}
                                value={this.state.episode_number}
                              />
                              <br />



                              <label
                                htmlFor="episode_series_id"
                                className="label"
                              >
                                <span className="required_input">*</span>
                                Select payment availability
                              </label>
                              <br />

                              <Dropdown
                                required
                                fluid
                                selection
                                placeholder="Select Payment Availability"
                                id="episode_series_id"
                                onChange={this.handlePaymentAva}
                                options={is_free_type}
                                value={this.state.isFree}
                              />
                              <br />

                              <label
                                htmlFor="episode_series_id"
                                className="label"
                              >
                                Sub Value
                              </label>
                              <br />

                              <Dropdown
                                fluid
                                search
                                selection
                                placeholder="Select Sub Value"
                                id="episode_series_id"
                                onChange={this.handleSubValueChange}
                                options={subValuesList}
                                value={this.state.subValueId}
                              />
                              <br />

                              {/* DataComponent */}
                              <PublishDate
                                getPublishDateCallBack={this.getPublishDate.bind(
                                  this
                                )}
                                value={this.state.episode_publishDate}
                                showNow={!isInvalid}
                              />
                              <br />
                              <label>
                                <input
                                  type="checkbox"
                                  checked={this.state.featured}
                                  onChange={this.handleCheckboxChange}
                                />
                                {' Featured'}
                              </label>
                              <br />
                              <label
                                htmlFor="episode_description"
                                className="label"
                              >
                                Description
                              </label>
                              <br />
                              <textarea
                                dir="auto"
                                className="form-control"
                                fluid
                                rows="6"
                                name="episode_description"
                                id="episode_description"
                                onChange={this.handleChange}
                                value={this.state.episode_description}
                              />
                              <br />

                              <label
                                htmlFor="episode_description_in_english"
                                className="label"
                              >
                                Description In English
                              </label>
                              <br />
                              <textarea
                                dir="auto"
                                className="form-control"
                                fluid
                                rows="6"
                                name="episode_description_in_english"
                                id="episode_description_in_english"
                                onChange={this.handleChange}
                                value={
                                  this.state.episode_description_in_english
                                }
                              />
                              <br />
                            </div>
                            <div className="buttons_container">
                              <Button
                                icon
                                labelPosition="right"
                                id="nextButton"
                              >
                                Next
                                <Icon name="right arrow" />
                              </Button>

                              <Button
                                id="episode_cancel"
                                onClick={this.handleCancel}
                              >
                                Cancel
                              </Button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}
AddEpisode.propTypes = {
  fetchAllSeries: propTypes.func.isRequired,
  addActivity: propTypes.func.isRequired,
  addEpisode: propTypes.func.isRequired,
  series: propTypes.func.isRequired,
  fetchAllSubValues: propTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  episodes: state.episodes.item,
  activities: state.activities.item,
  series: state.series.items,
  discoverUsSeries: state.discoverUsSeries.items,
  selectedDiscoverUsSeries: state.discoverUsSeries.item,
  selectedSeries: state.series.item,
  allSubValues: state.ContentSubValue.items,
  redirectionPage: state.redirectionPage.item,
  series_type : state.series.series_type
  // videoWithMusicUploadInfo: state.vdoCipher.videoWithMusic,
  // videoWithoutMusicUploadInfo: state.vdoCipher.videoWithoutMusic,
});

export default withFirebase(
  connect(mapStateToProps, {
    fetchAllSeries,
    addEpisode,
    addActivity,
    fetchAllSubValues,
    emptyDiscoverSeriesItem,
    emptySeriesWithEpisode,
    clearPreSignData,
    clearAll,
    getSeriesType,
    getSeriesData,
    getDiscoverUsSeriesData,
    clearSeriesType,
  })(AddEpisode)
);
