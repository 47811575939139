import { LOAD_SUBSCRIPTIONS_REPORT,
         GET_SUBSCRIPTIONS_REPORT,
         ERROR_LOADING_SUBSCRIPTIONS_REPORT,
         EMPTY_SUBSCRIPTIONS_REPORT} from "../Actions/Types";

const initial_state = {
    items: [],
    loading: '',
    error_loading_subscriptions_report: '',
}

export default function (state = initial_state, action){
    switch(action.type){
        case LOAD_SUBSCRIPTIONS_REPORT:
            return {
                ...state,
                loading: true,
            }
        
        case GET_SUBSCRIPTIONS_REPORT:
            return {
                ...state,
                items: action.payload,
                loading:false,
                error_loading_subscriptions_report:'',
            }
        
        case ERROR_LOADING_SUBSCRIPTIONS_REPORT:
            return{
                ...state,
                loading: false,
                error_loading_subscriptions_report: action.payload,
            }
        case EMPTY_SUBSCRIPTIONS_REPORT:
            return{
                ...state,
                items: '',
                loading: '',
                error_loading_subscriptions_report:'',
            }
            
        default:
            return state;    
    }
}