import React, { Component } from "react";
import { Redirect, Route } from "react-router-dom";
import mainLogo from "../images/main-logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, NavLink, withRouter } from "react-router-dom";
import "../css/AppCss/ContentMainValue/contentMainValue.css";
/*Redux */
import "firebase/firestore";
import "firebase/auth";
import { withFirebase } from "../config";
import firebase from "firebase/app";
import { connect } from "react-redux";
import propTypes from "prop-types";

/* Actions */
import { getUserData } from "../Actions/MobilePaymentProfileAction";

/* Routes */
import {
  SIGN_IN, CONTENT_SETTING, HOME, USER_MANAGEMENT, VENDORS,
  ALL_SERIES, CUSTOMERS, ALL_BLOGS, ALL_ISSUES, TERMS_AND_CONDITIONS,
  ALL_FAQ, GENERAL_SETTING, REPORT, RECOMMENDATION, PAYMENT_PACKAGES,
  PROMO_CODES, CONTENT_VALUES, IN_APP_NOTIFICATION, PAYMENT_COUNTRIES ,ALL_BANNERS , SECTIONS ,SETTINGS, ALL_EDUCATIONAL_VIDEOS
} from '../routes';

class Dashboard extends Component {
  constructor(props) {
    super(props);
    if (firebase.auth().currentUser) {
      firebase
        .auth()
        .currentUser.getIdToken()
        .then(token => {
          this.props.getUserData(token);
        });
    }
  }

  componentDidMount() {
    const { userPaymentProfile } = this.props;
    const { auth } = this.props;
    if (!auth.uid) {
      return <Redirect to={SIGN_IN}></Redirect>;
    }
  }

  redirect = () => {
    this.props.history.push(CONTENT_SETTING);
  };

  render() {
    const { userPaymentProfile } = this.props;
    if (userPaymentProfile.user_role !== undefined) {
      if (
        userPaymentProfile.user_role.role === "ADMIN" ||
        userPaymentProfile.user_role.role === "SuperAdmin"
      ) {
        return (
          <div className="col-3 left-content">
            <div className="nav-logo">
              <div>
                <a href="index.html">
                  <img
                    className="main-logo float-left"
                    alt="main logo"
                    src={mainLogo}
                  />
                </a>
              </div>
              <div className="nav-title small-font font-weight-bold">
                JEEL ADMIN PANEL
              </div>
            </div>
            <div className="main-nav">
              <ul className="mt-3 mb-3">
                <li>
                  <NavLink to={HOME} activeClassName="active">
                    {" "}
                    <FontAwesomeIcon icon="info-circle" />
                    <span> Dashboard </span>
                  </NavLink>
                </li>
                <li>
                  <NavLink to={USER_MANAGEMENT} activeClassName="active">
                    {" "}
                    <FontAwesomeIcon icon="info-circle" />
                    <span> User Management</span>
                  </NavLink>
                </li>

                <li>
                  <NavLink className="dropdown-item" to={VENDORS}>

                    {" "}
                    <FontAwesomeIcon icon="info-circle" />
                    <span> Partners Management</span>
                  </NavLink>
                </li>

                <li>
                  <NavLink to={ALL_SERIES} activeClassName="active">
                    <FontAwesomeIcon icon="info-circle" />
                    <span> Content Management</span>
                  </NavLink>
                </li>

                <li>
                  <NavLink to={CUSTOMERS} activeClassName="active">
                    {" "}
                    <FontAwesomeIcon icon="info-circle" />
                    <span> Customers </span>
                  </NavLink>
                </li>
                <li>
                  <NavLink to={ALL_BLOGS} activeClassName="active">
                    <FontAwesomeIcon icon="info-circle"></FontAwesomeIcon>
                    <span> Blogs </span>
                  </NavLink>
                </li>
                <li>
                  <NavLink to={ALL_ISSUES} activeClassName="active">
                    <FontAwesomeIcon icon="info-circle" />
                    <span> Complaints and Suggestions </span>
                  </NavLink>
                </li>
                <li>
                  <NavLink to={TERMS_AND_CONDITIONS} activeClassName="active">
                    <FontAwesomeIcon icon="info-circle" />
                    <span>Terms And Conditions</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink to={ALL_FAQ} activeClassName="active">
                    <FontAwesomeIcon icon="info-circle" />
                    <span>FAQ</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink to={GENERAL_SETTING} activeClassName="active">
                    <FontAwesomeIcon icon="info-circle" />
                    <span> General Setting</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink to={REPORT} activeClassName="active">
                    <FontAwesomeIcon icon="info-circle" />{" "}
                    <span> Customer Report</span>
                  </NavLink>
                </li>

                <li>
                  <NavLink to={RECOMMENDATION} activeClassName="active">
                    <FontAwesomeIcon icon="info-circle" />{" "}
                    <span> Recommendation</span>
                  </NavLink>
                </li>

                <li>
                  <NavLink to={PAYMENT_PACKAGES} activeClassName="active">
                    <FontAwesomeIcon icon="info-circle" />{" "}
                    <span> PaymentPackages</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink to={PAYMENT_COUNTRIES} activeClassName="active">
                    <FontAwesomeIcon icon="info-circle" />{" "}
                    <span> Payment Countries</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink to={PROMO_CODES} activeClassName="active">
                    <FontAwesomeIcon icon="info-circle" />{" "}
                    <span> Promo Codes</span>
                  </NavLink>
                </li>

                <li>
                  <NavLink to={IN_APP_NOTIFICATION} activeClassName="active">
                    <FontAwesomeIcon icon="info-circle" />{" "}
                    <span> In App Notification</span>
                  </NavLink>
                </li>

                <li>
                  <NavLink to={SECTIONS} activeClassName="active">
                    <FontAwesomeIcon icon="info-circle" />{" "}
                    <span> Dynamic Sections</span>
                  </NavLink>
                </li>

                <li className="nav-item dropdown contentNav">
                  <NavLink
                    className="nav-link dropdown-toggle"
                    to={CONTENT_SETTING}
                    onClick={this.redirect}
                    id="navbarDropdown"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <FontAwesomeIcon icon="info-circle" />
                    <span> Content Setting</span>
                  </NavLink>
                  <div
                    className="dropdown-menu"
                    aria-labelledby="navbarDropdown"
                  >
                    <NavLink className="dropdown-item" to={CONTENT_VALUES}>
                      Content Value
                    </NavLink>
                  </div>
                </li>
                <li>
                  <NavLink to={ALL_BANNERS} activeClassName="active">
                    <FontAwesomeIcon icon="info-circle" />{" "}
                    <span>Banners</span>
                  </NavLink>
                </li>
                <li>

                  <NavLink to={SETTINGS} activeClassName="active">
                    <FontAwesomeIcon icon="info-circle" />{" "}
                    <span>Settings</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink to={ALL_EDUCATIONAL_VIDEOS} activeClassName="active">
                    <FontAwesomeIcon icon="info-circle" />{" "}
                    <span>Parent Educational Videos</span>
                  </NavLink>
                </li>

              </ul>
              <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br />{" "}
              <br /> <br />
            </div>
          </div>
        );
      } else if (userPaymentProfile.user_role.role === "Analyst") {
        return (
          <div className="col-3 left-content">
            <div className="nav-logo">
              <div>
                <a href="index.html">
                  <img
                    className="main-logo float-left"
                    alt="main logo"
                    src={mainLogo}
                  />
                </a>
              </div>
              <div className="nav-title small-font font-weight-bold">
                JEEL ADMIN PANEL
              </div>
            </div>
            <div className="main-nav">
              <ul className="mt-3 mb-3">
                <li>
                  <NavLink to={HOME} activeClassName="active">
                    {" "}
                    <FontAwesomeIcon icon="info-circle" />
                    <span> Dashboard </span>
                  </NavLink>
                </li>
                <li>
                  <NavLink to={CUSTOMERS} activeClassName="active">
                    {" "}
                    <FontAwesomeIcon icon="info-circle" />
                    <span> Customers </span>
                  </NavLink>
                </li>
                <li>
                  <NavLink to={ALL_ISSUES} activeClassName="active">
                    <FontAwesomeIcon icon="info-circle" />
                    <span> Complaints and Suggestions </span>
                  </NavLink>
                </li>
                <li>
                  <NavLink to={REPORT} activeClassName="active">
                    <FontAwesomeIcon icon="info-circle" />{" "}
                    <span> Customer Report</span>
                  </NavLink>
                </li>
              </ul>
              <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br />{" "}
              <br /> <br />
            </div>
          </div>
        );
      } else if (userPaymentProfile.user_role.role === "ContentPublisher") {
        return (
          <div className="col-3 left-content">
            <div className="nav-logo">
              <div>
                <a href="index.html">
                  <img
                    className="main-logo float-left"
                    alt="main logo"
                    src={mainLogo}
                  />
                </a>
              </div>
              <div className="nav-title small-font font-weight-bold">
                JEEL ADMIN PANEL
              </div>
            </div>
            <div className="main-nav">
              <ul className="mt-3 mb-3">
                <li>
                  <NavLink to={ALL_SERIES} activeClassName="active">
                    <FontAwesomeIcon icon="info-circle" />
                    <span> Content Management</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink to={ALL_BLOGS} activeClassName="active">
                    <FontAwesomeIcon icon="info-circle"></FontAwesomeIcon>
                    <span> Blogs </span>
                  </NavLink>
                </li>
                <li>
                  <NavLink to={TERMS_AND_CONDITIONS} activeClassName="active">
                    <FontAwesomeIcon icon="info-circle" />
                    <span>Terms And Conditions</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink to={ALL_FAQ} activeClassName="active">
                    <FontAwesomeIcon icon="info-circle" />
                    <span>FAQ</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink to={RECOMMENDATION} activeClassName="active">
                    <FontAwesomeIcon icon="info-circle" />{" "}
                    <span> Recommendation</span>
                  </NavLink>
                </li>
                <li className="nav-item dropdown contentNav">
                  <NavLink
                    className="nav-link dropdown-toggle"
                    to={CONTENT_SETTING}
                    onClick={e => this.redirect(e)}
                    id="navbarDropdown"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <FontAwesomeIcon icon="info-circle" />
                    <span> Content Setting</span>
                  </NavLink>
                  <div
                    className="dropdown-menu"
                    aria-labelledby="navbarDropdown"
                  >
                    <NavLink className="dropdown-item" to={CONTENT_VALUES}>
                      Content Value
                    </NavLink>
                  </div>
                </li>
                <li>
                  <NavLink to={ALL_BANNERS} activeClassName="active">
                    <FontAwesomeIcon icon="info-circle" />{" "}
                    <span>Banners</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink to={ALL_EDUCATIONAL_VIDEOS} activeClassName="active">
                    <FontAwesomeIcon icon="info-circle" />{" "}
                    <span>Parent Educational Videos</span>
                  </NavLink>
                </li>
              </ul>
              <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br />{" "}
              <br /> <br />
            </div>
          </div>
        );
      } else if (userPaymentProfile.user_role.role === "AppManagement") {
        return (
          <div className="col-3 left-content">
            <div className="nav-logo">
              <div>
                <a href="index.html">
                  <img
                    className="main-logo float-left"
                    alt="main logo"
                    src={mainLogo}
                  />
                </a>
              </div>
              <div className="nav-title small-font font-weight-bold">
                JEEL ADMIN PANEL
              </div>
            </div>
            <div className="main-nav">
              <ul className="mt-3 mb-3">
                <li>
                  <NavLink to={ALL_SERIES} activeClassName="active">
                    <FontAwesomeIcon icon="info-circle" />
                    <span> Content Management</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink to={CUSTOMERS} activeClassName="active">
                    {" "}
                    <FontAwesomeIcon icon="info-circle" />
                    <span> Customers </span>
                  </NavLink>
                </li>
                <li>
                  <NavLink to={ALL_BLOGS} activeClassName="active">
                    <FontAwesomeIcon icon="info-circle"></FontAwesomeIcon>
                    <span> Blogs </span>
                  </NavLink>
                </li>
                <li>
                  <NavLink to={GENERAL_SETTING} activeClassName="active">
                    <FontAwesomeIcon icon="info-circle" />
                    <span> General Setting</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink to={ALL_ISSUES} activeClassName="active">
                    <FontAwesomeIcon icon="info-circle" />
                    <span> Complaints and Suggestions </span>
                  </NavLink>
                </li>
                <li>
                  <NavLink to={TERMS_AND_CONDITIONS} activeClassName="active">
                    <FontAwesomeIcon icon="info-circle" />
                    <span>Terms And Conditions</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink to={ALL_FAQ} activeClassName="active">
                    <FontAwesomeIcon icon="info-circle" />
                    <span>FAQ</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink to={RECOMMENDATION} activeClassName="active">
                    <FontAwesomeIcon icon="info-circle" />{" "}
                    <span> Recommendation</span>
                  </NavLink>
                </li>

                <li>
                  <NavLink to={PROMO_CODES} activeClassName="active">
                    <FontAwesomeIcon icon="info-circle" />{" "}
                    <span> Promo Codes </span>
                  </NavLink>
                </li>

                <li className="nav-item dropdown contentNav">
                  <NavLink
                    className="nav-link dropdown-toggle"
                    to={CONTENT_SETTING}
                    onClick={this.redirect}
                    id="navbarDropdown"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <FontAwesomeIcon icon="info-circle" />
                    <span> Content Setting</span>
                  </NavLink>
                  <div
                    className="dropdown-menu"
                    aria-labelledby="navbarDropdown"
                  >
                    <NavLink className="dropdown-item" to={CONTENT_VALUES}>
                      Content Value
                    </NavLink>
                  </div>
                </li>
                <li>
                  <NavLink to={ALL_BANNERS} activeClassName="active">
                    <FontAwesomeIcon icon="info-circle" />{" "}
                    <span>Banners</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink to={ALL_EDUCATIONAL_VIDEOS} activeClassName="active">
                    <FontAwesomeIcon icon="info-circle" />{" "}
                    <span>Parent Educational Videos</span>
                  </NavLink>
                </li>
              </ul>
              <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br />{" "}
              <br /> <br />
            </div>
          </div>
        );
      } else {
        return <div></div>;
      }
    } else {
      return <div></div>;
    }
  }
}
const mapStateToProps = state => ({
  auth: state.firebase.auth,
  userPaymentProfile: state.userPaymentProfile.item
});

export default withRouter(connect(mapStateToProps, { getUserData })(Dashboard));
