import React, { useEffect, useRef } from "react";
import Subscribe from "../subscribe/Subscribe";

const SubscribeSection = () => {
  const sectionRef = useRef(null);
  useEffect(() => {
    if (
      window.location.hash &&
      window.location.hash.includes("#subscribe-now")
    ) {
      window.scrollTo({ top: sectionRef.current.offsetTop });
    }
  }, [sectionRef]);

  return (
    <section ref={sectionRef} id="subscribe-now" className="portal-section">
      <div className="container">
        <div className="portal-section-title text-center">
          أسعار اشتراك جيل اب
          <div className="portal-section-desc">
            اختر باقة الاشتراك المناسبة لك
          </div>
        </div>
        <Subscribe showRenewal={false} />
      </div>
    </section>
  );
};
export default SubscribeSection;
