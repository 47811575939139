import React, { Component } from 'react'
import { Button, Icon } from 'semantic-ui-react';
import { Redirect, Link } from 'react-router-dom';
/*Headers */
import OuterHeader from '../Layout/OuterHeader';
import Dashboard from '../Dashboard';
import InnerHeader from '../Layout/InnerHeader';
import NavBar from '../Layout/NavBar';
import Footer from '../Layout/Footer';
/*Redux */
import { connect } from 'react-redux';
import propTypes from 'prop-types';
/*Actions */
import { emptyEvaluationAll } from '../../Actions/EvaluationActions'
import { clearQuestions } from '../../Actions/QuestionActions'
import { publishChannel, emptyChannel } from '../../Actions/RadioChannelAction';
import { publishQuestion, assignCorrrectAnswerToQuestion } from '../../Actions/QuestionActions';
import { addSeriesIDtoRedirectionPage } from '../../Actions/RedirectionPagesActions.js'

/*css*/
import '../../css/AppCss/Episode/PublishEpisode.css';
import "bootstrap/dist/css/bootstrap.min.css";
import '../../../node_modules/video-react/dist/video-react.css';
/*Other components */
import QuestionPreview from '../Layout/QuestionPreview';
import AudioPlayer from "react-h5-audio-player";
import { GET_RADIO_CHAN_EVAL } from '../../routes';

class PublishRadioChannel extends Component {
    constructor(props) {
        super(props);
    }

    state = {
        sucess: ''
    }

    publishChannel = (e) => {
        const { radioChannel } = this.props;
        const { redirectionPage } = this.props;
        console.log(redirectionPage.pageName)
        this.props.addSeriesIDtoRedirectionPage(redirectionPage.pageName, radioChannel.selected_radio_id);


        const { activity } = this.props;
        this.props.publishChannel(activity.name);
    }

    render() {
        const { radioChannel } = this.props;
        const { questions } = this.props;

        const { channelrror, channelsucess, loading } = this.props;
        const { activityerror } = this.props
        const { activitysucess } = this.props

        let buttonName = radioChannel.radiobutton;
        console.log(buttonName)
        if (radioChannel.radiobutton === "now") {
            buttonName = "publish"
        }
        else if (radioChannel.radiobutton === "schedule") {
            buttonName = "save"
        }

        if (activitysucess === false) {
            alert(activityerror)
            this.props.emptyChannel();
            this.props.clearQuestions();
            this.props.emptyEvaluationAll();
        }
        else if (activitysucess === true) {
            if (channelsucess === false) {
                alert("error missing data or network " + channelrror)
                this.props.emptyChannel();
                this.props.clearQuestions();
                this.props.emptyEvaluationAll();

            }

            else if (channelsucess === true) {
                alert("Channel added Successfully")
                this.props.emptyChannel();
                this.props.clearQuestions();
                this.props.emptyEvaluationAll();

                const { redirectionPage } = this.props;
                var pageName = redirectionPage.pageName
                return (<Redirect to={pageName} ></Redirect>)
            }
        }

        let questionsList = null;
        if (questions !== undefined) {
            questionsList = (
                <QuestionPreview state='publish'
                    questions={questions}
                />)
        }

        // loading button
        let $buttonSubmitLoader = (
            <Button id="episode_publish"
                onClick={this.publishChannel}>
                {buttonName}
            </Button>);

        if (loading === true) {
            $buttonSubmitLoader = (
                <button id="episode_publish"
                    class="ui loading button">Loading</button>);
            console.log("loading");
        }


        console.log("radioChannel.voice")
        console.log(radioChannel.voice)
        let audio = ''
        if (radioChannel.voice ) {
            audio = (
                <AudioPlayer
                    src={radioChannel.voice}
                    onPlay={e => console.log("onPlay")}
                />)
        }
        else {
            audio = (
                <td fluid className="episode_label"> No Audio</td>
            )
        }


        let trackWithMusic = ''
        if ((radioChannel.trackWithMusic )
        ) {
            trackWithMusic = (
                <AudioPlayer
                    src={radioChannel.trackWithMusic}
                    onPlay={e => console.log("onPlay")}
                />)
        }
        else {
            trackWithMusic = (
                <td fluid className="episode_label"> No Track With Music</td>
            )
        }



        let trackWithoutMusic = ''
        if ((radioChannel.trackWithoutMusic )
        ) {
            trackWithoutMusic = (
                <AudioPlayer
                    src={radioChannel.trackWithoutMusic}
                    onPlay={e => console.log("onPlay")}
                />)
        }
        else {
            trackWithoutMusic = (
                <td fluid className="episode_label"> No Track Without Music</td>
            )
        }

        return (
            <div className="container">
                <OuterHeader />
                <div className="container">
                    <div className="container-body">
                        <div className="row">

                            <Dashboard />
                            <div className="col">
                                <InnerHeader />
                                <div className="main-content">
                                    <div className="row">
                                        <div className="col-12 left-main-body" id="left-main-body">
                                            <NavBar />
                                            <br />
                                            <div className="main-progress-bar">
                                                <ol className="progress-tracker">
                                                    <li className="step active s2-active">
                                                        <span className="step-name small-font">ِ
                                                            Add Channel</span>
                                                    </li>
                                                    <li className="step active s2-active">
                                                        <span className="step-name small-font">
                                                            Add activity</span>
                                                    </li>
                                                    <li className="step active s2-active">
                                                        <span className="step-name small-font">
                                                            Add Evaluation</span>
                                                    </li>
                                                    <li className="step active">
                                                        <span className="step-name small-font">
                                                            Puplish Channel</span>
                                                    </li>
                                                </ol>
                                            </div>
                                            <br />
                                            <div className="container">
                                                <label htmlFor="" >{radioChannel.name}</label>
                                                
                                                <hr />
                                                <div className="row">
                                                    <div className="col-4">
                                                        <img className="rounded"
                                                         src={radioChannel.image}
                                                          alt="" 
                                                          className="image_Preview" />
                                                    </div>

                                                    <div className="col">
                                                        <table >
                                                            <tr>
                                                                <th className="episode_label"> Channel Name </th>
                                                                <td fluid className="episode_label"> {radioChannel.name} </td>
                                                            </tr>

                                                            <tr>
                                                                <th className="episode_label"> Channel Name In english </th>
                                                                <td fluid className="episode_label"> {radioChannel.name_in_english} </td>
                                                            </tr>

                                                            <tr>
                                                                <th className="episode_label"> Selected Radio  </th>
                                                                <td fluid className="episode_label"> {radioChannel.selected_radio_name} </td>
                                                            </tr>

                                                            <tr>
                                                                <th className="episode_label"> Number Of Questions </th>
                                                                <td fluid className="episode_label">{questions.length} </td>
                                                            </tr>

                                                            <tr>
                                                                <th className="episode_label"> Schedule </th>
                                                                <td fluid className="episode_label">11/2/2019, 02:00 am </td>
                                                            </tr>

                                                            <tr>
                                                                <th className="episode_label"> payment availability </th>
                                                                <td fluid className="episode_label"> {radioChannel.isFree} </td>
                                                            </tr>

                                                            <tr>
                                                                <th className="episode_label"> Track With Music </th>
                                                                {trackWithMusic}
                                                            </tr>
                                                            
                                                            <tr>
                                                                <th className="episode_label"> Track Without Music </th>
                                                                {trackWithoutMusic}
                                                            </tr>

                                                            <tr>
                                                                <th className="episode_label"> Audio </th>
                                                                {audio}
                                                            </tr>

                                                        </table>
                                                    </div>


                                                </div>
                                                <br />
                                                <div className="row" id="episode_Question_Row">
                                                    <label className="question_label">Questions</label>
                                                    <hr id="questionHR" />
                                                    <br />
                                                    {questionsList}




                                                </div>
                                                {$buttonSubmitLoader}
                                                <Link to={GET_RADIO_CHAN_EVAL}>
                                                    <Button icon labelPosition='left' id="episode_backButton" >
                                                        back
                                                            <Icon name='left arrow' />
                                                    </Button>
                                                </Link>
                                                <br /> <br /> <br />

                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <Footer />
            </div>

        )
    }
}

PublishRadioChannel.propTypes = {
    publishChannel: propTypes.func.isRequired,
    publishActivity: propTypes.func.isRequired,
    publishQuestion: propTypes.func.isRequired,
    assignCorrrectAnswerToQuestion: propTypes.func.isRequired
}
const mapStateToProps = state => ({
    loading: state.radioChannel.loading,
    radioChannel: state.radioChannel.item,
    channelrror: state.radioChannel.error_adding_channel,
    channelsucess: state.radioChannel.channel_added_successfully,

    activity: state.activities.item,
    questions: state.questions.items,
    question: state.questions.item,

    activityerror: state.activities.error_adding_activity,
    activitysucess: state.activities.activity_added_successfully,
    redirectionPage: state.redirectionPage.item

});


export default
    connect(
        mapStateToProps,
        {
            publishChannel,
            publishQuestion,
            assignCorrrectAnswerToQuestion,
            emptyChannel,
            clearQuestions,
            emptyEvaluationAll,
            addSeriesIDtoRedirectionPage
        }
    )
        (PublishRadioChannel)
