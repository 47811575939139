import {
    FETCH_ALL_CHANNELS,
    ADD_CHANNEL, PUBLISH_CHANNEL,
    GET_CHANNEL_DATA, LOAD_ALL_CHANNELS,
    ERROR_LOADING_CHANNEL,
    ERROR_LOADING_ALL_CHANNELS,
    ERROR_ADDING_ACTIVITY,
    ERROR_ADDING_CHANNEL,
    PUBLISH_ACTIVITY,
    LOAD_CHANNEL, EDIT_CHANNEL_DATA,
    ERROR_EDITING_CHANNEL, EMPTY_CHANNEL,
    ERROR_DELETING_CHANNEL, DELETE_CHANNEL,
    SETTING_CHANNEL_STATUS, ERROR_SETTING_CHANNEL_STATUS,
    EMPTY_ALL_CHANNELS
} from './Types'

import axios, { post, put } from 'axios'
import { saveActivity } from '../Promises/Activity.js';
import { saveChannel } from '../Promises/radioChannel.js'
import firebase from 'firebase/app'

export const fetchChannels = () => dispatch => {

    dispatch({
        type: LOAD_ALL_CHANNELS,
        payload: 'Loading'
    })

    axios.get('/Channel/getAllchannelsList')
        .then((res) => {
            dispatch({
                type: FETCH_ALL_CHANNELS,
                payload: res.data
            })
        }
        ).catch((Error) => {
            dispatch({
                type: ERROR_LOADING_ALL_CHANNELS,
                payload: Error.message
            })
        })
}

export const publishChannel = (name) => dispatch => {
    dispatch({
        type: LOAD_CHANNEL,
        payload: 'Loading'
    })

    saveActivity(name).then((res) => {
        dispatch({
            type: PUBLISH_ACTIVITY,
            payload: res
        })
        saveChannel(res.id).then((res) => {
            dispatch(
                {
                    type: PUBLISH_CHANNEL,
                    payload: res.data
                }
            )
        }).catch((Error) => {
            dispatch(
                {
                    type: ERROR_ADDING_CHANNEL,
                    payload: Error.message
                })
        })



    }).catch((Error) => {
        dispatch({
            type: ERROR_ADDING_ACTIVITY,
            payload: Error.message
        })
    });
}

export const addChannel = (name, description, image, PublishDate,
    trackWithMusic, trackWithoutMusic,
    radiobutton, isFree, voice,
    subValueId,
    selected_radio_id,
    selected_radio_name,
    name_in_english,
    description_in_english,
    featured) => dispatch => {

        if (description === null &&
            description === undefined) {
            description = '';
        }

        if (description_in_english === null ||
            description_in_english === undefined) {
            description_in_english = '';
        }

        if (name_in_english === null ||
            name_in_english === undefined) {
                name_in_english = '';
        }

        const radioChannel = {
            name: name,
            description: description,
            image: image,
            PublishDate: PublishDate,
            trackWithMusic: trackWithMusic,
            trackWithoutMusic: trackWithoutMusic,
            radiobutton: radiobutton,
            isFree: isFree,
            voice: voice,
            subValueId: subValueId,
            name_in_english: name_in_english,
            description_in_english: description_in_english,
            selected_radio_id: selected_radio_id,
            selected_radio_name: selected_radio_name,
            featured: featured
        }

        dispatch({
            type: ADD_CHANNEL,
            payload: radioChannel
        })
    }

export const getChannelData = (id) => {


    return (dispatch, getState, { getFirebase }) => {
        const firebase = getFirebase();
        firebase.auth().currentUser.getIdToken().then((token) => {
            dispatch({
                type: LOAD_CHANNEL,
                payload: 'Loading'
            })

            const url = "/Channel/getChannelDetails"
            const formData = new FormData();
            formData.append('ID', id);
            const config = {
                headers: {
                    'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
                    'X-Authorization-Firebase': token
                }
            }

            post(url, formData, config).then((res) => {
                dispatch(
                    {
                        type: GET_CHANNEL_DATA,
                        payload: res.data
                    }
                )
            }).catch((Error) => {
                dispatch({
                    type: ERROR_LOADING_CHANNEL,
                    payload: Error.message
                })
            })
        })



    }

}

export const updateChannel = (id, name, description, Image,
    PublishDate, trackWithMusic,
    trackWithoutMusic, activityId,
    paymentAva, voice,
    subValueId, selected_radio_id, name_in_english, description_in_english, featured) => {


    return (dispatch, getState, { getFirebase }) => {
        const firebase = getFirebase();
        firebase.auth().currentUser.getIdToken().then((token) => {
            dispatch({
                type: LOAD_CHANNEL,
                payload: 'Loading'
            })


            if (description === null &&
                description === undefined) {
                description = '';
            }

            if (description_in_english === null ||
                description_in_english === undefined) {
                description_in_english = '';
            }

            if (name_in_english === null ||
                name_in_english === undefined) {
                    name_in_english = '';
            }

            let isFree = ''
            if (paymentAva === 'paid') {
                isFree = 0
            }
            else if (paymentAva === 'free') {
                isFree = 1
            }

            let updatedSubValueId
            if (subValueId === '' ||
                subValueId === undefined ||
                subValueId === null) {
                updatedSubValueId = 0
            } else {
                updatedSubValueId = subValueId
            }


            let tempTrackWithMusic = trackWithMusic;
            if (trackWithMusic == null ||
                trackWithMusic == undefined ||
                trackWithMusic == '') {

                tempTrackWithMusic = ''
            }

            let tempTrackWithoutMusic = trackWithoutMusic;
            if (trackWithoutMusic == null ||
                trackWithoutMusic == undefined ||
                trackWithoutMusic == '') {

                tempTrackWithoutMusic = ''
            }

            let status = '';
            if (name === '' || name === null || name === undefined
                // || name_in_english === '' || name_in_english === null || name_in_english === undefined
                || Image === '' || Image === null || Image === undefined
                || tempTrackWithMusic === '' || tempTrackWithMusic === null
                || tempTrackWithMusic === undefined
                || tempTrackWithoutMusic === '' || tempTrackWithoutMusic === null
                || tempTrackWithoutMusic === undefined
                || PublishDate === '' || PublishDate === null) {
                status = 'SUSPENDED'
            }
            else {
                status = ''
            }

            const url = "/Channel/updateRadioChannel";
            const formData = new FormData();
            formData.append('ID', id);
            formData.append('Name', name);
            formData.append('Description', description);
            formData.append('Image', Image);
            formData.append('Status', status);
            formData.append('Publish_Date', PublishDate);

            formData.append('Track_With_Music', tempTrackWithMusic);
            formData.append('Track_Without_Music', tempTrackWithoutMusic);

            formData.append('Activity_ID', activityId);
            formData.append('Is_free', isFree);
            formData.append('Voice', voice);
            formData.append('Content_Sub_Value_Id', updatedSubValueId);
            formData.append('Radio_Id', selected_radio_id);
            formData.append('Name_In_English',name_in_english);
            formData.append('Description_In_English',description_in_english);
            formData.append('featured', featured);
            const config = {
                headers: {
                    'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
                    'X-Authorization-Firebase': token
                }
            }
            post(url, formData, config).then((res) => {
                dispatch({
                    type: EDIT_CHANNEL_DATA,
                    payload: res.data
                })
            }).catch((Error) => {
                dispatch({
                    type: ERROR_EDITING_CHANNEL,
                    payload: Error.message
                })
            })
        })
    }
}

export const emptyChannel = () => dispatch => {
    dispatch({
        type: EMPTY_CHANNEL,
        payload: ""
    })
}

export const emptyAllChannels = () => dispatch => {
    dispatch({
        type: EMPTY_ALL_CHANNELS,
        payload: ""
    })
}

export const deleteChannel = (id) => {
    return (dispatch, getState, { getFirebase }) => {
        const firebase = getFirebase();
        firebase.auth().currentUser.getIdToken().then((token) => {
            const url = "/Channel/deleteRadioChannel"
            const formData = new FormData();
            formData.append("ID", id);

            const config = {
                headers: {
                    'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
                    'X-Authorization-Firebase': token
                }

            }
            post(url, formData, config).then((res) => {
                dispatch({
                    type: DELETE_CHANNEL,
                    payload: res.data
                })
            }).catch((Error) => {
                dispatch({
                    type: ERROR_DELETING_CHANNEL,
                    payload: Error.response.data.message
                })
            })

        })
    }
}

export const setChannelStatus = (id, status) => {
    return (dispatch, getState, { getFirebase }) => {
        const firebase = getFirebase();
        firebase.auth().currentUser.getIdToken().then((token) => {
            dispatch({
                type: LOAD_CHANNEL,
            })
            const url = "/Channel/setRadioChannelStatus"
            const formData = new FormData();
            formData.append("ID", id)
            formData.append("Status", status)

            const config = {
                headers: {
                    'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
                    'X-Authorization-Firebase': token
                }
            }
            post(url, formData, config).then((res) => {
                dispatch({
                    type: SETTING_CHANNEL_STATUS,
                    payload: res.data
                })
            }).catch((Error) => {
                dispatch({
                    type: ERROR_SETTING_CHANNEL_STATUS,
                    payload: Error.response.data.message// Error.message
                })
            })
        })

    }
}




export const deleteChannelPuzzle = (id) => {

    const url = "/ChannelPuzzle/deleteRadioChannelPuzzle"
    let token = firebase.auth().currentUser.h.b;
    const formData = new FormData();
    formData.append("Channel_Puzzle_ID", id);
    return axios({

        method: "post",
        headers: {
            'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
            'X-Authorization-Firebase': token
        },
        data: formData,
        url: url
    });
}


export const saveChannelPuzzle = (channel_ID, Image_URL) => {

    const url = "/ChannelPuzzle/saveRadioChannelPuzzle"
    let token = firebase.auth().currentUser.h.b;
    const formData = new FormData();
    formData.append("Channel_ID", channel_ID);
    formData.append("Image_URL", Image_URL);
    return axios({

        method: "post",
        headers: {
            'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
            'X-Authorization-Firebase': token
        },
        data: formData,
        url: url
    });
}





export const deleteChannelPaintingGame = (id) => {

    const url = "/ChannelPainting/deleteRadioChannelPainting"
    let token = firebase.auth().currentUser.h.b;
    const formData = new FormData();
    formData.append("Channel_Painting_ID", id);
    return axios({

        method: "post",
        headers: {
            'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
            'X-Authorization-Firebase': token
        },
        data: formData,
        url: url
    });
}


export const saveChannelPaintingGame = (Channel_ID, Image_URL) => {

    const url = "/ChannelPainting/saveRadioChannelPainting"
    let token = firebase.auth().currentUser.h.b;
    const formData = new FormData();
    formData.append("Channel_ID", Channel_ID);
    formData.append("Image_URL", Image_URL);
    return axios({

        method: "post",
        headers: {
            'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
            'X-Authorization-Firebase': token
        },
        data: formData,
        url: url
    });
}


/*Start of Drag Drop*/ 

export const getDragAndDropChannel = (DDID) => {
    const url = "/ChannelDragDrop/getChannelDragAndDropData"
    let token = firebase.auth().currentUser.h.b;
    const formData = new FormData();
    formData.append("ChannelDragDrop_ID", DDID);
    return axios({
        method: "post",
        headers: {
            'X-Authorization-Firebase': token
        },
        data: formData,
        url: url
    });
}

export const saveChannelDragAndDrop = (attributes) => {
    const url = "/ChannelDragDrop/saveChannelDragDrop"
    let token = firebase.auth().currentUser.h.b;
    return axios({
        method: "post",
        headers: {
            'content-type': 'application/json',
            'X-Authorization-Firebase': token
        },
        data: {
            channelId: attributes.parentId,
            imageUrl: attributes.game_Image,
            cropedImageUrl: attributes.cropedImageUrl,
            dragDropWidth: attributes.dragDropWidth,
            dragDropHeight: attributes.dragDropHeight,
            draggableElements: attributes.listElements
        },
        url: url
    });
}

export const deleteChannelDragAndDrop = (id) => {
    const url = "/ChannelDragDrop/deleteDragAndDrop"
    let token = firebase.auth().currentUser.h.b;
    const formData = new FormData();
    formData.append("ChannelDragDrop_ID", id);
    return axios({
        method: "post",
        headers: {
            'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
            'X-Authorization-Firebase': token
        },
        data: formData,
        url: url
    });
}

export const addDraggableElementChannel = (DDID, draggableWidth, draggableHeight, xCoordinate, yCoordinate, draggableImage) => {
    const url = "/ChannelDragDrop/addDraggableElement"
    let token = firebase.auth().currentUser.h.b;
    return axios({
        method: "post",
        headers: {
            'X-Authorization-Firebase': token
        },
        data: {
            dragDropId: DDID,
            draggableWidth: draggableWidth,
            draggableHeight: draggableHeight,
            xCoordinate: xCoordinate,
            yCoordinate: yCoordinate,
            draggableImage: draggableImage,
        },
        url: url
    });
}

export const deleteDraggableElementChannel = (id) => {
    const url = "/ChannelDragDrop/deleteDraggableElement"
    let token = firebase.auth().currentUser.h.b;
    const formData = new FormData();
    formData.append("DraggableElement_ID", id);
    return axios({
        method: "post",
        headers: {
            'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
            'X-Authorization-Firebase': token
        },
        data: formData,
        url: url
    });
}

export const updateOneDraggableElementChannel = (id, width, height, XCoordinate, YCoordinate, imageURL) => {
    const url = "/ChannelDragDrop/updateOneDraggableElement";
    let token = firebase.auth().currentUser.h.b;
    const formData = new FormData();
    formData.append("DraggableId", id);
    formData.append("DraggbleWidth", width);
    formData.append("DraggbleHeight", height);
    formData.append("XCoordinate", XCoordinate);
    formData.append("YCoordinate", YCoordinate);
    formData.append("url", imageURL);

    return axios({
        method: "post",
        headers: {
            'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
            'X-Authorization-Firebase': token
        },
        data: formData,
        url: url
    });
}

export const updateDragAndDropChannel = (id, width, height, imageURL, croppedImageURL) => {
    const url = "/ChannelDragDrop/updateChannelDragAndDrop";
    let token = firebase.auth().currentUser.h.b;
    const formData = new FormData();
    formData.append("Id", id);
    formData.append("DragDropWidth", width);
    formData.append("DragDropHeight", height);
    formData.append("url", imageURL);
    formData.append("Cropped_Image_Url", croppedImageURL);

    return axios({
        method: "post",
        headers: {
            'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
            'X-Authorization-Firebase': token
        },
        data: formData,
        url: url
    });
}

/*End of Drag Drop*/ 
