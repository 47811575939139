import {
    ERROR_ADDING_ACTIVITY,
    PUBLISH_ACTIVITY,
    LOAD_ALL_BOOKS,
    FETCH_ALL_BOOKS,
    ERROR_LOADING_ALL_BOOKS,
    LOAD_BOOK,
    PUBLISH_BOOK,
    ERROR_ADDING_BOOK,
    ADD_BOOK,
    GET_BOOK_DATA,
    ERROR_LOADING_BOOK,
    EDIT_BOOK_DATA,
    ERROR_EDITING_BOOK,
    EMPTY_BOOK,
    EMPTY_ALL_BOOKS,
    DELETE_BOOK,
    ERROR_DELETING_BOOK,
    SETTING_BOOK_STATUS,
    ERROR_SETTING_BOOK_STATUS,
    ADD_BOOK_WITH_SENTFROM,
    DELETE_BOOK_PAGE,
    ERROR_DELETING_BOOK_PAGE,
    ADD_BOOK_PAGE,
    ERROR_ADDING_PAGE,
    UPDATE_BOOK_PAGE,
    ERROR_UPDATE_BOOK_PAGE,
    ERROR_ADDING_BOOK_PAGE,
    LOAD_BOOK_PAGE
} from './Types'

import axios, { post, put } from 'axios'
import { saveActivity } from '../Promises/Activity.js';
// import { saveChannel } from '../Promises/radioChannel.js'
import firebase from 'firebase/app'
import { saveBook } from '../Promises/readWithJeel';
export const fetchBooks = () => dispatch => {
    dispatch({
        type: LOAD_ALL_BOOKS,
        payload: 'Loading'
    })
    firebase.auth().onAuthStateChanged(function (user) {
        if (user) {
            user.getIdToken().then((idToken) => {
                const config = {
                    headers: {
                        'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
                        'X-Authorization-Firebase': idToken
                    }
                }

                axios.get('/Book/getAllBookList', config)
                    .then((res) => {
                        dispatch({
                            type: FETCH_ALL_BOOKS,
                            payload: JSON.parse(res.data)
                        })
                    }
                    ).catch((Error) => {
                        dispatch({
                            type: ERROR_LOADING_ALL_BOOKS,
                            payload: Error.message
                        })
                    })
            });
        }
    });
}

export const publishBook = (name) => dispatch => {
    dispatch({
        type: LOAD_BOOK,
        payload: 'Loading'
    })

    saveActivity(name).then((res) => {
        dispatch({
            type: PUBLISH_ACTIVITY,
            payload: res
        })
        saveBook(res.id).then((res) => {
            dispatch(
                {
                    type: PUBLISH_BOOK,
                    payload: res.data
                }
            )
        }).catch((Error) => {
            dispatch(
                {
                    type: ERROR_ADDING_BOOK,
                    payload: Error.message
                })
        })



    }).catch((Error) => {
        dispatch({
            type: ERROR_ADDING_ACTIVITY,
            payload: Error.message
        })
    });
}


// (name, description, image, PublishDate,
// titleAudio, bookAudio,
// bookbutton, isFree, voice,
// subValueId,
// selected_radio_id,
// selected_radio_name, pages)
export const addBook = (name, description, image, PublishDate,
    titleAudio, bookAudio, audioMarker,
    bookbutton, pages, isFree, subValueId, selected_bookParent_id, selected_bookParent_name, name_in_english, description_in_english, featured) => dispatch => {

        if (description === null &&
            description === undefined) {
            description = '';
        }

        if (description_in_english === null ||
            description_in_english === undefined) {
                description_in_english = '';
        }

        if (name_in_english === null ||
            name_in_english === undefined) {
                name_in_english = '';
        }

        if (pages === null &&
            pages === undefined) {
            pages = [];
        }

        const readWithJeel = {
            name: name,
            description: description,
            image: image,
            PublishDate: PublishDate,
            titleAudio: titleAudio,
            bookAudio: bookAudio,
            audioMarker: audioMarker,
            // radiobutton: radiobutton,
            bookbutton: bookbutton,
            isFree: isFree,
            // voice: voice,
            subValueId: subValueId,

            // selected_radio_id: selected_radio_id,
            // selected_radio_name: selected_radio_name,
            // Pages
            pages: pages,
            selected_bookParent_id: selected_bookParent_id,
            selected_bookParent_name: selected_bookParent_name,
            nameInEnglish: name_in_english,
            descriptionInEnglish: description_in_english,
            featured: featured
        }

        dispatch({
            type: ADD_BOOK,
            payload: readWithJeel
        })
    }

export const addBookItemWithSentFrom = (item, sentFrom) => dispatch => {
    const readWithJeel = {
        item: item,
        sentFrom: sentFrom
    }
    dispatch({
        type: ADD_BOOK_WITH_SENTFROM,
        payload: readWithJeel
    })
}

export const getBookData = (id) => (dispatch) => {
    dispatch({
        type: LOAD_BOOK,
        payload: 'Loading'
    })
    firebase.auth().onAuthStateChanged(function (user) {
        if (user) {
            user.getIdToken().then((idToken) => {
                const formData = new FormData();
                formData.append('ID', id);
                const config = {
                    headers: {
                        'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
                        'X-Authorization-Firebase': idToken
                    },
                    params: {
                        'ID': id
                    }
                }

                axios.get('/Book/getBookDetails', config)
                    .then((res) => {
                        dispatch(
                            {
                                type: GET_BOOK_DATA,
                                payload: res.data
                            }
                        )
                    }
                    ).catch((Error) => {
                        dispatch({
                            type: ERROR_LOADING_BOOK,
                            payload: Error.message
                        })
                    })
            });
        }
    });
}


// (id, name, description, Image,
// PublishDate, titleAudio,
// bookAudio, activityId,
// paymentAva, voice,
// subValueId, selected_radio_id)

export const updateBook = (id, name, description, Image,
    PublishDate, titleAudio,
    bookAudio, audioMarker, activityId,
    pages, paymentAva, subValueId, bookParent_id, name_in_english, description_in_english, featured) => {

    return (dispatch, getState, { getFirebase }) => {
        const firebase = getFirebase();
        firebase.auth().currentUser.getIdToken().then((token) => {
            dispatch({
                type: LOAD_BOOK,
                payload: 'Loading'
            })


            if (description === null &&
                description === undefined) {
                description = '';
            }

            if (description_in_english === null ||
                description_in_english === undefined) {
                    description_in_english = '';
            }
    
            if (name_in_english === null ||
                name_in_english === undefined) {
                    name_in_english = '';
            }

            if (pages === null &&
                pages === undefined) {
                pages = [];
            }

            let isFree = ''
            if (paymentAva === 'paid') {
                isFree = 0
            }
            else if (paymentAva === 'free') {
                isFree = 1
            }

            let updatedSubValueId
            if (subValueId === '' ||
                subValueId === undefined ||
                subValueId === null) {
                updatedSubValueId = 0
            } else {
                updatedSubValueId = subValueId
            }


            let tempTitleAudio = titleAudio;
            if (titleAudio == null ||
                titleAudio == undefined ||
                titleAudio == '') {

                tempTitleAudio = ''
            }

            let tempBookAudio = bookAudio;
            if (bookAudio == null ||
                bookAudio == undefined ||
                bookAudio == '') {

                tempBookAudio = ''
            }

            let tempAudioMarker = audioMarker;
            if (audioMarker == null ||
                audioMarker == undefined ||
                audioMarker == '') {

                tempAudioMarker = ''
            }

            let status = '';
            if (name === '' || name === null || name === undefined
                || Image === '' || Image === null || Image === undefined
                || tempTitleAudio === '' || tempTitleAudio === null
                || tempTitleAudio === undefined
                || tempBookAudio === '' || tempBookAudio === null
                || tempBookAudio === undefined
                || PublishDate === '' || PublishDate === null
                || tempAudioMarker === '' || tempAudioMarker === null
                || tempAudioMarker === undefined) {
                status = 'SUSPENDED'
            }
            else {
                status = 'DEFAULT'
            }

            const url = "/Book/updateBook";
            const formData = new FormData();
            formData.append('ID', id);
            formData.append('Name', name);
            formData.append('Description', description);
            formData.append('Image', Image);
            formData.append('Status', status);
            formData.append('Publish_Date', PublishDate);

            formData.append('Voice', tempTitleAudio);
            formData.append('book_voice', tempBookAudio);
            formData.append('book_marker', tempAudioMarker);

            formData.append('Activity_ID', activityId.id);
            // formData.append('bookPages', pages);
            formData.append('Is_free', isFree);
            // formData.append('Voice', voice);
            formData.append('Content_Sub_Value_Id', updatedSubValueId);
            // formData.append('Radio_Id', selected_radio_id);
            formData.append('Book_Parent_Id', bookParent_id);
            formData.append('Name_In_English', name_in_english);
            formData.append('Description_In_English', description_in_english);
            formData.append('featured', featured);

            const config = {
                headers: {
                    'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
                    'X-Authorization-Firebase': token
                }
            }
            post(url, formData, config).then((res) => {
                dispatch({
                    type: EDIT_BOOK_DATA,
                    payload: res.data
                })
            }).catch((Error) => {
                dispatch({
                    type: ERROR_EDITING_BOOK,
                    payload: Error.message
                })
            })
        })
    }
}

export const emptyBook = () => dispatch => {
    dispatch({
        type: EMPTY_BOOK,
        payload: ""
    })
}

export const emptyAllBooks = () => dispatch => {
    dispatch({
        type: EMPTY_ALL_BOOKS,
        payload: ""
    })
}

export const deleteBook = (id) => {
    return (dispatch, getState, { getFirebase }) => {
        const firebase = getFirebase();
        firebase.auth().currentUser.getIdToken().then((token) => {
            const url = "/Book/deleteBook"
            const formData = new FormData();
            formData.append("ID", id);

            const config = {
                headers: {
                    'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
                    'X-Authorization-Firebase': token
                }

            }
            post(url, formData, config).then((res) => {
                dispatch({
                    type: DELETE_BOOK,
                    payload: res.data
                })
            }).catch((Error) => {
                dispatch({
                    type: ERROR_DELETING_BOOK,
                    payload: Error.response.data.message
                })
            })

        })
    }
}

export const setBookStatus = (id, status) => {
    return (dispatch, getState, { getFirebase }) => {
        const firebase = getFirebase();
        firebase.auth().currentUser.getIdToken().then((token) => {
            dispatch({
                type: LOAD_BOOK,
            })
            const url = "/Book/setBookStatus"
            const formData = new FormData();
            formData.append("ID", id)
            formData.append("Status", status)

            const config = {
                headers: {
                    'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
                    'X-Authorization-Firebase': token
                }
            }
            post(url, formData, config).then((res) => {
                dispatch({
                    type: SETTING_BOOK_STATUS,
                    payload: res.data
                })
            }).catch((Error) => {
                dispatch({
                    type: ERROR_SETTING_BOOK_STATUS,
                    payload: Error.response.data.message// Error.message
                })
            })
        })

    }
}




export const deleteBookPuzzle = (id) => {

    const url = "/BookPuzzle/deleteBookPuzzle"
    let token = firebase.auth().currentUser.h.b;
    const formData = new FormData();
    formData.append("id", id);
    return axios({

        method: "delete",
        headers: {
            'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
            'X-Authorization-Firebase': token
        },
        data: formData,
        url: url
    });
}


export const saveBookPuzzle = (book_ID, Image_URL) => {

    const url = "/BookPuzzle/addBookPuzzle"
    let token = firebase.auth().currentUser.h.b;
    const formData = new FormData();
    formData.append("Book_ID", book_ID);
    formData.append("Image_Url", Image_URL);
    return axios({

        method: "post",
        headers: {
            'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
            'X-Authorization-Firebase': token
        },
        data: formData,
        url: url
    });
}





export const deleteBookPaintingGame = (id) => {

    const url = "/BookPaintingGame/deleteBookPaintingGame"
    let token = firebase.auth().currentUser.h.b;
    const formData = new FormData();
    formData.append("id", id);
    return axios({

        method: "delete",
        headers: {
            'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
            'X-Authorization-Firebase': token
        },
        data: formData,
        url: url
    });
}


export const saveBookPaintingGame = (Book_ID, Image_URL) => {

    const url = "/BookPaintingGame/addBookPaintingGame"
    let token = firebase.auth().currentUser.h.b;
    const formData = new FormData();
    formData.append("Book_ID", Book_ID);
    formData.append("Image_Url", Image_URL);
    return axios({

        method: "post",
        headers: {
            'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
            'X-Authorization-Firebase': token
        },
        data: formData,
        url: url
    });
}


/*Start of Drag Drop*/

export const getDragAndDropBook = (DDID) => {
    const url = "/BookDragDrop/getBookDragAndDropData"
    let token = firebase.auth().currentUser.h.b;
    const formData = new FormData();
    formData.append("BookDragDrop_ID", DDID);
    return axios({
        method: "post",
        headers: {
            'X-Authorization-Firebase': token
        },
        data: formData,
        url: url
    });
}

export const saveBookDragAndDrop = (attributes) => {
    const url = "/BookDragDrop/saveBookDragDrop"
    let token = firebase.auth().currentUser.h.b;
    return axios({
        method: "post",
        headers: {
            'content-type': 'application/json',
            'X-Authorization-Firebase': token
        },
        data: {
            bookId: attributes.parentId,
            imageUrl: attributes.game_Image,
            cropedImageUrl: attributes.cropedImageUrl,
            dragDropWidth: attributes.dragDropWidth,
            dragDropHeight: attributes.dragDropHeight,
            draggableElements: attributes.listElements
        },
        url: url
    });
}

export const deleteBookDragAndDrop = (id) => {
    const url = "/BookDragDrop/deleteDragAndDrop"
    let token = firebase.auth().currentUser.h.b;
    const formData = new FormData();
    formData.append("BookDragDrop_ID", id);
    return axios({
        method: "post",
        headers: {
            'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
            'X-Authorization-Firebase': token
        },
        data: formData,
        url: url
    });
}

export const addDraggableElementBook = (DDID, draggableWidth, draggableHeight, xCoordinate, yCoordinate, draggableImage) => {
    const url = "/BookDragDrop/addDraggableElement"
    let token = firebase.auth().currentUser.h.b;
    return axios({
        method: "post",
        headers: {
            'X-Authorization-Firebase': token
        },
        data: {
            dragDropId: DDID,
            draggableWidth: draggableWidth,
            draggableHeight: draggableHeight,
            xCoordinate: xCoordinate,
            yCoordinate: yCoordinate,
            draggableImage: draggableImage,
        },
        url: url
    });
}

export const deleteDraggableElementBook = (id) => {
    const url = "/BookDragDrop/deleteDraggableElement"
    let token = firebase.auth().currentUser.h.b;
    const formData = new FormData();
    formData.append("DraggableElement_ID", id);
    return axios({
        method: "post",
        headers: {
            'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
            'X-Authorization-Firebase': token
        },
        data: formData,
        url: url
    });
}

export const updateOneDraggableElementBook = (id, width, height, XCoordinate, YCoordinate, imageURL) => {
    const url = "/BookDragDrop/updateOneDraggableElement";
    let token = firebase.auth().currentUser.h.b;
    const formData = new FormData();
    formData.append("DraggableId", id);
    formData.append("DraggbleWidth", width);
    formData.append("DraggbleHeight", height);
    formData.append("XCoordinate", XCoordinate);
    formData.append("YCoordinate", YCoordinate);
    formData.append("url", imageURL);

    return axios({
        method: "post",
        headers: {
            'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
            'X-Authorization-Firebase': token
        },
        data: formData,
        url: url
    });
}

export const updateDragAndDropBook = (id, width, height, imageURL, croppedImageURL) => {
    const url = "/BookDragDrop/updateBookDragAndDrop";
    let token = firebase.auth().currentUser.h.b;
    const formData = new FormData();
    formData.append("Id", id);
    formData.append("DragDropWidth", width);
    formData.append("DragDropHeight", height);
    formData.append("url", imageURL);
    formData.append("Cropped_Image_Url", croppedImageURL);

    return axios({
        method: "post",
        headers: {
            'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
            'X-Authorization-Firebase': token
        },
        data: formData,
        url: url
    });
}

/*End of Drag Drop*/

export const emptyError = () => dispatch => {
    dispatch({
        type: EMPTY_BOOK,
        payload: ""
    })
}

// Pages

// Add Page
export const addBookPage = (bookID, text, image, pageOrder) => {
    return (dispatch, getState, { getFirebase }) => {
        const firebase = getFirebase();
        firebase.auth().currentUser.getIdToken().then((token) => {
            dispatch({
                type: LOAD_BOOK_PAGE,
                payload: 'loading'
            })

            const url = '/BookPage/addPage';
            const formData = new FormData();
            formData.append('book_id', bookID);
            formData.append('text', text);
            formData.append('image', image);
            formData.append('page_index', pageOrder);

            const config = {
                headers: {
                    'X-Authorization-Firebase': token
                }
            }
            post(url, formData, config).then((res) => {
                dispatch(
                    {
                        type: ADD_BOOK_PAGE,
                        payload: res.data
                    }
                )
            }).catch((Error) => {
                dispatch({
                    type: ERROR_ADDING_BOOK_PAGE,
                    payload: Error.message
                })
            })
        })
    }
}

// Update Page

export const updateBookPage = (pageID, text, image, pageIndex, lastPageID) => {
    return (dispatch, getState, { getFirebase }) => {

        const firebase = getFirebase();
        firebase.auth().currentUser.getIdToken().then((token) => {
            dispatch({
                type: LOAD_BOOK_PAGE,
                payload: 'loading'
            })

            const url = "/BookPage/updatePage";
            const formData = new FormData();
            formData.append('page_id', pageID);
            formData.append('text', text);
            formData.append('image', image);
            formData.append('page_index', pageIndex);
            const config = {
                headers: {
                    'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
                    'X-Authorization-Firebase': token
                }
            }
            post(url, formData, config).then((res) => {
                if (lastPageID && pageID == lastPageID) {
                    res.data.lastPageLoading = false
                    dispatch(
                        {
                            type: UPDATE_BOOK_PAGE,
                            payload: res.data
                        }
                    )
                } else if (lastPageID) {
                    res.data.lastPageLoading = true
                }

            }).catch((Error) => {

                if (pageIndex) {
                    alert("Error In Page No. = " + pageIndex)
                } else if (pageID) {
                    alert("Error In Page ID. = " + pageID)
                } else {
                    alert("Something Wrong In Pages")
                }

                dispatch({
                    type: ERROR_UPDATE_BOOK_PAGE,
                    payload: Error.message
                })
            })
        })

    }
}

// Delete Page 
export const deleteBookPage = (id) => {
    return (dispatch, getState, { getFirebase }) => {
        const firebase = getFirebase();
        firebase.auth().currentUser.getIdToken().then((token) => {
            const url = "/BookPage/deletePage"
            const formData = new FormData();
            formData.append("page_id", id);

            const config = {
                headers: {
                    'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
                    'X-Authorization-Firebase': token
                }

            }
            post(url, formData, config).then((res) => {
                dispatch({
                    type: DELETE_BOOK_PAGE,
                    payload: res.data
                })
            }).catch((Error) => {
                dispatch({
                    type: ERROR_DELETING_BOOK_PAGE,
                    payload: Error.response.data.message
                })
            })

        })
    }
}
