import React, { Component } from 'react';
import { withFirebase } from '../../config';
import { Dropdown, Input, Button, Icon } from 'semantic-ui-react';
import { Link, Redirect } from 'react-router-dom';
/*navbar*/
import OuterHeader from '../Layout/OuterHeader';
import Dashboard from '../Dashboard';
import InnerHeader from '../Layout/InnerHeader';
import NavBar from '../Layout/NavBar';
import Footer from '../Layout/Footer';
/*Redux */
import { connect } from 'react-redux';
import propTypes from 'prop-types';
/*Actions */
import { updateChannel, emptyChannel, getChannelData } from '../../Actions/RadioChannelAction'
import { fetchAllSubValues } from '../../Actions/ContentSubValueAction';
import { fetchAllRadio, getRadioData } from '../../Actions/RadioActions';
/*CSS */
import '../../css/AppCss/Episode/addEpisode.css';
import '../../css/Songs.css';
/**Other Component */
import PublishDate from '../Layout/PublishDate';
import UploadImageWithoutDropZone from '../S3Uploading/UploadImageWithoutDropZone'
import UploadAudio from '../S3Uploading/UploadAudio';
import { SIGN_IN } from '../../routes';

const is_free_type = [{
    key: 0,
    text: 'paid',
    value: 'paid'
},
{
    key: 1,
    text: 'free',
    value: 'free'
}]


const INITIAL_STATE = {
    selected_radio_id: "",
    selected_radio_name: "",

    id: null,
    name: "",
    description: "",
    name_in_english: "",
    description_in_english: "",
    publishDate: "",
    activityId: "",

    imageUrl: "",

    trackWithMusicUrl: "",
    trackWithoutMusicUrl: "",
    radiobutton: '',
    voice: '',
    subValueId: '',
    isFree: '',
    featured: false,
    auth_user: {},

    ////////////////// validation form
    error: true,
    errorMessage: "",

    formErrors: { general: '' },

}


class EditRadioChannel extends Component {

    constructor(props) {
        super(props);
        this.state = { ...INITIAL_STATE };
    }

    componentDidMount() {
        const { auth } = this.props
        if (!auth.uid) {
            return (<Redirect to={SIGN_IN}></Redirect>)
        }
        else {
            this.props.fetchAllSubValues();
            this.props.fetchAllRadio();

            let radioName = this.props.match.params.radioName;
            let radioId = this.props.match.params.radioId;
            let channelId = this.props.match.params.channelId;


            this.setState({
                id: channelId,
                selected_radio_id: radioId,
                selected_radio_name: radioName,

            })
            this.props.getChannelData(channelId);


        }

    }

    componentWillReceiveProps(nextprop) {

        let radioChannel = nextprop.radioChannel;


        let paymentAvailability = ''
        if (radioChannel.isFree == 0) {
            paymentAvailability = 'paid'
        }
        else if (radioChannel.isFree == 1) {
            paymentAvailability = 'free'
        }

        let audio = radioChannel.voice;
        if (radioChannel.voice == undefined &&
            radioChannel.voice == null &&
            radioChannel.voice == "undefined"
            && radioChannel.voice == "null"
            && radioChannel.voice == 'null') {
            audio = null
        }

        let trackWithMusic = radioChannel.trackWithMusic;
        if (radioChannel.trackWithMusic == undefined &&
            radioChannel.trackWithMusic == null &&
            radioChannel.trackWithMusic == "undefined"
            && radioChannel.trackWithMusic == "null"
            && radioChannel.trackWithMusic == 'null') {
            trackWithMusic = null
        }


        let trackWithoutMusic = radioChannel.trackWithoutMusic;
        if (radioChannel.trackWithoutMusic == undefined &&
            radioChannel.trackWithoutMusic == null &&
            radioChannel.trackWithoutMusic == "undefined"
            && radioChannel.trackWithoutMusic == "null"
            && radioChannel.trackWithoutMusic == 'null') {
            trackWithoutMusic = null
        }

        let subvalue = ''
        if (radioChannel.contentSubValue === undefined ||
            radioChannel.contentSubValue === null) {
            subvalue = ''
        } else {
            subvalue = radioChannel.contentSubValue.id
        }

        this.setState(
            {
                name: radioChannel.name,
                description: radioChannel.description,
                imageUrl: radioChannel.image,
                status: radioChannel.status,
                trackWithMusicUrl: trackWithMusic,
                trackWithoutMusicUrl: trackWithoutMusic,
                publishDate: radioChannel.publishDate,
                activityId: radioChannel.activityId.id,
                isFree: paymentAvailability,
                voice: audio,
                subValueId: subvalue,
                name_in_english: radioChannel.nameInEnglish,
                description_in_english: radioChannel.descriptionInEnglish,
                featured: radioChannel.featured,
            })
            // let generalValidation = this.state.formErrors
            // // if (radioChannel.nameInEnglish !== null && radioChannel.nameInEnglish !== undefined ) {
            // //     generalValidation.general = '';
            // //     this.setState({ formErrors: generalValidation });
            // // }else {
            // //     generalValidation.general = "Empty Data is not accepted";
            // //     this.setState({ formErrors: generalValidation });
            // // }
    }

    getImageUrl(imageUrl) {
        if (imageUrl) {
            this.setState({ imageUrl: imageUrl })
        }
    }

    getPublishDate(publishDate) {
        if (publishDate) {
            this.setState({ publishDate: publishDate });
        }
    }

    getAudio(audioUrl) {
        if (audioUrl) {

            // check if url =  delete send empty to DB
            if (audioUrl === 'delete') {
                this.setState({ voice: '' });
            }
            // else send the url to DB
            else {
                this.setState({ voice: audioUrl });
            }

        }
    }


    getTrackWithMusic(audioUrl) {
        if (audioUrl) {

            // check if url =  delete send empty to DB
            if (audioUrl === 'delete') {
                this.setState({ trackWithMusicUrl: '' });
            }
            // else send the url to DB
            else {
                this.setState({ trackWithMusicUrl: audioUrl });
            }

        }
    }


    getTrackWithoutMusic(audioUrl) {
        if (audioUrl) {

            // check if url =  delete send empty to DB
            if (audioUrl === 'delete') {
                this.setState({ trackWithoutMusicUrl: '' });
            }
            // else send the url to DB
            else {
                this.setState({ trackWithoutMusicUrl: audioUrl });
            }

        }
    }


    updateChannel(id, name, description, Image,
        PublishDate, trackWithMusic, trackWithoutMusic,
        activityId,
        isFree, voice,
        subValueId, selected_radio_id,name_in_english, description_in_english, featured) {

        this.props.updateChannel(id, name, description, Image,
            PublishDate, trackWithMusic, trackWithoutMusic,
            activityId,
            isFree, voice,
            subValueId, selected_radio_id, name_in_english, description_in_english, featured);
    }

    handleChange = (e) => {
        let generalValidation = this.state.formErrors;

        let checkSpaces = e.target.value.replace(/\s\s+/g, ' ');
        this.setState({ [e.target.name]: checkSpaces });
        let nameOfState = e.target.name;
        switch (e.target.name) {
            case 'description':
                this.setState({ [e.target.name]: checkSpaces });
                break;
            case 'name_in_english':
                this.setState({ [e.target.name]: checkSpaces });
                break;        
            default:
                setTimeout(() => {
                    if (checkSpaces === '' || checkSpaces === null || checkSpaces === ' ') {
                        generalValidation.general = "Empty Data is not accepted";
                        this.setState({ formErrors: generalValidation });
                    } else {
                        generalValidation.general = '';
                        this.setState({ formErrors: generalValidation });
                    }

                    if ((this.state.imageUrl === '' ||
                        this.state.imageUrl === null ||
                        this.state.imageUrl === undefined)
                        || (this.state.name === '' ||
                            this.state.name === null ||
                            this.state.name === ' ')
                        || this.state.description_in_english.length > 255    
                        // || (this.state.name_in_english === '' ||
                        //     this.state.name_in_english === null ||
                        //     this.state.name_in_english === ' ')     
                    ) {
                        {
                            generalValidation.general = nameOfState === "description_in_english" ? 
                            "Description in English is too long (greater than 255 characters)." : 
                            "Empty Data is not accepted";
                            this.setState({ formErrors: generalValidation });
                        }
                    }

                }, 0);
        }
    }

    handlePaymentAva = (e, { value }) => {

        console.log("value in handle change = " + value)

        this.setState({
            isFree: value
        })

    }

    handleSubValueChange = (e, { value }) => {
        this.setState({ subValueId: value });
    };

    handleSubmit = (e) => {

        if (e.nativeEvent.submitter.id === "nextButton") {

            console.log("handle Submit")

            const { id, name, description, imageUrl,
                publishDate, trackWithMusicUrl, trackWithoutMusicUrl,
                activityId,
                isFree, voice,
                subValueId,
                selected_radio_id, name_in_english, description_in_english, featured } = this.state;

            console.log(" publishDateTime in handleSubmit " + publishDate)

            this.updateChannel(id, name, description, imageUrl,
                publishDate, trackWithMusicUrl, trackWithoutMusicUrl,
                activityId,
                isFree, voice,
                subValueId, selected_radio_id, name_in_english, description_in_english, featured);
        }

        /// calling save
        e.preventDefault();

    }

    handleRadioChange = (e, { value }) => {

        this.setState({ selected_radio_id: value[0].id })
        this.setState({ selected_radio_name: value[1].name })
    }

    handleCheckboxChange = () => {
        this.setState({ featured: !this.state.featured })
      };

    render() {

        const { allSubValues } = this.props;
        const subValuesList = [];
        for (let i = 0; i < allSubValues.length; i++) {
            subValuesList.push({
                key: allSubValues[i].id,
                text: allSubValues[i].contentValue,
                value: allSubValues[i].id
            });
        }

        // for radio dropdown 
        const { radio } = this.props;

        const radioList = [];
        for (let i = 0; i < radio.length; i++) {
            radioList.push({
                key: radio[i].id,
                text: radio[i].name,
                value: [{ id: radio[i].id }, { name: radio[i].name }]
            })
        }

        const { success,
            error, loading } = this.props

        // loading button
        let $buttonSubmitLoader = (
            <Button disabled={this.state.formErrors.general}
                type="submit" id="nextButton" >
                Save
            </Button>);

        if (loading === true) {
            $buttonSubmitLoader = (
                <button id="nextButton"
                    class="ui loading button">Loading</button>);
        }

        if (success === true) {
            alert("Channel updated successfully");
            this.props.emptyChannel();

            const { redirectionPage } = this.props;

            this.props.getRadioData(redirectionPage.series_Id);

            var pageName = redirectionPage.pageName
            return (<Redirect to={pageName} ></Redirect>)
        }

        else if (success === false) {
            alert("error missing data or network error "
                + error);
            this.props.emptyChannel();
        }

        const { name, imageUrl,
            publishDate,
            isFree } = this.state;

        const isInvalid =
            name === '' || name == undefined ||
            imageUrl === '' || imageUrl == undefined ||
            publishDate === '' || publishDate == undefined ||
            isFree === '' || isFree == undefined;


        return (
            <div className="container">
                <OuterHeader />
                <div className="container">
                    <div className="container-body">
                        <div className="row">

                            <Dashboard />
                            <div className="col">
                                <InnerHeader />
                                <div className="main-content">
                                    <div className="row">
                                        <div className="col-12 left-main-body" id="left-main-body">
                                            <NavBar />
                                            <br />
                                            <div className="container">
                                                <div className="container">
                                                    <h2 className="label">Edit Channel</h2>
                                                    <br />
                                                    <form onSubmit={this.handleSubmit}>
                                                        <p style={{ color: "red" }}>
                                                            {this.state.formErrors.general}
                                                        </p>


                                                        <label htmlFor="Image" className="label">
                                                            Channel Image
                                                        </label>
                                                        <br />

                                                        <div className="row">

                                                            <div className=" col-4">
                                                                <UploadImageWithoutDropZone
                                                                    getImageURLCallBack={this.getImageUrl.bind(this)}
                                                                    imageUrl={this.state.imageUrl}
                                                                    imagepreview={this.state.imageUrl}
                                                                    s3DirectryrName='radiochannel' />

                                                            </div>
                                                        </div>

                                                        <br />

                                                        <div className="input-field">
                                                            <label htmlFor="name"
                                                                className="label">
                                                                    <span className="required_input">*</span>
                                                                    Channel Name</label><br />
                                                            <input
                                                                dir="auto"
                                                                className="form-control"
                                                                type="text" name="name"
                                                                fluid id="name"
                                                                onChange={this.handleChange}
                                                                value={this.state.name}
                                                                required />
                                                        </div>

                                                        <div className="input-field">
                                                            <label htmlFor="name_in_english"
                                                                className="label">Channel Name In English</label><br />
                                                            <input
                                                                dir="auto"
                                                                className="form-control"
                                                                type="text" name="name_in_english"
                                                                fluid id="name_in_english"
                                                                onChange={this.handleChange}
                                                                value={this.state.name_in_english}
                                                                 />
                                                        </div>
                                                        <br />
                                                        <div className="form-group">
                                                            <label className="label"
                                                                for="exampleFormControlTextarea1">
                                                                Channel Description
                                                            </label><br />
                                                            <textarea dir="auto"
                                                                className="form-control"
                                                                id="exampleFormControlTextarea1"
                                                                rows="3" name="description"
                                                                fluid onChange={this.handleChange}
                                                                value={this.state.description} />
                                                        </div>
                                                        <br />

                                                        <div className="form-group">
                                                            <label className="label"
                                                                for="exampleFormControlTextarea1">
                                                                Channel Description In English
                                                            </label><br />
                                                            <textarea dir="auto"
                                                                className="form-control"
                                                                id="exampleFormControlTextarea1"
                                                                rows="3" name="description_in_english"
                                                                fluid onChange={this.handleChange}
                                                                value={this.state.description_in_english} />
                                                        </div>
                                                        <br />

                                                        <label htmlFor="episode_series_id"
                                                            className="label">Select the Radio
                                                          <br />
                                                            <strong>Parent is : {this.state.selected_radio_name}</strong>
                                                        </label>
                                                        <br />
                                                        <Dropdown fluid search
                                                            selection
                                                            placeholder='Select Radio'
                                                            name="episode_series_id"
                                                            onChange={this.handleRadioChange}
                                                            options={radioList} />
                                                        <br />


                                                        <label htmlFor="episode_series_id"
                                                            className="label">
                                                            <span className="required_input">*</span>
                                                            Select payment availability</label><br />

                                                        <Dropdown fluid selection
                                                            placeholder='Select Payment Availability'
                                                            id="episode_series_id"
                                                            onChange={this.handlePaymentAva}
                                                            options={is_free_type}
                                                            value={this.state.isFree}
                                                        />
                                                        <br />

                                                        <label htmlFor="episode_series_id"
                                                            className="label">
                                                            Sub Value</label><br />

                                                        <Dropdown fluid search selection
                                                            placeholder='Select Sub Value'
                                                            id="episode_series_id"
                                                            onChange={this.handleSubValueChange}
                                                            options={subValuesList}
                                                            value={this.state.subValueId}
                                                        />
                                                        <br />

                                                        {/* DataComponent */}
                                                        <PublishDate getPublishDateCallBack={this.getPublishDate.bind(this)}
                                                            date={this.state.publishDate}
                                                            showNow={!isInvalid} />
                                                        <br />
                                                        <label>
                                                            <input
                                                                type="checkbox"
                                                                checked={this.state.featured}
                                                                onChange={this.handleCheckboxChange}
                                                            />
                                                            {' Featured'}
                                                            </label>
                                                        <br />


                                                        <label htmlFor="name" className="label">
                                                            track With Music
                                                        </label>
                                                        <UploadAudio
                                                            getAudioUrlCallBack={this.getTrackWithMusic.bind(this)}
                                                            audioPreview={this.state.trackWithMusicUrl}
                                                            audioUrl={this.state.trackWithMusicUrl}
                                                            s3DirectryrName="radiochannel/audio"
                                                        />


                                                        <label htmlFor="name" className="label">
                                                            track Without Music
                                                        </label>
                                                        <UploadAudio
                                                            getAudioUrlCallBack={this.getTrackWithoutMusic.bind(this)}
                                                            audioPreview={this.state.trackWithoutMusicUrl}
                                                            audioUrl={this.state.trackWithoutMusicUrl}
                                                            s3DirectryrName="radiochannel/audio" />


                                                        <label htmlFor="name" className="label">
                                                            Audio
                                                        </label>
                                                        <UploadAudio
                                                            getAudioUrlCallBack={this.getAudio.bind(this)}
                                                            audioPreview={this.state.voice}
                                                            audioUrl={this.state.voice}
                                                            s3DirectryrName="radiochannel/audio"
                                                        />



                                                        {$buttonSubmitLoader}

                                                        <br /> <br /> <br />
                                                    </form>
                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        )
    }
}


const mapStateToProps = state => ({

    radioChannel: state.radioChannel.item,
    activity: state.radioChannel.item.activityId,
    auth: state.firebase.auth,

    loading: state.radioChannel.loading,
    success: state.radioChannel.channel_updated_successfully,
    error: state.radioChannel.error_updated_channel,

    allSubValues: state.ContentSubValue.items,
    redirectionPage: state.redirectionPage.item,
    radio: state.radio.items,
});

export default connect(
    mapStateToProps,
    {
        getChannelData,
        emptyChannel,
        updateChannel,
        fetchAllSubValues,
        fetchAllRadio,
        getRadioData
    })(EditRadioChannel);
