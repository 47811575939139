import React, { Component } from 'react';
import { withFirebase } from '../../config';
import { Dropdown, Input, Button, Icon } from 'semantic-ui-react';
import { Link, Redirect } from 'react-router-dom';
/*navbar*/
import OuterHeader from '../Layout/OuterHeader';
import Dashboard from '../Dashboard';
import InnerHeader from '../Layout/InnerHeader';
import NavBar from '../Layout/NavBar';
import Footer from '../Layout/Footer';
/*Redux */
import { connect } from 'react-redux';
import propTypes from 'prop-types';
/*Actions */
import { addChannel } from '../../Actions/RadioChannelAction'
import { fetchAllSubValues } from '../../Actions/ContentSubValueAction';
import { fetchAllRadio } from '../../Actions/RadioActions';
import { addActivity } from '../../Actions/ActivityActions';
/*CSS */
import '../../css/AppCss/Episode/addEpisode.css';
import '../../css/Songs.css';
/**Other Component */
import PublishDate from '../Layout/PublishDate';
import UploadImage from '../S3Uploading/UploadImage';
import UploadAudio from '../S3Uploading/UploadAudio';
import { ADD_RADIO_CHAN_ACTIVITY } from '../../routes';

const is_free_type = [{
    key: 0,
    text: 'paid',
    value: 'paid'
},
{
    key: 1,
    text: 'free',
    value: 'free'
}]


const INITIAL_STATE = {
    redirection: false,

    selected_radio_id: "",
    selected_radio_name: "",

    returned_radio_id_viewRadioPage: "",
    returned_radio_name_viewRadioPage: "",


    name: "",
    description: "",
    name_in_english: "",
    description_in_english: "",
    imageUrl: "",

    trackWithMusicUrl: "",
    trackWithoutMusicUrl: "",

    isFree: '',

    auth_user: {},
    publishDateTime: "",
    radiobutton: '',
    voice: '',  
    subValueId: '',
    featured: false,
    ////////////////// validation form
    error: true,
    errorMessage: "",

}

class AddRadioChannel extends Component {
    constructor(props) {
        super(props);
        this.state = INITIAL_STATE

    }
    componentDidMount() {
        this.props.fetchAllRadio();
        this.props.fetchAllSubValues();


        const { radioChannel } = this.props;
        this.setState({
            error: false,
            name: radioChannel.name,
            imageUrl: radioChannel.image,
            publishDateTime: radioChannel.PublishDate,
            trackWithMusicUrl: radioChannel.trackWithMusic,
            trackWithoutMusicUrl: radioChannel.trackWithoutMusic,
            isFree: radioChannel.isFree,
            voice: radioChannel.voice,
            subValueId: radioChannel.subValueId,
            description: radioChannel.description,
            name_in_english: radioChannel.nameInEnglish,

            selected_radio_id: radioChannel.selected_radio_id,
            selected_radio_name: radioChannel.selected_radio_name,

            returned_radio_id_viewRadioPage: radioChannel.selected_radio_id,
            returned_radio_name_viewRadioPage: radioChannel.selected_radio_name,

            radiobutton:radioChannel.radiobutton,

        })


        const { parentId, parentName } = this.props.location;


        if (parentId) {
            this.setState({
                returned_radio_name_viewRadioPage: parentName,
                returned_radio_id_viewRadioPage: parentId,
                selected_radio_id: parentId,
                selected_radio_name: parentName
            });
        }
    }


    handleDateChange = (e, { name, value }) => {
        if (this.state.hasOwnProperty(name)) {
            this.setState({ [name]: value });
        }
    }

    handleChange = (e) => {
        let checkSpaces = e.target.value.replace(/\s\s+/g, ' ');
        this.setState({ [e.target.name]: checkSpaces });
        let nameOfState = e.target.name;
        setTimeout(() => {
            if (
                (this.state.name === ''
                    || this.state.name === null
                    || this.state.name === ' ') 
                || this.state.description_in_english.length > 255    
                    // (this.state.name_in_english === ''
                    // || this.state.name_in_english === null
                    // || this.state.name_in_english === ' ')
            ) {
                this.setState({ error: true, errorMessage: nameOfState === "description_in_english" ? 
                "Description in English is too long (greater than 255 characters)." : 
                "Empty Data is not accepted" });
            }
            else if (
                (this.state.name !== ''
                    && this.state.name !== null
                    && this.state.name !== ' ')
                && this.state.description_in_english.length <= 255    
                    // (this.state.name_in_english !== ''
                    // && this.state.name_in_english !== null
                    // && this.state.name_in_english !== ' ')
            ) {
                this.setState({ error: false, errorMessage: "" });
            }
            else {
                this.setState({
                    error: true,
                    errorMessage: "Empty Data is not accepted"
                });
            }
        }, 0);
    }

    handlePaymentAva = (e, { value }) => {

        console.log(value)

        this.setState({
            isFree: value
        })
    }

    handleChannelParentChange = (e, { value }) => {

        this.setState({ selected_radio_id: value[0].id })
        this.setState({ selected_radio_name: value[1].name })
    }

    handleSubValueChange = (e, { value }) => {
        this.setState({ subValueId: value });
    };

    handleSubmit = (e) => {
        e.preventDefault();

        const { name, description,
            imageUrl,
            publishDateTime,
            trackWithMusicUrl,
            trackWithoutMusicUrl,

            radiobutton, isFree,
            voice, subValueId,
            selected_radio_id,
            selected_radio_name,
            name_in_english,
            description_in_english,
            featured } = this.state;

        if (e.nativeEvent.submitter.id === "nextButton") {


            if (selected_radio_id === ''
                || selected_radio_id == undefined
                || selected_radio_name === ''
                || selected_radio_name == undefined) {
                alert("Empty Channel Parent")
                this.setState({ validateInput: false })

            }
            else if (isFree === ''
                || isFree == undefined
                || isFree == null
                || isFree == "") {
                alert("You must choose payment availability")
                this.setState({ validateInput: false })
                e.preventDefault();
            }

            else if (publishDateTime === ''
                || publishDateTime == undefined) {
                this.setState({ validateInput: false })
            }

            else {
                if (name === ''
                    || name == undefined 
                    // || name_in_english === ''
                    // || description_in_english == undefined
                      ) {
                    alert("Empty Name ")
                    this.setState({ validateInput: false })
                } else {
                    this.setState({ validateInput: true });
                    this.props.addChannel(name,
                        description, imageUrl,
                        publishDateTime,
                        trackWithMusicUrl,
                        trackWithoutMusicUrl,
                        radiobutton, isFree, voice,
                        subValueId,
                        selected_radio_id,
                        selected_radio_name,
                        name_in_english,
                        description_in_english,
                        featured)
                }
            }
        }
        /// calling save
    }

    handleCancel = (e) => {
        this.setState({ redirection: true })
    }

    handleCheckboxChange = () => {
        this.setState({ featured: !this.state.featured })
      };

    getPublishDate(publishDateTime, radioButtonState) {
        if (radioButtonState) {
            this.setState({ radiobutton: radioButtonState })
        }

        if (publishDateTime) {
            this.setState({ publishDateTime: publishDateTime });
        }
    }

    getImageUrl(imageUrl) {
        if (imageUrl === 'delete') {
            this.setState({
                imageUrl: '',
                error: true,
                errorMessage: "Empty Data is not accepted"
            });
        }
        else if (imageUrl !== undefined && imageUrl !== '') {
            this.setState({ imageUrl: imageUrl });
            if (this.state.name !== ''
                && this.state.name !== ' '
                && this.state.name !== null
                // && this.state.name_in_english !== ''
                // && this.state.name_in_english !== ' '
                // && this.state.name_in_english !== null
            ) {
                this.setState({
                    error: false,
                    errorMessage: ""
                });
            }
        }
        else {
            this.setState({
                imgURL: '',
                error: true,
                errorMessage: "Empty Data is not accepted"
            });
        }
    }


    getAudio(audioUrl) { // get  Audio
        if (audioUrl) {
            // check if url =  delete send empty to DB
            if (audioUrl === 'delete') {

                this.setState({ voice: '' });
                console.log("audio URL in add episode  if = "
                    + this.state.voice);
            }
            // else send the url to DB
            else {
                this.setState({ voice: audioUrl });
                console.log("audio URL in add episode else = "
                    + this.state.voice);

            }

        }
    }


    getTrackWithMusic(audioUrl) { // get  Audio
        if (audioUrl) {
            // check if url =  delete send empty to DB
            if (audioUrl === 'delete') {

                this.setState({ trackWithMusicUrl: '' });

            }
            // else send the url to DB
            else {
                this.setState({ trackWithMusicUrl: audioUrl });

            }

        }
    }

    getTrackWithoutMusic(audioUrl) { // get  Audio
        if (audioUrl) {
            // check if url =  delete send empty to DB
            if (audioUrl === 'delete') {

                this.setState({ trackWithoutMusicUrl: '' });
                console.log("audio URL in add episode  if = "
                    + this.state.voice);
            }
            // else send the url to DB
            else {
                this.setState({ trackWithoutMusicUrl: audioUrl });
                console.log("audio URL in add episode else = "
                    + this.state.voice);

            }

        }
    }


    render() {
        const { name, description,
            imageUrl,
            publishDateTime,
            trackWithMusicUrl,
            trackWithoutMusicUrl,

            radiobutton, isFree,
            voice, subValueId,
            selected_radio_id,
            selected_radio_name,
            name_in_english } = this.state;

        const isInvalid =
            name === '' || name == undefined ||
            // name_in_english === '' || name_in_english == undefined ||
            imageUrl === '' || imageUrl == undefined ||
            isFree === '' || isFree == undefined ||
            selected_radio_id === '' || selected_radio_id == undefined ||
            selected_radio_name === '' || selected_radio_name == undefined ||
            trackWithMusicUrl === '' || trackWithMusicUrl == undefined ||
            trackWithoutMusicUrl === '' || trackWithoutMusicUrl == undefined;

        const { allSubValues } = this.props;
        const subValuesList = [];
        for (let i = 0; i < allSubValues.length; i++) {
            subValuesList.push({
                key: allSubValues[i].id,
                text: allSubValues[i].contentValue,
                value: allSubValues[i].id
            });
        }

        const { radio } = this.props;
        const radioList = [];
        for (let i = 0; i < radio.length; i++) {
            radioList.push({
                key: radio[i].id,
                text: radio[i].name,
                value: [{ id: radio[i].id }, { name: radio[i].name }]
            })
        }

        // for dropdown Radio selection 
        let selectedRadio = null;
        if (this.state.returned_radio_id_viewRadioPage) {
            selectedRadio = (<input
                dir="auto"
                className="form-control"
                fluid id="episode_description"
                value={this.state.returned_radio_name_viewRadioPage} />);
        }
        else {
            selectedRadio = (
                <Dropdown fluid
                    selection
                    placeholder='Select Radio'
                    name="radio_id"
                    // id="episode_series_id"  
                    onChange={this.handleChannelParentChange}
                    options={radioList} />
            );
        }

        if (this.state.redirection === true) {
            const { redirectionPage } = this.props;
            var pageName = redirectionPage.pageName
            return (<Redirect to={pageName} ></Redirect>)
        }


        if (this.state.validateInput === true) {
            return (<Redirect to={ADD_RADIO_CHAN_ACTIVITY}></Redirect>)
        }

        return (
            <div className="container">
                <OuterHeader />
                <div className="container">
                    <div className="container-body">
                        <div className="row">

                            <Dashboard />
                            <div className="col">
                                <InnerHeader />
                                <div className="main-content">
                                    <div className="row">
                                        <div className="col-12 left-main-body"
                                            id="left-main-body">
                                            <NavBar />
                                            <br />
                                            <div className="container">
                                                <div className="main-progress-bar">
                                                    <ol className="progress-tracker">
                                                        <li className="step active"><span className="step-name small-font">Add Channel</span></li>
                                                        <li className="step"><span className="step-name small-font">Add activity</span></li>
                                                        <li className="step "><span className="step-name small-font">Add Evaluation</span></li>
                                                        <li className="step"><span className="step-name small-font">Puplish Channel</span></li>
                                                    </ol>
                                                </div>
                                                <br />

                                                <form onSubmit={this.handleSubmit.bind(this)} >
                                                    <p style={{ color: "red" }}>{this.state.errorMessage} </p>
                                                    <br />

                                                    <div className="row">
                                                        <div className="col-4">
                                                            {/* <span className="required_input">*</span> */}
                                                            <UploadImage
                                                                getImageURLCallBack={this.getImageUrl.bind(this)}
                                                                imageUrl={this.state.imageUrl}
                                                                imagepreview={this.state.imageUrl}
                                                                s3DirectryrName='radiochannel'
                                                            />

                                                        </div>
                                                        <div className="col">
                                                            <label htmlFor="song_name" className="label">
                                                                <span className="required_input">*</span>
                                                                Channel Name</label><br />

                                                            <input
                                                                dir="auto"
                                                                className="form-control"
                                                                fluid name="name" id="name"
                                                                onChange={this.handleChange}
                                                                value={this.state.name} />
                                                            <br />

                                                      
                                                            <label htmlFor="name_in_english" className="label">
                                                                {/* <span className="required_input">*</span> */}
                                                                Channel Name In English</label><br />

                                                            <input
                                                                dir="auto"
                                                                className="form-control"
                                                                fluid name="name_in_english" id="name_in_english"
                                                                onChange={this.handleChange}
                                                                value={this.state.name_in_english} />
                                                            <br />

                                                            <label className="label"
                                                                for="exampleFormControlTextarea1">
                                                                channel Description</label><br />

                                                            <textarea dir="auto"
                                                                className="form-control"
                                                                id="exampleFormControlTextarea1" rows="3"
                                                                name="description" onChange={this.handleChange}
                                                                value={this.state.description} />
                                                            <br />

                                                            <label className="label"
                                                                for="exampleFormControlTextarea1">
                                                                channel Description In English</label><br />

                                                            <textarea dir="auto"
                                                                className="form-control"
                                                                id="exampleFormControlTextarea1" rows="3"
                                                                name="description_in_english" onChange={this.handleChange}
                                                                value={this.state.description_in_english} />
                                                            <br />

                                                            <label htmlFor="name" className="label">
                                                                Track With Music
                                                            </label>
                                                            <UploadAudio
                                                                getAudioUrlCallBack={this.getTrackWithMusic.bind(this)}

                                                                audioPreview={this.state.trackWithMusicUrl}
                                                                audioUrl={this.state.trackWithMusicUrl}
                                                                s3DirectryrName="radiochannel/audio"
                                                            />
                                                            <br /><br />

                                                            <label htmlFor="name" className="label">
                                                                Track Without Music
                                                            </label>
                                                            <UploadAudio
                                                                getAudioUrlCallBack={this.getTrackWithoutMusic.bind(this)}

                                                                audioPreview={this.state.trackWithoutMusicUrl}
                                                                audioUrl={this.state.trackWithoutMusicUrl}
                                                                s3DirectryrName="radiochannel/audio"
                                                            />
                                                            <br /><br />

                                                            <label htmlFor="name" className="label">
                                                                Audio
                                                            </label>
                                                            <UploadAudio
                                                                getAudioUrlCallBack={this.getAudio.bind(this)}

                                                                audioPreview={this.state.voice}
                                                                audioUrl={this.state.voice}
                                                                s3DirectryrName="radiochannel/audio"
                                                            />
                                                            <br />

                                                            <label htmlFor="episode_series_id"
                                                                className="label">
                                                                <span className="required_input">*</span>
                                                                Select Radio
                                                            </label>
                                                            <br />

                                                            {selectedRadio}

                                                            <br />
                                                            <label htmlFor="episode_series_id"
                                                                className="label">
                                                                <span className="required_input">*</span>
                                                                Select payment availability</label><br />

                                                            <Dropdown required fluid selection
                                                                placeholder='Select Payment Availability'
                                                                id="episode_series_id"
                                                                onChange={this.handlePaymentAva}
                                                                options={is_free_type}
                                                                value={this.state.isFree}
                                                            />
                                                            <br />




                                                            <label htmlFor="episode_series_id"
                                                                className="label">
                                                                Sub Value</label><br />

                                                            <Dropdown required fluid search selection
                                                                placeholder='Select Sub Value'
                                                                id="episode_series_id"
                                                                onChange={this.handleSubValueChange}
                                                                options={subValuesList}
                                                                value={this.state.subValueId}
                                                            />
                                                            <br />


                                                            {/* DataComponent */}
                                                            {this.state.publishDateTime !== " " &&
                                                                this.state.publishDateTime !== '' &&
                                                                this.state.publishDateTime !== null &&
                                                                this.state.publishDateTime !== undefined ?
                                                                <PublishDate getPublishDateCallBack={this.getPublishDate.bind(this)}
                                                                    date={this.state.publishDateTime}
                                                                    showNow={!isInvalid} />

                                                                :
                                                                <PublishDate
                                                                    getPublishDateCallBack={this.getPublishDate.bind(this)}
                                                                    value={this.state.publishDateTime}
                                                                    showNow={!isInvalid} />}
                                                                <br />
                                                                <label>
                                                                <input
                                                                    type="checkbox"
                                                                    checked={this.state.featured}
                                                                    onChange={this.handleCheckboxChange}
                                                                />
                                                                {' Featured'}
                                                                </label>
                                                            <br />
                                                        </div>
                                                        <div className="buttons_container">

                                                            <Button icon labelPosition='right'
                                                                id="nextButton"
                                                                disabled={this.state.error}>
                                                                Next
                                                                    <Icon name='right arrow' />
                                                            </Button>

                                                            <Button
                                                                onClick={this.handleCancel}
                                                                id="episode_cancel">Cancel</Button>

                                                        </div>
                                                    </div>
                                                </form>

                                            </div>
                                        </div>


                                    </div>


                                </div>
                            </div>

                        </div>



                    </div>
                </div>
                <Footer />
            </div>

        )
    }
}

AddRadioChannel.propTypes = {
    addChannel: propTypes.func.isRequired,
    addActivity: propTypes.func.isRequired,
    fetchAllSubValues: propTypes.func.isRequired

}
const mapStateToProps = state => ({
    radioChannel: state.radioChannel.item,
    activities: state.activities.item,
    allSubValues: state.ContentSubValue.items,
    radio: state.radio.items,
    redirectionPage: state.redirectionPage.item
});

export default withFirebase(
    connect(
        mapStateToProps,
        {
            addChannel,
            addActivity,
            fetchAllSubValues,
            fetchAllRadio
        })(AddRadioChannel));
