import React from "react";
import { useDispatch } from "react-redux";

import CardComponent from "../../../images/cardComponent.png";
import { savePaymentMethod } from "../../../Actions/PaymentDataAction";
import { PRE_PAID_CARD } from "../constants";

const PrePaidCard =(
   {openLoginModal = () => false} 
)=>{
    const dispatch = useDispatch();
    const handleClick = () => {
       dispatch(savePaymentMethod(PRE_PAID_CARD));
       openLoginModal();
    };

    return(
        <div className="styled-div-pre-paid-card col-12 col-lg-7" onClick={handleClick} style={{ cursor: 'pointer' }}>
            <img style={{marginLeft: '3px'}} src={CardComponent} alt="" />
            <p className="pre-paid-text">هل لديك بطاقة دفع مسبقة من جيل ؟</p>
        </div>
    );
}

export default PrePaidCard;