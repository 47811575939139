import React, { useEffect, useState } from "react";
import Zain from "../../../images/portal/icons/zain.svg";
import Mada from "../../../images/portal/icons/mada.svg";
import MasterCard from "../../../images/portal/icons/mastercard.svg";
import Mobily from "../../../images/portal/icons/mobily.svg";
import Stc from "../../../images/portal/icons/stc.svg";
import PaymentMethodCard from "./PaymentMethodCard";
import Fawry from "../../../images/portal/fawry.svg";
import Etisalat from "../../../images/tpay/etisalat.png";
import WE from "../../../images/tpay/we.jpg"
import irqZain from "../../../images/mw/IraqFlag.jpg"
import lyImg from "../../../images/mw/libyaFlag.png"
import qaImg from "../../../images/mw/qatarFlag.png"
import { useSelector } from "react-redux";
import { E_Wallet_Payment_Method, Fawry_Payment_Method, JEEL_Etisalat, JEEL_MOBILY, JEEL_STC, JEEL_We, JEEL_ZAIN, JeelMADA, JeelMasterCard , IRAQ_ZAIN,LIBYA_MADAR,LIBYA_LIBYANA,QATAR_VODAPHONE } from "../constants";

export const PaymentMethods = ({
  showRenewal = false,
  openMainAuthModal = () => false,
  openSubAuthModal = () => false,
  openLoginModal = () => false,
}) => {
  const data = useSelector(
    (state) => state.paymentData.package_and_methods
  );
  const country = useSelector(
    (state) => state.countryName.country
  );


  const [paymentChosed, setPaymentChosed] = useState("MasterCard");
  const paymentMethodsList = data.paymentPayingMethods;


  return (
    <div className="payment-methods-list">
      {showRenewal && (
        <div className="method-renewal h4">هل تود تجديد باقتك</div>
      )}

      <PaymentMethodCard
        openMainAuthModal={openMainAuthModal}
        openSubAuthModal={openSubAuthModal}
        openLoginModal={openLoginModal}
        logo={getDiscountPercent(JeelMasterCard)}
        name={JeelMasterCard}
        active={true}
        setPaymentChosed={setPaymentChosed}
        paymentChosed={paymentChosed}
      />


      {paymentMethodsList && paymentMethodsList.length
        ? paymentMethodsList.map((method) => {
            if (method.name === Fawry_Payment_Method ||
                method.name === JeelMADA ||
                method.name === E_Wallet_Payment_Method ||
                method.name === JEEL_We ||
                method.name === JEEL_Etisalat ||
                method.name === JEEL_STC ||
                method.name === JEEL_MOBILY ||
                method.name === JEEL_ZAIN ||
                method.name === IRAQ_ZAIN ||
                method.name === LIBYA_MADAR ||
                method.name === LIBYA_LIBYANA ||
                method.name === QATAR_VODAPHONE 
                ) {
              return (
                <PaymentMethodCard
                  openMainAuthModal={openMainAuthModal}
                  openSubAuthModal={openSubAuthModal}
                  openLoginModal={openLoginModal}
                  logo={getDiscountPercent(method.name)}
                  name={method.name}
                  active={method.active}
                  setPaymentChosed={setPaymentChosed}
                  paymentChosed={paymentChosed}
                />
              );
            }
          })
        : ""}
    </div>                 
  );
};
export default PaymentMethods;

function getDiscountPercent(logo) {
  switch (logo) {
    case Fawry_Payment_Method:
      return Fawry;
    case E_Wallet_Payment_Method:
        return Fawry;  
    case JeelMasterCard:
      return MasterCard;
    case JeelMADA:
      return Mada;
    case JEEL_Etisalat:
      return Etisalat;
    case JEEL_We:
      return WE;   
    case JEEL_STC:
      return Stc;
    case JEEL_MOBILY:
      return Mobily;
    case JEEL_ZAIN:
      return Zain;       
    case IRAQ_ZAIN:
      return irqZain;
    case LIBYA_MADAR:
      return lyImg;
    case LIBYA_LIBYANA:
      return lyImg;      
    case QATAR_VODAPHONE:
      return qaImg;      
    default:
      return null;
  }
}
