import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  savePriceSubscribeCard,
  saveDiscountSubscribeCard,
  saveDescriptionSubscribeCard,
  saveExpirationDateSubscribeCard,
  saveSlugDateSubscribeCard,
} from "../../../Actions/PaymentDataAction";
export const SubscribeCard = ({
  price,
  type,
  beforeDiscount = 0,
  afterDiscount = 0,
  recommended = false,
  selected = false,
  slug,
  currency,
  expirationDate,
  discription,
  methodType,
  changeSubscribe = (typeSlug) => {
    return typeSlug;
  },
}) => {
  const dispatch = useDispatch();

  const calcDiscountPercentage = (afterDiscount * 100) / price;

  const [date] = expirationDate.split(" ");
  const handleSubscribe = () => {
    dispatch(savePriceSubscribeCard(price));
    dispatch(saveDiscountSubscribeCard(afterDiscount));
    dispatch(saveExpirationDateSubscribeCard(date));
    dispatch(saveDescriptionSubscribeCard(type));
    dispatch(saveSlugDateSubscribeCard(slug));
  };

  return (
    <div
      className={`subscribe-card relative ${selected && "active"} ${recommended ? "recommended-card" : ""
        }`}
      onClick={() => {
        changeSubscribe(slug);
        handleSubscribe();
      }}
    >
      <div className="subscribe-header p-3">
        <div className="subscribe-type">{type}</div>
      </div>
      <div className="subscribe-body p-3">
        <div></div>
        <div className="subscribe-price-details">
          {/* <div
            className={`subscribe-discount text-center mb-3 ${
              calcDiscountPercentage && calcDiscountPercentage > 0 ? "show-discount" : ""
            }`}
          >
            خصم {parseInt(calcDiscountPercentage)} %
          </div> */}
          <div className="subscribe-price text-center mb-4">
            {afterDiscount}{" "}
          </div>
          {methodType === "other" ? <div className="before-discount-price text-center mb-4">
            {beforeDiscount}{" "}
          </div> : ""}
          <div className="subscribe-currency text-center mb-4" >
            {currency}
          </div>
          {/* <div
            className={`subscribe-price has-discount text-center mb-4 ${
              afterDiscount && afterDiscount > 0 ? "show-discount" : ""
            }`}
          >
            {price} <span className="subscribe-currency"> {currency }</span>
          </div> */}
        </div>

      </div>
      <div className="subscribe-footer p-3 text-center mt-4">
        {selected ? "الباقة المختارة" : " أختر الباقة"}
      </div>
      {recommended && (
        <div className="subscribe-recommended text-center">موصي بها</div>
      )}
    </div>
  );
};
export default SubscribeCard;
