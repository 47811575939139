import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { changePaymentMethodType, savePaymentMethod } from "../../../Actions/PaymentDataAction";
import {  Fawry_Payment_Method, JEEL_Etisalat, JEEL_MOBILY, JEEL_STC, JEEL_We, JEEL_ZAIN,IRAQ_ZAIN,LIBYA_MADAR, LIBYA_LIBYANA,QATAR_VODAPHONE } from "../constants";
import { useHistory } from 'react-router-dom';

export const PaymentMethodCard = ({
  logo,
  name,
  openMainAuthModal = () => false,
  openSubAuthModal = () => false,
  openLoginModal = () => false,
  setPaymentChosed,
  paymentChosed
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const testType = useSelector(
    (state) => state.paymentData.paymentMethodType
  );
  const handlePaymentMethod = () => {
    dispatch(savePaymentMethod(name));
    if(name === JEEL_Etisalat ||
       name === JEEL_We ||
       name === JEEL_STC || 
       name === JEEL_MOBILY ||
       name === JEEL_ZAIN ||
       name === IRAQ_ZAIN ||
       name === LIBYA_LIBYANA ||
       name === LIBYA_MADAR ||
       name === QATAR_VODAPHONE)
       {	
      let url = '/telecomLogin'+`?name=${name}`;       
      history.push(url);
    }else{   
      openLoginModal();
    }
  }

  const handelMouseEnter =()=>{
    if(name === JEEL_Etisalat ||
       name === JEEL_We ||
        name === JEEL_STC ||
        name === JEEL_MOBILY ||
        name === JEEL_ZAIN ||
		name === IRAQ_ZAIN ||
        name === LIBYA_LIBYANA ||
        name === LIBYA_MADAR ||
        name === QATAR_VODAPHONE)        
    {
      dispatch(changePaymentMethodType("telecom"));
      dispatch(savePaymentMethod(name));
    }else{
      dispatch(changePaymentMethodType("other"));  
      dispatch(savePaymentMethod(name));
    }
    setPaymentChosed(name);
  }

  const handleMouseLeave =()=>{
    dispatch(changePaymentMethodType("other"));
    setPaymentChosed("MasterCard");
  }

  return (
   
    <div className={`payment-method-card ${
      name === paymentChosed && "selected"
    }`}
    // onClick={()=>handleClick(name)}
    onMouseEnter={()=> handelMouseEnter()}
    onMouseLeave={()=> handleMouseLeave()}
    >
      <div className="method-body">
        <div className="method-logo">
          <img src={logo} alt="logo" />
        </div>
        <div className="method-name"
            >الدفع عن طريق {name === JEEL_Etisalat ||
                             name === JEEL_We ||
                             name == JEEL_STC ||
                             name === JEEL_MOBILY ||
                             name === JEEL_ZAIN ? name.substring(5):name ||
                             name === IRAQ_ZAIN ||
					         name === LIBYA_LIBYANA ||
					         name === LIBYA_MADAR ||
					         name === QATAR_VODAPHONE                             
                             }
                              
       </div>
      </div>
      <div className="method-action">
        <button
          className="btn portal-main-btn pay-now"
          // onClick={() => openMainAuthModal()}
          // onClick={() => openSubAuthModal()}
          onClick={() =>{
              handlePaymentMethod();             
            } }

        >
          الدفع الان
        </button>
      </div>
    </div>
  );
};

export default PaymentMethodCard;
