import {
    LOAD_All_GAME_DATA, FETCH_All_GAME_DATA, ERROR_ALL_GAME_DATA,
    LOAD_GAME_DATA, ERROR_UPDATE_GAME_DATA, UPDATE_GAME_DATA,
    ERROR_ADDING_GAME_DATA, ADD_GAME_DATA, DELETING_GAME_DATA,
    ERROR_DELETING_GAME_DATA, EMPTY_GAME_DATA,
    GET_GAME_DATA, ERROR_LOADING_GAME_DATA
} from './Types'
import axios, { post } from 'axios'

export const fetchAllGameData = () => dispatch => {

    dispatch({
        type: LOAD_All_GAME_DATA,
        payload: 'loading'
    })

    axios.get('/gameData/getAllGameDataList')
        .then((res) => {
            dispatch({
                type: FETCH_All_GAME_DATA,
                payload: res.data
            })
        }
        ).catch((Error) => {
            dispatch({
                type: ERROR_ALL_GAME_DATA,
                payload: Error.message
            })
        })
}

export const getGameData = (id) => {
    return (dispatch, getState, { getFirebase }) => {
        dispatch({
            type: LOAD_GAME_DATA,
            payload: 'loading'
        })

        const firebase = getFirebase();
        firebase.auth().currentUser.getIdToken().then((token) => {

            const url = "/gameData/getGameData"
            const formData = new FormData();
            formData.append('ID', id);
            const config = {
                headers: {
                    'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
                    'X-Authorization-Firebase': token
                }
            }
            post(url, formData, config).then((res) => {
                dispatch(
                    {
                        type: GET_GAME_DATA,
                        payload: res.data
                    }
                )
            }).catch((Error) => {
                dispatch({
                    type: ERROR_LOADING_GAME_DATA,
                    payload: Error.message
                })
            })


        })

    }
}

export const addGameData = (name, boyImage, girlImage, key, isFree, status, gameUrl, type,order, voice, parentID, name_in_english, featured) => {
    return (dispatch, getState, { getFirebase }) => {
        dispatch({
            type: LOAD_GAME_DATA,
            payload: 'loading'
        })

        const firebase = getFirebase();
        firebase.auth().currentUser.getIdToken().then((token) => {


            const url = "/gameData/addGameData";

            const formData = new FormData();
            formData.append('Name', name);
            formData.append('Image', boyImage);
            formData.append('Girl_Image', girlImage);
            formData.append('Key', key);
            formData.append('Is_Free', isFree);
            formData.append('Status', status);
            formData.append('Url', gameUrl);
            formData.append('Order', order);
            formData.append('Type', type);
            formData.append('Voice', voice);
            formData.append('Game_Parent_Id', parentID);
            formData.append('Name_In_English', name_in_english);
            formData.append('featured', featured);
            const config = {
                headers: {
                    'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
                    'X-Authorization-Firebase': token
                }
            }
            post(url, formData, config).then((res) => {
                dispatch(
                    {
                        type: ADD_GAME_DATA,
                        payload: res.data
                    }
                )
            }).catch((Error) => {
                dispatch({
                    type: ERROR_ADDING_GAME_DATA,
                    payload: Error.response.data.message
                })
            })
        })

    }
}


export const updateGame = (id, name, boyImage, girlImage, key, isFree, status,gameUrl, type,order, voice, parentID, name_in_english, featured) => {
    return (dispatch, getState, { getFirebase }) => {
        dispatch({
            type: LOAD_GAME_DATA,
            payload: 'loading'
        })

        const firebase = getFirebase();
        firebase.auth().currentUser.getIdToken().then((token) => {

            const url = "/gameData/updateGame";
            const formData = new FormData();
            formData.append('ID', id);
            formData.append('Name', name);
            formData.append('Image', boyImage);
            formData.append('Girl_Image', girlImage);
            formData.append('Key', key);
            formData.append('Is_Free', isFree);
            formData.append('Status', status);
            formData.append('Url', gameUrl);
            formData.append('Order', order);
            formData.append('Type', type);
            formData.append('Voice', voice);
            formData.append('Game_Parent_Id', parentID);
            formData.append('Name_In_English', name_in_english);
            formData.append('featured', featured);
            const config = {
                headers: {
                    'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
                    'X-Authorization-Firebase': token
                }
            }
            post(url, formData, config).then((res) => {
                dispatch(
                    {
                        type: UPDATE_GAME_DATA,
                        payload: res.data
                    }
                )
            }).catch((Error) => {
                dispatch({
                    type: ERROR_UPDATE_GAME_DATA,
                    payload: Error.response.data.message
                })
            })
        })

    }
}

export const deleteGameData = (id) => {
    return (dispatch, getState, { getFirebase }) => {
        dispatch({
            type: LOAD_GAME_DATA,
            payload: 'loading'
        })
        const firebase = getFirebase();
        firebase.auth().currentUser.getIdToken().then((token) => {


            const url = "/gameData/deleteGameData";
            const formData = new FormData();
            formData.append('ID', id);
            const config = {
                headers: {
                    'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
                    'X-Authorization-Firebase': token
                }
            }
            post(url, formData, config).then((res) => {
                dispatch(
                    {
                        type: DELETING_GAME_DATA,
                        payload: res.data
                    }
                )
            }).catch((Error) => {
                dispatch({
                    type: ERROR_DELETING_GAME_DATA,
                    payload: Error.message
                })
            })
        })

    }
}

export const emptyGameData = () => dispatch => {
    dispatch({
        type: EMPTY_GAME_DATA,
        payload: ''
    })
}
