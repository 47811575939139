import React, { Component } from "react";
import SubscribeCard from "./SubscribeCard";
import PaymentMethods from "./PaymentMethods";
import Promocode from "./Promocode";
import MainAuthModal from "../auth/MainAuthModal";
import SubAuthModal from "../auth/SubAuthModal";
import LoginModal from "../auth/LoginModal";
import OTPModal from "../auth/OTPModal";
import { connect } from 'react-redux';
import { savePriceSubscribeCard, saveDiscountSubscribeCard, saveDescriptionSubscribeCard, saveExpirationDateSubscribeCard, saveSlugDateSubscribeCard } from "../../../Actions/PaymentDataAction";
import { DAILY_SUBSCRIPTION, DEFAULT_PRICE, IRAQ_ZAIN, IRAQ_ZAIN_PRICE,LIBYA_MADAR,LIBYA_LIBYANA,QATAR_VODAPHONE,LIBYA_DAILY_PRICE,LIBYA_WEEKLY_PRICE,QATAR_WEEKLY_PRICE,WEEKLY_SUBSCRIPTION,SLUG_WEEKLY,JEEL_MOBILY, JEEL_MOBILY_PRICE, JEEL_STC, JEEL_STC_PRICE, JEEL_ZAIN, JEEL_ZAIN_PRICE, MONTHLY_SUBSCRIPTION, SLUG_DAILY, SLUG_MONTHLY, SLUG_YEARLY, YEARLY_SUBSCRIPTION } from "../constants";
import PrePaidCard from "./PrePaidCard";


const INITIAL_STATE = {
  selectedSubscribe: SLUG_YEARLY,
  showMainAuth: false,
  showSubAuth: false,
  showLogin: false,
  showOTP: false,
};

class Subscribe extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }
  changeSubscribe = (subscribeType) => {
    this.setState({
      selectedSubscribe: subscribeType,
    });
  };
  openMainAuthModal = (e) => {
    this.setState({ showMainAuth: true });
  };
  closeMainAuthModal = (e) => {
    this.setState({ showMainAuth: false });
  };

  openSubAuthModal = (e) => {
    this.setState({ showSubAuth: true });
  };
  closeSubAuthModal = (e) => {
    this.setState({ showSubAuth: false });
  };

  openLoginModal = (e) => {
    this.setState({ showLogin: true });
  };
  closeLoginModal = (e) => {
    this.setState({ showLogin: false });
  };

  openOTPModal = (e) => {
    this.setState({ showOTP: true });
  };
  closeOTPModal = (e) => {
    this.setState({ showOTP: false });
  }; 

  componentDidMount() {
    const { data } = this.props;
    const {methodType} = this.props;
  }

  getPackagePrice(paymentMethod){
    switch(paymentMethod){
      case JEEL_STC:
        return JEEL_STC_PRICE;
      case JEEL_MOBILY:
        return JEEL_MOBILY_PRICE;
      case JEEL_ZAIN:
        return JEEL_ZAIN_PRICE;
      case IRAQ_ZAIN:
        return IRAQ_ZAIN_PRICE;
        
      case LIBYA_MADAR:
        return LIBYA_WEEKLY_PRICE; 
      case LIBYA_LIBYANA:
        return LIBYA_WEEKLY_PRICE;
      case QATAR_VODAPHONE:
        return QATAR_WEEKLY_PRICE;                
      default:
        return DEFAULT_PRICE;      
    }
  }

  getPackageslug(paymentMethod){
    switch(paymentMethod){
      case JEEL_STC:
        return SLUG_DAILY;
      case JEEL_MOBILY:
        return SLUG_DAILY;
      case JEEL_ZAIN:
        return SLUG_DAILY;
      case IRAQ_ZAIN:
        return SLUG_DAILY;  
        
      case LIBYA_MADAR:
        return SLUG_WEEKLY; 
      case LIBYA_LIBYANA:
        return SLUG_WEEKLY;
      case QATAR_VODAPHONE:
        return SLUG_WEEKLY;         
      default:
        return SLUG_MONTHLY;      
    }
  }

  getPackageType(paymentMethod){
    switch(paymentMethod){
      case JEEL_STC:
        return DAILY_SUBSCRIPTION;
      case JEEL_MOBILY:
        return DAILY_SUBSCRIPTION;
      case JEEL_ZAIN:
        return DAILY_SUBSCRIPTION;
      case IRAQ_ZAIN:
        return DAILY_SUBSCRIPTION;
        
      case LIBYA_MADAR:
        return WEEKLY_SUBSCRIPTION; 
      case LIBYA_LIBYANA:
        return WEEKLY_SUBSCRIPTION;
      case QATAR_VODAPHONE:
        return WEEKLY_SUBSCRIPTION;         
      default:
        return MONTHLY_SUBSCRIPTION;      
    }
  }

  render() {
    const { data, methodType, paymentMethod } = this.props;

    // Calculate before and after discount prices for yearly subscription
    const yearBasicPrice = data.paymentSpecialCountriesOffer 
      && data.paymentSpecialCountriesOffer.yearBasicPriceConverted 
      && data.paymentSpecialCountriesOffer.yearBasicPriceConverted.convertedPrice 
      ? data.paymentSpecialCountriesOffer.yearBasicPriceConverted.convertedPrice 
      : 0;

    const yearDiscountPrice = data.paymentSpecialCountriesOffer 
      && data.paymentSpecialCountriesOffer.yearDiscountPriceConverted 
      && data.paymentSpecialCountriesOffer.yearDiscountPriceConverted.convertedPrice 
      ? data.paymentSpecialCountriesOffer.yearDiscountPriceConverted.convertedPrice 
      : 0;

    const showYearBeforeDiscount = (yearBasicPrice - yearDiscountPrice) > 1;

    const yearBeforeDiscount = showYearBeforeDiscount ? yearBasicPrice : '';
    const yearAfterDiscount = data.paymentSpecialCountriesOffer 
      ? data.paymentSpecialCountriesOffer.yearDiscountPriceConverted.convertedPrice 
      : '';

    // Calculate before and after discount prices for monthly subscription
    const monthBasicPrice = data.paymentSpecialCountriesOffer 
      && data.paymentSpecialCountriesOffer.monthBasicPriceConverted 
      && data.paymentSpecialCountriesOffer.monthBasicPriceConverted.convertedPrice 
      ? data.paymentSpecialCountriesOffer.monthBasicPriceConverted.convertedPrice 
      : 0;

    const monthDiscountPrice = data.paymentSpecialCountriesOffer 
      && data.paymentSpecialCountriesOffer.monthDiscountPriceConverted 
      && data.paymentSpecialCountriesOffer.monthDiscountPriceConverted.convertedPrice 
      ? data.paymentSpecialCountriesOffer.monthDiscountPriceConverted.convertedPrice 
      : 0;

    const showMonthBeforeDiscount = (monthBasicPrice - monthDiscountPrice) > 1;

    const monthBeforeDiscount = showMonthBeforeDiscount ? monthBasicPrice : '';
    const monthAfterDiscount = data.paymentSpecialCountriesOffer 
      ? data.paymentSpecialCountriesOffer.monthDiscountPriceConverted.convertedPrice 
      : '';

    // Calculate before and after discount prices for weekly subscription
    const weekBasicPrice = data.paymentSpecialCountriesOffer 
      && data.paymentSpecialCountriesOffer.weekBasicPriceConverted 
      && data.paymentSpecialCountriesOffer.weekBasicPriceConverted.convertedPrice 
      ? data.paymentSpecialCountriesOffer.weekBasicPriceConverted.convertedPrice 
      : 0;

    const weekDiscountPrice = data.paymentSpecialCountriesOffer 
      && data.paymentSpecialCountriesOffer.weekDiscountPriceConverted 
      && data.paymentSpecialCountriesOffer.weekDiscountPriceConverted.convertedPrice 
      ? data.paymentSpecialCountriesOffer.weekDiscountPriceConverted.convertedPrice 
      : 0;

    const showWeekBeforeDiscount = (weekBasicPrice - weekDiscountPrice) > 1;

    const weekBeforeDiscount = showWeekBeforeDiscount ? weekBasicPrice : '';
    const weekAfterDiscount = data.paymentSpecialCountriesOffer 
      ? data.paymentSpecialCountriesOffer.weekDiscountPriceConverted.convertedPrice 
      : '';
      
    return (
      <div className="form-row">
        <div className="col-12 col-lg-7">
          <div className="subscribe-list">
           
              {this.props.methodType === "other" ?
              <div className="form-row">
                <div className="col-12 col-md-4 mb-md-50">
                  <SubscribeCard
                    changeSubscribe={this.changeSubscribe}
                    selected={
                      this.state.selectedSubscribe == SLUG_YEARLY
                        ? true
                        : false
                    }
                    slug={SLUG_YEARLY}
                    type={YEARLY_SUBSCRIPTION}
                    price={data.paymentSpecialCountriesOffer ? data.paymentSpecialCountriesOffer.yearOriginalPriceConverted.convertedPrice : ''}
                    beforeDiscount={yearBeforeDiscount}
                    afterDiscount={yearAfterDiscount}
                    recommended={true}
                    currency={data.paymentSpecialCountriesOffer ? data.paymentSpecialCountriesOffer.yearOriginalPriceConverted.currency : ''}
                    discription={data.paymentSpecialCountriesOffer ? data.paymentSpecialCountriesOffer.description : ''}
                    expirationDate={data.paymentSpecialCountriesOffer ? data.paymentSpecialCountriesOffer.yearOriginalPriceConverted.expirationDate : ''}
                    methodType={this.props.methodType}
                  />
                </div>
                <div className="col-12 col-md-4 mb-md-50">
                  <SubscribeCard
                    changeSubscribe={this.changeSubscribe}
                    selected={
                      this.state.selectedSubscribe == SLUG_MONTHLY
                        ? true
                        : false
                    }
                    slug={SLUG_MONTHLY}
                    type={MONTHLY_SUBSCRIPTION}
                    price={data.paymentSpecialCountriesOffer ? data.paymentSpecialCountriesOffer.monthOriginalPriceConverted.convertedPrice : ''}
                    beforeDiscount={monthBeforeDiscount}
                    afterDiscount={monthAfterDiscount}
                    recommended={false}
                    currency={data.paymentSpecialCountriesOffer ? data.paymentSpecialCountriesOffer.monthOriginalPriceConverted.currency : ''}
                    discription={data.paymentSpecialCountriesOffer ? data.paymentSpecialCountriesOffer.description : ''}
                    expirationDate={data.paymentSpecialCountriesOffer ? data.paymentSpecialCountriesOffer.monthOriginalPriceConverted.expirationDate : ''}
                    methodType={this.props.methodType}
                  />
                </div>
                <div className="col-12 col-md-4 mb-md-50">
                  <SubscribeCard
                    changeSubscribe={this.changeSubscribe}
                    selected={
                      this.state.selectedSubscribe == SLUG_WEEKLY
                        ? true
                        : false
                    }
                    slug={SLUG_WEEKLY}
                    type={WEEKLY_SUBSCRIPTION}
                    price={data.paymentSpecialCountriesOffer ? data.paymentSpecialCountriesOffer.weekOriginalPriceConverted.convertedPrice : ''}
                    beforeDiscount={weekBeforeDiscount}
                    afterDiscount={weekAfterDiscount}
                    recommended={false}
                    currency={data.paymentSpecialCountriesOffer ? data.paymentSpecialCountriesOffer.weekOriginalPriceConverted.currency : ''}
                    discription={data.paymentSpecialCountriesOffer ? data.paymentSpecialCountriesOffer.description : ''}
                    expirationDate={data.paymentSpecialCountriesOffer ? data.paymentSpecialCountriesOffer.weekOriginalPriceConverted.expirationDate : ''}
                    methodType={this.props.methodType}
                  />
                </div>
              </div> : 
               <div className="form-row">
                <div className="col-12 col-md-12 mb-md-50">
                  <SubscribeCard
                    changeSubscribe={this.changeSubscribe}
                    selected={true}
                    slug={this.getPackageslug(paymentMethod)}
                    type={this.getPackageType(paymentMethod)}
                    price={this.getPackagePrice(paymentMethod)}
                    afterDiscount={this.getPackagePrice(paymentMethod)}
                    recommended={false}
                    currency={data.paymentSpecialCountriesOffer ? data.paymentSpecialCountriesOffer.monthOriginalPriceConverted.currency : ''}
                    discription={data.paymentSpecialCountriesOffer ? data.paymentSpecialCountriesOffer.description : ''}
                    expirationDate={data.paymentSpecialCountriesOffer ? data.paymentSpecialCountriesOffer.monthOriginalPriceConverted.expirationDate : ''}
                    methodType={this.props.methodType}
                  />
                </div>
              </div>
              }



          </div>
        </div>
        <div className="col-12 col-lg-5">
          <PaymentMethods
            openMainAuthModal={this.openMainAuthModal}
            openSubAuthModal={this.openSubAuthModal}
            openLoginModal={this.openLoginModal}
            showRenewal={this.props.showRenewal ? true : false}
          />
        </div>
        
        <PrePaidCard className="col-12 col-lg-7"
         openLoginModal={this.openLoginModal}
        />


        {this.state.showMainAuth && (
          <MainAuthModal
            showMainAuth={this.state.showMainAuth}
            closeMainAuthModal={this.closeMainAuthModal}
            openLoginModal={this.openLoginModal}
            closeLoginModal={this.closeLoginModal}
            openOTPModal={this.openOTPModal}
            closeOTPModal={this.closeOTPModal}
          />
        )}

        {this.state.showSubAuth && (
          <SubAuthModal
            showSubAuth={this.state.showSubAuth}
            closeSubAuthModal={this.closeSubAuthModal}
            openLoginModal={this.openLoginModal}
            closeLoginModal={this.closeLoginModal}
            openOTPModal={this.openOTPModal}
            closeOTPModal={this.closeOTPModal}
          />
        )}

        {this.state.showLogin && (
          <LoginModal
            showLogin={this.state.showLogin}
            closeLoginModal={this.closeLoginModal}
            openMainAuthModal={this.openMainAuthModal}
            closeMainAuthModal={this.closeMainAuthModal}
            openSubAuthModal={this.openSubAuthModal}
            closeSubAuthModal={this.closeSubAuthModal}
          />
        )}

        {this.state.showOTP && (
          <OTPModal
            showOTP={this.state.showOTP}
            closeOTPModal={this.closeOTPModal}
            openMainAuthModal={this.openMainAuthModal}
            openSubAuthModal={this.openSubAuthModal}
          />
        )}
      </div>
    );
  }
}
const mapStateToProps = state => ({
  data: state.paymentData.package_and_methods, 
  methodType: state.paymentData.paymentMethodType,
  paymentMethod: state.paymentData.paymentMethod, 
});
export default connect(mapStateToProps,
  {
    savePriceSubscribeCard,
    saveDiscountSubscribeCard,
    saveExpirationDateSubscribeCard,
    saveSlugDateSubscribeCard,
    saveDescriptionSubscribeCard,
  })(Subscribe);
