import store from '../Store'
import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'
import { post } from 'axios'

export function saveSong(activityId) {
    return new Promise(function (resolve, reject) {
        let song = store.getState().songs.item;

        let isFree = '', subValueId = '';
        if (song.isFree === 'paid') {
            isFree = 0
        }
        else if (song.isFree === 'free') {
            isFree = 1
        }

        if (song.subValueId === '' ||
            song.subValueId === undefined ||
            song.subValueId === null) {
            subValueId = 0
        } else {
            subValueId = song.subValueId
        }


        let durationWithMusic = '',
            durationWithoutMusic = '',
            videoWithMusicUrl = '',
            videoWithoutMusicUrl = '',
            videoKeyVideoWithMusic='' , videoKeyVideoWithoutMusic='';


        if (song.videoWithMusic_duration === '' ||
            song.videoWithMusic_duration === undefined ||
            song.videoWithMusic_duration === null) {
            durationWithMusic = 0
        } else {
            durationWithMusic = song.videoWithMusic_duration
        }
        if (song.videoKeyVideoWithMusic === '' ||
            song.videoKeyVideoWithMusic === undefined ||
            song.videoKeyVideoWithMusic === null) {
            videoKeyVideoWithMusic = ""
        } else {
            videoKeyVideoWithMusic = song.videoKeyVideoWithMusic
        }
        if (song.videoKeyVideoWithoutMusic === '' ||
            song.videoKeyVideoWithoutMusic === undefined ||
            song.videoKeyVideoWithoutMusic === null) {
            videoKeyVideoWithoutMusic = ""
        } else {
            videoKeyVideoWithoutMusic = song.videoKeyVideoWithoutMusic
        }

        if (song.videoWithoutMusic_duration === '' ||
            song.videoWithoutMusic_duration === undefined ||
            song.videoWithoutMusic_duration === null) {
            durationWithoutMusic = 0
        } else {
            durationWithoutMusic = song.videoWithoutMusic_duration
        }


        if (song.videoWithMusic === '' ||
            song.videoWithMusic === undefined ||
            song.videoWithMusic === null) {
            videoWithMusicUrl = ''
        } else {
            videoWithMusicUrl = song.videoWithMusic
        }


        if (song.Video_Without_Music === '' ||
            song.Video_Without_Music === undefined ||
            song.Video_Without_Music === null) {
            videoWithoutMusicUrl = ''
        } else {
            videoWithoutMusicUrl = song.Video_Without_Music
        }

        let description = song.description;
        if (song.description == null ||
            song.description == undefined ||
            song.description == '') {
            description = ''
        }

        let descriptionInEnglish = song.description_in_english;
        if (song.description_in_english == null ||
            song.description_in_english == undefined ||
            song.description_in_english == '') {
                descriptionInEnglish = ''
        }
        const url = "/Song/addSong";
        const formData = new FormData();
        formData.append('Name', song.name);
        formData.append('Image', song.image);
        formData.append('Status', 'SUSPENDED');
        formData.append('Publish_Date', song.PublishDate);
        formData.append('Description', description);
        formData.append('Name_In_English',song.song_name_in_english);
        formData.append('Description_In_English',song.description_in_english);

        formData.append('Video_With_Music_URL', videoWithMusicUrl);
        formData.append('Video_With_Music_Duration', durationWithMusic);

        formData.append('Video_Without_Music_URL', videoWithoutMusicUrl);
        formData.append('Video_Without_Music_Duration', durationWithoutMusic);
        formData.append('Activity_ID', activityId);
        formData.append('Is_free', isFree);
        formData.append('Voice', song.voice);
        formData.append('Content_Sub_Value_Id', subValueId);
        formData.append('Song_Parent_Id', song.selected_songParent_id);
        formData.append("videoKeyVideoWithMusic", videoKeyVideoWithMusic);
        formData.append("videoKeyVideoWithoutMusic", videoKeyVideoWithoutMusic);
        formData.append("featured", song.featured);
        const config = {
            headers: {
                'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
                'X-Authorization-Firebase': firebase.auth().currentUser.h.b
            }
        }
        post(url, formData, config).then((res) => {
            resolve(res);
            return (res);
        }).catch((Error) => {
            reject(Error)
            return Error.message
        })
    })
}